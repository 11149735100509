import React, { useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Input, Tag } from "antd";
import { AddNewTag } from "./AddNewTag";

interface Props {
  tags: string[];
  setTags: (tags: string[]) => void;
  disableTagAddEdit?: boolean;
  newTagTitle?: string;
  allowAddingNewTag?: boolean;
}

export const AddRemoveTags = ({
  setTags,
  tags,
  newTagTitle,
  allowAddingNewTag,
  disableTagAddEdit,
}: Props) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags([...newTags]);
  };

  const showInput = () => {
    if (!disableTagAddEdit) setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  return (
    <>
      {tags.map((tag) => {
        return (
          <Tag
            className="edit-tag"
            key={tag}
            closable={!disableTagAddEdit}
            onClose={() => handleClose(tag)}
          >
            {tag}
          </Tag>
        );
      })}
      {allowAddingNewTag ? (
        inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <AddNewTag
            className={disableTagAddEdit ? "cursor-disable" : ""}
            newTagTitle={newTagTitle}
            onClick={showInput}
          />
        )
      ) : null}
    </>
  );
};
