import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import "./hedisCareGaps.scss";
import { DownOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PatientService } from "../../../api";
import { EVIDENCE_VIEWER_URL } from "./tile-helper-components";
import { INSIGHT_TYPE } from "../../evidence-viewer/evidence-viewer-container";
import { IPatient } from "../../../interfaces";
import { Input } from "antd";
import { InputText, Loading } from "../../../components";
import { UnmountClosed } from "react-collapse";
import { PAGE_URL } from "../../../utils";

const { Search } = Input;

type Props = {
  patientInfo: IPatient;
};

type QuestionType = {
  exclusionCriteria: {
    question: string;
    response: string;
  }[];
  numerator: {
    question: string;
    response: string;
  }[];
};

export const HedisCareGaps = (props: Props) => {
  const { patientInfo } = props;
  const navigate = useNavigate();
  const [hedisCareGaps, setHedisCareGaps] = useState([] as any);
  const [measureId, setMeasureId] = useState<string | null>(null);
  const [questions, setQuestions] = useState({} as QuestionType);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [expandIsLoading, setExpandIsLoading] = useState(false);
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const [expandRationale, setExpandRationale] = useState<string | null>(null);
  const [originalData, setOriginalData] = useState([] as any);

  const columns: any = [
    {
      title: "Measure Name",
      dataIndex: "measureName",
      key: "measureName",
      className: "measureTable-measurename",
    },
    {
      title: "Measure ID",
      dataIndex: "measureId",
      key: "measureId",
    },
    {
      title: "Compliance",
      dataIndex: "compliance",
      key: "compliance",
    },
  ];

  const getMeasuregap = async () => {
    const { data } = await PatientService.getHedisCareGaps(
      patientInfo.fileList[0]?.UUID
    );
    const hedisCareGapsWithKeys = data.data.map((item: any) => ({
      ...item,
      key: item.id, // Add a "key" field with the same value as "id"
    }));
    setHedisCareGaps(hedisCareGapsWithKeys);
    setOriginalData(hedisCareGapsWithKeys);
  };

  useEffect(() => {
    getMeasuregap();
  }, []);

  const renderExpandedRow = (record: any) => {
    const toggleRationale = (key: any) => {
      if (expandRationale === key) {
        setExpandRationale(null);
      } else {
        setExpandRationale((prevExpandedRowId) =>
          prevExpandedRowId === key ? null : key
        );
      }
    };

    const getNumeratorComplianceColumns = () => [
      {
        title: "Numerator Compliance",
        dataIndex: "question",
        key: "question",
        className: "expandedTable-question"
      },
      {
        title: "Response",
        dataIndex: "response",
        key: "response",
      },
      {
        title: "Rationale",
        render: (text: any, value: any) => (
          <div className="cursor-pointer">
            <div className="flex">
              <Tooltip
                placement="topLeft"
                title={
                  <span style={{ color: "black" }}>
                    {value.rationale || ""}
                  </span>
                }
                color="white"
                overlayStyle={{minWidth: "600px"}}
              >
                <p className="link-color normal-font-size">Rationale</p>
              </Tooltip>
            </div>
          </div>
        ),
        className: "expandedTable-evidence",
      },
      {
        title: "Evidence",
        render: (text: any, value: any) => (
          <div
            className="link-color cursor-pointer"
            onClick={() =>
              navigate(
                `${PAGE_URL.EVIDENCE_VIEWER}?isMeasure=true&queryId=${value.id}&fileId=${patientInfo.fileList[0]?.UUID}`
              )
            }
          >
            Click Here
          </div>
        ),
        className: "expandedTable-evidence",
      },
    ];

    const getExclusionCriteriaColumns = () => [
      {
        title: "Exclusion Criteria",
        dataIndex: "question",
        key: "question",
        className: "expandedTable-question"
      },
      {
        title: "Response",
        dataIndex: "response",
        key: "response",
      },
      {
        title: "Rationale",
        render: (text: any, value: any) => (
          <div className="cursor-pointer">
            <div className="flex">
              <Tooltip
                placement="topLeft"
                title={
                  <span style={{ color: "black" }}>
                    {value.rationale || ""}
                  </span>
                }
                color="white"
                overlayStyle={{minWidth: "600px"}}
              >
                <p className="link-color normal-font-size">Rationale</p>
              </Tooltip>
            </div>
          </div>
        ),
        className: "expandedTable-evidence",
      },
      {
        title: "Evidence",
        render: (text: any, value: any) => (
          <div
            className="link-color cursor-pointer"
            onClick={() =>
              navigate(
                `${PAGE_URL.EVIDENCE_VIEWER}?isMeasure=true&queryId=${value.id}&fileId=${patientInfo.fileList[0]?.UUID}`
              )
            }
          >
            Click Here
          </div>
        ),
        className: "expandedTable-evidence",
      },
    ];

    return (
      <div className="expandedTable">
        {expandIsLoading ? (
          <Loading />
        ) : (
          <>
            {!!questions.numerator?.length && (
              <Table
                columns={getNumeratorComplianceColumns()}
                dataSource={questions.numerator.map(
                  (item: any, index: number) => ({
                    ...item,
                    key: index,
                  })
                )}
                pagination={false}
              />
            )}
            {!!questions.exclusionCriteria?.length && (
              <Table
                columns={getExclusionCriteriaColumns()}
                dataSource={questions.exclusionCriteria.map(
                  (item: any, index: number) => ({
                    ...item,
                    key: index + 100,
                  })
                )}
                pagination={false}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    if (expanded) {
      return (
        <DownOutlined
          className="measureTable-expandIcon"
          onClick={(e) => onExpand(record, e)}
        />
      );
    }
    return (
      <RightOutlined
        className="measureTable-collapseIcon"
        onClick={(e) => onExpand(record, e)}
      />
    );
  };

  const filterData = (query: string) => {
    if (!query) {
      setHedisCareGaps(originalData); // If query is empty, show all data
      return;
    }
    const filteredData = originalData.filter((item: any) => {
      return (
        item.measureName.toLowerCase().includes(query.toLowerCase()) ||
        item.measureId.toLowerCase().includes(query.toLowerCase())
      );
    });
    setHedisCareGaps(filteredData);
  };

  useEffect(() => {
    filterData("");
  }, []);

  const handleExpand = async (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowId(expanded ? record.id : null);
      setMeasureId(record.measureId);
      setExpandIsLoading(true);
      const { data } = await PatientService.getQuestions(
        patientInfo.fileList[0]?.UUID,
        record.id
      );
      setQuestions(data.transformedData);
      setExpandIsLoading(false);
    } else {
      setExpandedRowId(expanded ? record.id : null);
      setMeasureId(null);
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    if (value.trim() === "") {
      setHedisCareGaps(originalData);
    } else {
      filterData(value);
    }
  };

  return (
    <div>
      <Search
        className="record-search"
        placeholder="Search Hedis Measure"
        style={{ width: "350px", marginBottom: "20px" }}
        onSearch={handleSearch}
      />
      <Table
        className="measureTable"
        columns={columns}
        dataSource={hedisCareGaps}
        pagination={false}
        expandable={{
          expandedRowRender: renderExpandedRow,
          expandIconColumnIndex: 0,
          onExpand: handleExpand,
          expandIcon: CustomExpandIcon,
          expandedRowKeys: expandedRowId ? [expandedRowId] : undefined,
          expandRowByClick: true,
        }}
        rowClassName={(record) =>
          expandedRowId === record.id ? "row-expanded" : ""
        }
      />
    </div>
  );
};
