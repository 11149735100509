import { apiClient } from ".";

export interface ConfigurationObj {
  field_key: string;
  field_name: string;
  field_content: string | string[];
}

export interface GetConfigurationsResponse {
  data: ConfigurationObj[];
  message: string;
}

export interface UpdateConfigurationRequestObj {
  settings: ConfigurationObj[];
}

const updateConfiguration = async (
  clientId: number,
  reqObj: ConfigurationObj[]
) => {
  const obj: UpdateConfigurationRequestObj = { settings: [...reqObj] };
  const res = await apiClient.put<GetConfigurationsResponse>(
    `dashboard/setting/org/${clientId}`,
    obj
  );
  return res;
};

const getConfigurations = async (clientId: number) => {
  const res = await apiClient.get<GetConfigurationsResponse>(
    `dashboard/setting/org/${clientId}`
  );
  return res;
};

export const ConfigurationService = {
  updateConfiguration,
  getConfigurations,
};
