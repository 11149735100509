import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, RootState, setUserRoles } from "../../store";
import { UsersList } from "..";
import { rolesService } from "../../api/roles";
import { useFetchUserList } from "../../hooks";
import { Button } from "antd";
import { MODAL_TYPE, openNotificationWithIcon } from "../../utils";
import { NotAccessibleFallback } from "../../components";

export const EnterpriseAdminDashboardContainer = () => {
  const dispatch = useDispatch();
  const { fetchUsers, isFetchUsersAuthorized } = useFetchUserList();
  const [loading, setLoading] = useState(true);
  const clientId = useSelector((state: RootState) => state.user.user.clientId);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        await fetchUsers();
        const { data: userRoles } = await rolesService.getAll(true);
        dispatch(setUserRoles(userRoles.data));
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [clientId, dispatch, fetchUsers]);

  return (
    <div className="projectsList">
      <div className="flex jcsb aic ">
        <p className="table-heading">Users</p>
        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
          }}
        >
          Add New User
        </Button>
      </div>
      {!isFetchUsersAuthorized ? (
        <NotAccessibleFallback />
      ) : (
        <UsersList loading={loading} />
      )}
    </div>
  );
};
