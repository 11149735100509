import React, { useCallback } from "react";
import { Slider } from "antd";
import { SliderMarks, SliderSingleProps } from "antd/lib/slider";
import "./slider.scss";

const marks: SliderMarks = {
  0: "0%",
  100: "100%",
};

interface SingleSliderProps extends SliderSingleProps {
  setValue: (value: number) => void;
}

export const SingleSlider = (props: SingleSliderProps) => {
  const { setValue } = props;
  const onSliderValueChange = useCallback(
    (e: number | null) => {
      if (e) setValue(e);
    },
    [setValue]
  );

  return (
    <Slider
      onChange={onSliderValueChange}
      marks={marks}
      max={100}
      min={0}
      step={5}
      {...props}
      className="customize-range-silder"
    />
  );
};
