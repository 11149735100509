import React, { useEffect, useState } from "react";
import {
  VitalSignsAndPhysicalMeasurements,
  DemographicsCard,
} from "./patient-insight-summary";
import { IPatient } from "../../../interfaces";
import { summaryService } from "../../../api/summarization";

interface Props {
  patientInfo: IPatient;
  summary: string;
}

export const Summary = ({ patientInfo, summary }: Props) => {
  const { demographics } = patientInfo;

  return (
    <div>
      {demographics && <DemographicsCard demographics={demographics} />}
      {/* <VitalSignsAndPhysicalMeasurements /> */}
      <div style={{ marginTop: "40px" }}>
        <h5 className="insight-card-title">PATIENT SUMMARY</h5>
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: summary }}
        ></div>
      </div>
    </div>
  );
};
