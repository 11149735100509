import React from "react";
import { IconsProps } from "./Icons";

export const TeamIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 32 32"}
      width={width ? width : "32"}
      height={height ? height : "32"}
      fill={fill ? fill : "currentColor"}
    >
        <rect opacity="0.5" width="32" height="32" rx="3" fill="#D9CFED"/>
        <path d="M21.5714 15C23.1486 15 24.4171 13.72 24.4171 12.1429C24.4171 10.5657 23.1486 9.28571 21.5714 9.28571C19.9943 9.28571 18.7143 10.5657 18.7143 12.1429C18.7143 13.72 19.9943 15 21.5714 15ZM13 13.8571C14.8971 13.8571 16.4171 12.3257 16.4171 10.4286C16.4171 8.53143 14.8971 7 13 7C11.1029 7 9.57143 8.53143 9.57143 10.4286C9.57143 12.3257 11.1029 13.8571 13 13.8571ZM21.5714 17.2857C19.48 17.2857 15.2857 18.3371 15.2857 20.4286V23H27.8571V20.4286C27.8571 18.3371 23.6629 17.2857 21.5714 17.2857ZM13 16.1429C10.3371 16.1429 5 17.48 5 20.1429V23H13V20.4286C13 19.4571 13.3771 17.7543 15.7086 16.4629C14.7143 16.2571 13.7543 16.1429 13 16.1429Z" fill="#8972BB"/>
    </svg>
  );
};
