import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AddEditUserForm } from "./AddEditUserForm";
import { AddEditUserProps } from ".";

const AddEditUser = ({
  visibility,
  editObj,
  isEdit,
  clientId,
  userRoleCode,
  handleClose,
}: AddEditUserProps) => {
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title=""
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <AddEditUserForm
        editObj={editObj}
        onClose={handleClose}
        isEdit={isEdit}
        role={userRoleCode}
        clientId={clientId}
        title={`${isEdit ? "Edit" : "Add"} User`}
        buttonTitle={isEdit ? "Save" : `Add User`}
      />
    </Modal>
  );
};

export default AddEditUser;
