import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../../../utils";
import { userService } from "../../../api";
import { ResetUserPasswordProps } from ".";
import "./reset-user-password.scss";

export const ResetUserPassword = ({ visibility, editObj, handleClose }: ResetUserPasswordProps) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [resetLink, setResetLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendMailLoading, setSendMailLoading] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const handleSendMail = async (email: string) => {
    setSendMailLoading(true);
    try {
      await userService.forgotPassword({ email });
      openNotificationWithIcon("", "Reset password mail sent successfully", "success");
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "success");
    } finally {
      setSendMailLoading(false);
    }
  };

  useEffect(() => {
    const fetchLink = async () => {
      setLoading(true);
      const { data } = await userService.generateResetPasswordLink(editObj.email);
      setResetLink(data.data.link);
      setLoading(false);
    };
    fetchLink();
  }, [editObj.email]);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => setCopySuccess(false), 2000);
    }
  }, [copySuccess]);

  return (
    <Modal
      className="autonomizeModal addClient reset-user-password"
      centered
      width={450}
      title="Reset User Password"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h2>Reset user password </h2>
        <p className="text-bold">Here, you can change the user password.</p>
      </div>
      <div className="addClient-content new-role">
        <Card loading={loading}>
          <p>
            <span style={{ fontWeight: "bold" }}>Name :</span>{" "}
            {`${editObj.name}`}
            <br />
            <span style={{ fontWeight: "bold" }}>Email :</span> {editObj.email}
            <br />
            <span style={{ fontWeight: "bold" }}>Role :</span> {""}
          </p>
        </Card>
        <div>
          <p className="m-b">Send a password reset link to the registered user email</p>
          <Button
            className="outline"
            loading={sendMailLoading}
            disabled={loading}
            onClick={() => handleSendMail(editObj.email)}
          >
            Send Mail
          </Button>
        </div>
        <div className="m-t-24">
          <p className="m-b">Copy the link for password reset.</p>
          <Button
            title={resetLink}
            className="outline block"
            onPointerUp={() => copyToClipboard(resetLink)}
            disabled={loading}
          >
            {copySuccess ? "Link Copied!" : "Copy Link"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
