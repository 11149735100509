import React from "react";
import { BackButton } from "../../components";
import { Client } from "../../store/data/types";
import "./client-detail-aside.scss";

interface Props {
  client: Client;
}

export const ClientDetailAside = ({ client }: Props) => {
  return (
    <>
      <BackButton />
      <h2 className="title">{client.name}</h2>
      <div className="clientDetails-description">
        <h3 className="sub-title">Client Detail</h3>
        <p>{client.description}</p>
      </div>
    </>
  );
};
