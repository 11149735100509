import React from "react";
import { IconsProps } from "./Icons";
const ChartGreenIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      fill="none"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 84 52"}
      width={width ? width : "84"}
      height={height ? height : "52"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_16_11)">
        <path
          d="M12.2832 17.1954C10.6077 12.7274 7.39627 22.7805 6 28.3656V45.0957H77.21V17.1954C73.0212 20.6861 73.0212 17.1954 69.5305 17.1954H60.4547C58.3603 17.1954 56.2659 17.1954 52.0771 24.1768C47.8882 31.1582 47.1901 19.2898 46.492 10.9122C45.9334 4.21006 43.4667 5.79251 42.3031 7.42149C40.9069 12.0757 37.9747 23.1994 37.4162 30.46C36.8577 37.7206 32.9946 34.8816 31.1329 32.5544C30.4348 31.1582 28.6196 26.9694 26.9441 21.3842C24.8497 14.4028 20.6609 17.1954 17.8683 21.3842C15.0758 25.573 14.3776 22.7805 12.2832 17.1954Z"
          fill="url(#paint0_linear_16_11)"
          fillOpacity="0.6"
        />
      </g>
      <path
        d="M6 28.3656C7.39627 22.7805 10.6077 12.7274 12.2832 17.1954C14.3776 22.7805 15.0758 25.573 17.8683 21.3842C20.6609 17.1954 24.8497 14.4028 26.9441 21.3842C28.6196 26.9694 30.4348 31.1582 31.1329 32.5544C32.9946 34.8816 36.8577 37.7207 37.4162 30.46C37.9747 23.1994 40.9069 12.0757 42.3031 7.42149C43.4667 5.79251 45.9334 4.21006 46.492 10.9122C47.1901 19.2898 47.8882 31.1582 52.0771 24.1768C56.2659 17.1954 58.3603 17.1954 60.4547 17.1954C62.5491 17.1954 66.0398 17.1954 69.5305 17.1954C73.0212 17.1954 73.0212 20.6861 77.21 17.1954"
        stroke="#1DAB2D"
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_f_16_11"
          x="0"
          y="0"
          width="83.21"
          height="51.0957"
          filterUnits="userSpaceOnUse"
          // colorinterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_16_11" />
        </filter>
        <linearGradient
          id="paint0_linear_16_11"
          x1="41.605"
          y1="6"
          x2="41.605"
          y2="45.0957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DAB2D" />
          <stop offset="0.817708" stopColor="#1DAB2D" stopOpacity="0" />
        </linearGradient>
      </defs>{" "}
    </svg>
  );
};

export default ChartGreenIcon;
