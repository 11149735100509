import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  useAuthorization,
  useFetchPatients,
  usePatientFilter,
  useUser,
} from '../../../hooks';
import { PatientListFilter } from '.';
import {
  CLINICIAN_PERMISSIONS,
  getLocalDateTimeStringFromISO,
  MODAL_TYPE,
  PAGE_URL,
  getAge,
} from '../../../utils';
import { openModal, updateAssignPatientsList } from '../../../store';
import { AppPagination } from '../../../components';
import { RootState } from '../../../store/index';

interface PatientListDataType {
  key: React.Key;
  patientName: string;
  // age: number;
  gender: string;
  dateOfBirth: string;
  patientId: number;
  mrn: string;
  uploadedDate: string;
}

const getColumns = (): ColumnsType<PatientListDataType> => [
  {
    title: 'Subject Name',
    dataIndex: 'patientName',
    sorter: true,
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
  },
  {
    title: 'Date of Birth',
    dataIndex: 'dateOfBirth',
  },
  {
    title: 'Subject Id',
    dataIndex: 'mrn',
  },
  {
    title: 'Uploaded Date',
    dataIndex: 'uploadedDate',
    sorter: true,
  },
];

export const UnassociatedPatientsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsAuthorized } = useAuthorization();
  const { roleCode } = useUser();
  const {
    getPatientFilterObject,
    paginationObj,
    patientStatusFilter,
    searchFilter,
    sortFilter,
  } = usePatientFilter({
    onPatientsList: true,
    setIsAuthorized,
  });
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { onPatientStatusChange, patientStatusLoading, patientStatusOptions } =
    patientStatusFilter;
  const { onSearchChange, searchKeyword } = searchFilter;
  const { setSort } = sortFilter;
  const { fetchPatients, patientsLoading, patients, totalPatients } =
    useFetchPatients({ setIsAuthorized });
  const { isProjectAssigned } = useSelector(
    (state: RootState) => state.project,
  );

  const [selectedIds, setSelectedIds] = useState([] as React.Key[]);

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: PatientListDataType[],
    ) => {
      setSelectedIds([...selectedRows.map((item) => item.key)]);
    },
  };
  const patientsData: PatientListDataType[] = patients.map((patient) => ({
    key: patient.Id,
    mrn: patient.Mrn,
    age: getAge(patient.Dob),
    gender: patient.Gender,
    dateOfBirth: patient.Dob,
    patientId: patient.Id,
    patientName: patient.Name,
    uploadedDate: getLocalDateTimeStringFromISO(patient.CreatedAt),
  }));

  const onTableChange: TableProps<PatientListDataType>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    setSort(sorter);
  };

  const handleSearchChange = (str: string) => {
    onSearchChange(str);
    setSelectedIds([]);
  };

  const handleRowClick = (item: PatientListDataType) => {
    navigate(`${PAGE_URL.PATIENT_PROFILE}/${item.patientId}`);
  };

  const assignProjectButtonClick = () => {
    if (selectedIds.length > 0) {
      dispatch(
        updateAssignPatientsList({
          patientIds: selectedIds.map((id) => Number(id)),
          alreadyAssignedProjects: [],
        }),
      );
      dispatch(openModal(MODAL_TYPE.ADD_PATIENTS_TO_PROJECT));
    }
  };

  useEffect(() => {
    const getPatients = async () => {
      const obj = getPatientFilterObject({ assigned: false });
      await fetchPatients(obj);
    };
    getPatients();
  }, [fetchPatients, getPatientFilterObject]);

  useEffect(() => {
    if (isProjectAssigned) {
      const getPatients = async () => {
        const obj = getPatientFilterObject({ assigned: false });
        await fetchPatients(obj);
      };
      getPatients();
    }
  }, [fetchPatients, getPatientFilterObject, isProjectAssigned]);

  return (
    <>
      <div className="patients-tab">
        <PatientListFilter
          {...{
            onSearchChange: handleSearchChange,
            searchKeyword,
            patientStatusLoading,
            patientStatusOptions,
            onPatientStatusChange,
            disableAssignProjectButton: selectedIds.length <= 0,
            assignProjectButtonClick,
          }}
        />
        <AppPagination
          {...{
            showSizeChanger: true,
            current: page,
            total: totalPatients,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
        <Table
          className="basic-detail-content tb-scroll checkbox-table"
          rowClassName="cursor-pointer"
          rowSelection={
            !CLINICIAN_PERMISSIONS.includes(roleCode) ? rowSelection : undefined
          }
          columns={getColumns()}
          dataSource={patientsData}
          onChange={onTableChange}
          loading={patientsLoading}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
          pagination={false}
        />
      </div>
    </>
  );
};
