import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SummaryTypes } from "../../interfaces";

interface IModal {
  [name: string]: boolean;
}

interface UiSlice {
  modal: IModal;
  projectName: string;
  patientName: string;
  isDeleteConfirmed: boolean;
  editSummaryData: SummaryTypes;
}
export const uiInitialState: UiSlice = {
  modal: {} as IModal,
  projectName: "",
  patientName: "",
  isDeleteConfirmed: false,
  editSummaryData: {} as SummaryTypes
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    openModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = true;
    },
    closeModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = false;
    },
    updateDeleteConfirmation: (
      state: UiSlice,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeleteConfirmed = payload;
    },
    setProjectName: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.projectName = payload;
    },
    setPatientName: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.patientName = payload;
    },
    setEditSummaryData: (state: UiSlice, { payload }: PayloadAction<SummaryTypes>) => {
      state.editSummaryData = payload;
    },
  },
});

export const { openModal, closeModal, updateDeleteConfirmation, setProjectName, setPatientName, setEditSummaryData } =
  uiSlice.actions;

export default uiSlice.reducer;
