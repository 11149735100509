import { Avatar, Switch } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { projectService } from '../../../api';
import { UserPlaceholder } from '../../../assets/images';
import { ProjectUser } from '../../../interfaces';
import { updateProjectUser } from '../../../store';
import {
  CLINICIAN_PERMISSIONS,
  openNotificationWithIcon,
} from '../../../utils';
import { useUser } from '../../../hooks';

interface Props {
  user: ProjectUser;
}

export const ProjectTeamMemberCard = ({ user }: Props) => {
  const { roleCode } = useUser();
  const dispatch = useDispatch();

  const onChange = async (relationId: number, status: boolean) => {
    try {
      const { data } = await projectService.disableEnableProjectUsers(
        relationId,
        status,
      );
      dispatch(updateProjectUser(data.data));
    } catch (err: any) {
      openNotificationWithIcon('', err.response.data.message, 'error');
    }
  };

  return (
    <div className="team-member-card">
      <Avatar src={UserPlaceholder} />
      <div>
        <h4 className="m-0">{`${user.name}`}</h4>
        <h3 title={user.email} className="text_ellipsis">
          {user.email}
        </h3>
        <div className="flex jcsb aic">
          <p className="italic">{user.visualName}</p>
          {!CLINICIAN_PERMISSIONS.includes(roleCode) && (
            <Switch
              size="small"
              checked={user.status}
              onChange={(checked) => onChange(user.relationId, checked)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
