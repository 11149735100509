import React from "react";
import { Demographics } from "../../../../interfaces";
import { Card } from "antd";

interface Props {
  demographics: Demographics;
}

export const DemographicsCard = ({ demographics }: Props) => {
  const {
    year_of_birth,
    sex,
    race,
    blood_type,
    ethnicity,
    income,
    marital_status,
    occupation,
    zip_code,
  } = demographics;

  const mapper: { [key: string]: string } = {
    "Year of birth": year_of_birth,
    "Biological Sex": sex,
    Race: race,
    "Blood Type": blood_type,
    "Marital Status": marital_status,
    Ethnicity: ethnicity,
    Occupation: occupation,
    "Income Level": income,
    "Zip Code": zip_code,
  };
  return (
    <div className="m-b">
      <h5 className="insight-card-title">DEMOGRAPHICS</h5>
      <div className="demo-card-conatiner">
        {Object.keys(mapper).map((item, index) => (
          <div key={`demographics-item-${index}`}>
            <p className="demo-card-conatiner-title">{item}</p>
            <p>{mapper[item].length === 0 ? "N/A" : mapper[item]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
