import React, { useEffect } from "react";
import { Row, Table, Col } from "antd";
import { ViewMoreResults } from "../tile-helper-components";

interface ProjectData {
  score: string;
  condition: string;
  icdCode: string;
}

interface ProjectSpecific {
  title: string;
  thresholdConfidenceScore: number;
  data: ProjectData[];
}

interface Props {
  project_specifics: ProjectSpecific[];
}

const ProjectSpecificTable: React.FC<{ project: ProjectSpecific }> = ({
  project,
}) => {
  const columns = [
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
    },
    {
      title: "ICD-10 Code",
      dataIndex: "icdCode",
      key: "icdCode",
    },
    {
      title: "Confidence Score",
      dataIndex: "score",
      key: "score",
    },
  ];

  return (
    <Col className="gutter-row" span={12}>
      <div>
        <h4 className="insight-card-title">{project.title.toUpperCase()}</h4>
        <Table
          className="cmn-table"
          dataSource={project.data}
          columns={columns}
          pagination={false}
        />
      </div>
    </Col>
  );
};

const ProjectSpecificConditionCard = ({ project_specifics }: Props) => {
  return (
    <div>
      <Row gutter={[40, 40]}>
        {project_specifics.map((project, index) => (
          <ProjectSpecificTable key={index} project={project} />
        ))}
      </Row>
    </div>
  );
};

export default ProjectSpecificConditionCard;
