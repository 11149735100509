import React, { useCallback } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PatientFile } from "../../../interfaces";
import { PAGE_URL } from "../../../utils";
import { SmallPdfIcon } from "../../../assets/icons";

interface Props {
  fileList: PatientFile[];
}

export const FileListCard = ({ fileList }: Props) => {
  const navigate = useNavigate();

  const handlePdfClick = useCallback(
    (file: PatientFile) => {
      navigate({
        pathname: PAGE_URL.EVIDENCE_VIEWER,
        search: createSearchParams({
          fileName: file.name,
          fileId: file.UUID,
        }).toString(),
      });
    },
    [navigate]
  );

  return (
    <div className="m-t data-sources-container">
      <h4 className="mb-0">{"Source Charts/Data Sources"}</h4>
      <div className="orange patient-card tiles-max-height">
        {fileList.map((file: PatientFile, index: number) => (
          <div key={`file-list-item-${index}`} className="listitem">
            <h4 className="reviewlist cursor-pointer" onClick={() => handlePdfClick(file)}>
              {file.name}
            </h4>
            <SmallPdfIcon />
          </div>
        ))}
      </div>
    </div>
  );
};
