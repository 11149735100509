import { apiClient } from ".";

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  roleId: string;
  email: string;
  roleName: string;
  client: number;
  mobile?: string;
  designation?: string;
  status: boolean;
  createdBy: string;
  visualName: string;
}

export interface UserSignUpRequest {
  email: string;
  firstName: string;
  lastName: string;
  mobile?: string;
  designation?: string;
  role: string;
  roleId: number;
  client: number;
}

export interface UserList {
  id: 22;
  ssoUserId: string;
  name: string;
  email: string;
  createdAt: string;
  isActive: boolean;
  updatedAt: string;
}
interface GetAllUsers {
  data: UserList[];
  message: string;
}
interface UserResponse {
  data: UserList;
  message: string;
}

const getAll = async (clientId: number) => {
  const res = await apiClient.get<GetAllUsers>(`/auth/user/list/${clientId}`);
  return res;
};

const getOne = async (id: string) => {
  const res = await apiClient.get(`/users/${id}`);
  return res;
};

const editOne = async (reqObj: Partial<UserSignUpRequest>, id: number) => {
  const res = await apiClient.put<UserResponse>(
    `/auth/admin/update/${id}`,
    reqObj
  );
  return res;
};

const disableEnable = async (status: boolean, id: number) => {
  const reqObj = { setIsActive: status };
  const res = await apiClient.put<UserResponse>(
    `/auth/admin/update/${id}`,
    reqObj
  );
  return res;
};

const deleteOne = async (id: number) => {
  const res = await apiClient.delete<{ message: string }>(
    `/auth/admin/delete/${id}`
  );
  return res;
};

const addOne = async (req: UserSignUpRequest) => {
  const res = await apiClient.post<UserResponse>("/auth/signup", req);
  return res;
};

export const usersService = {
  getAll,
  getOne,
  editOne,
  disableEnable,
  deleteOne,
  addOne,
};
