import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useUser = () => {
  const { user, impersonate } = useSelector((state: RootState) => state.user);
  const { accessToken, clientId, firstName, lastName } = user;
  const isImpersonationActive = impersonate && impersonate.isActive;
  const roleCode = isImpersonationActive ? impersonate.roleCode : user.roles.RoleCode;
  const roleName = isImpersonationActive ? impersonate.roleName : "";

  return {
    firstName,
    lastName,
    accessToken,
    roleCode,
    roleName,
    clientId,
    isImpersonationActive,
    originalRoleCode: user.roles.RoleCode,
    originalRoleName: user.roles.VisualName,
  };
};
