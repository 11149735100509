import { apiClient } from ".";
import { EditUserRequest, EditUserResponse as EditUserResponseData, IUser } from "../interfaces";

interface EditUserResponse {
  data: EditUserResponseData;
  message: string;
}

export interface UserLoginRequest {
  email: string;
  password: string;
}

export interface UserForgotPasswordResponse {
  message: string;
  password: string;
}

export interface UserResetPasswordResponse {
  message: string;
}

export interface GenerateResetPasswordLinkResponse {
  data: {
    token: string;
    link: string;
  };
}

const login = async (requestObject: UserLoginRequest) => {
  const res = await apiClient.post<IUser>(`/auth/signin`, requestObject);
  return res;
};

const forgotPassword = async (reqObj: { email: string }) => {
  const res = await apiClient.post<UserForgotPasswordResponse>("/auth/reset-mail", reqObj);
  return res;
};

const resetPassword = async (reqObj: { password: string }, token: string) => {
  const res = await apiClient.post<UserForgotPasswordResponse>(
    `/auth/reset-password/${token}`,
    reqObj
  );
  return res;
};

const editSelf = async (reqObj: Partial<EditUserRequest>) => {
  const res = await apiClient.put<EditUserResponse>(`/auth/me/update`, reqObj);
  return res;
};

const generateResetPasswordLink = async (email: string) => {
  const res = await apiClient.post<GenerateResetPasswordLinkResponse>(
    `/auth/generate-reset-password-link`,
    { email }
  );
  return res;
};

export const userService = {
  login,
  editSelf,
  forgotPassword,
  resetPassword,
  generateResetPasswordLink,
};
