export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/password-create",
  CREATE_PASSWORD_SUCCESS: "/password-create-success",
  DASHBOARD: "/dashboard",
  ROLE_MAPPING: "/role-mapping",
  SEARCH: {
    LAYOUT: "/search",
    HOME: "",
    RESULT: "result",
  },
  PATIENTS_LIST: "/subjects",
  PATIENT_PROFILE: "/subject",
  EVIDENCE_VIEWER: "/evidence",
  CLIENT_DETAIL: "/client/detail",
  SETTINGS: "/settings",
  PROJECT_DETAIL: "/project/detail",
  ANNOTATE_FILE: "/annotate-file",
  ANNOTATION: "/annotation",
  CONFIGURATIONS: "/configurations",
  COHORT: "/cohort",
  REDIRECTION: "/redirect",
};

export const PAGE_TITLE = [
  {
    route: PAGE_URL.DASHBOARD,
    title: "Welcome to Autonomize",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.PATIENT_PROFILE,
    title: "Subject Insights",
    displayBackButton: true,
  },
  {
    route: PAGE_URL.PATIENTS_LIST,
    title: "Subjects",
    displayBackButton: false,
  },
  { route: PAGE_URL.SEARCH.LAYOUT, title: "Search", displayBackButton: false },
  { route: PAGE_URL.SETTINGS, title: "Settings", displayBackButton: false },
  {
    route: PAGE_URL.PROJECT_DETAIL,
    title: "Project Detail",
    displayBackButton: true,
  },
  {
    route: PAGE_URL.EVIDENCE_VIEWER,
    title: "Evidence Viewer",
    displayBackButton: true,
  },
  {
    route: PAGE_URL.ROLE_MAPPING,
    title: "Roles Mapping",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.CONFIGURATIONS,
    title: "Configurations",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.ANNOTATION,
    title: "Upload File for Annotation",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.ANNOTATE_FILE,
    title: "Annotate File",
    displayBackButton: false,
  },
  { route: PAGE_URL.COHORT, title: "Cohort Subjects", displayBackButton: true },
  {
    route: PAGE_URL.REDIRECTION,
    title: "Authentication",
    displayBackButton: false,
  },
];
