import axios from "axios";
import { apiClient } from ".";
import {
  IPagination,
  PatientStatus,
  Project,
  ProjectDetail,
  ProjectMeta,
  ProjectUser,
} from "../interfaces";
import { UserWithProject } from "../store/data/types";
import { DOCUMENT_STATUS } from "../utils";

export interface RecentDocumentStatus {
  Id: number;
  DocumentProcessId: number;
  Status: string;
  createdAt: string;
  updatedAt: string;
  message: string;
}

export interface RecentDocument {
  Id: number;
  DocumentUUID: string;
  BucketName: null;
  FileName: string;
  ProjectId: number;
  createdAt: string;
  updatedAt: string;
  DocumentProcessStatuses: RecentDocumentStatus[];
}

interface FileListResponse {
  message: string;
  data: RecentDocument[];
  pagination: IPagination;
}

export interface GetAllProjectResponse {
  data: Project[];
  message: string;
}

export interface AddEditProjectRequest {
  name: string;
  description: string;
  s3Folder: string;
  meta?: ProjectMeta[];
}

export interface GetOneProjectResponse {
  data: Project;
  message: string;
}

export interface ProjectDetailResponse {
  data: ProjectDetail;
  message: string;
}

export interface GetUsersWithProjectsResponse {
  data: UserWithProject[];
  message: string;
}

export interface PatientStatusResponse {
  data: PatientStatus[];
  message: string;
}

export interface ProjectUserResponse {
  data: ProjectUser;
  message: string;
}

export interface UpdatePatientStatusResponse {
  data: {
    CreatedBy: number;
    Id: number;
    IsDeleted: boolean;
    OrganizationId: number;
    PatientProjectId: number;
    ProjectStatusId: number;
    UpdatedBy: number;
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
  };
  message: string;
}

export interface GetS3UploadUrlResponse {
  message: string;
  response: string;
}

export interface ProjectDashboardStats {
  status: string;
  count: number;
  colorCode: string;
}

interface ProjectDashboardStatsResponse {
  message: string;
  data: ProjectDashboardStats[];
}

interface DeleteProjectMetaResponse {
  data: {
    status: string;
    meta: ProjectMeta[];
  };
  message: string;
}

const getAll = async (organisationId: number) => {
  const res = await apiClient.get<GetAllProjectResponse>(
    `/project/data/org/${organisationId}`
  );
  return res;
};

const getFiles = async (qs: string) => {
  const res = await apiClient.get<FileListResponse>(
    `/document-process/list${qs}`
  );
  return res;
};

const getS3UploadURL = async (projectId: number, fileName: string) => {
  const res = await apiClient.get<GetS3UploadUrlResponse>(
    `/file-manager/upload-url?project_id=${projectId}&file_name=${fileName}`
  );
  return res;
};

const uploadFiles = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: {
      "Content-Type": `multipart/form-data;`,
      "x-ms-blob-type": "BlockBlob",
    },
  });
  return res;
};

const createDocument = async (projectId: number, fileName: string) => {
  const res = await apiClient.post(`/document-process`, {
    FileName: fileName,
    ProjectId: projectId,
    Status: DOCUMENT_STATUS.FILE_UPLOADED,
  });
  return res;
};

const deleteDocument = async(fileUUID: string) => {
  const res = await apiClient.delete(`/document/${fileUUID}`);
  return res;
}

const getOne = async (clientId: number, projectId: string) => {
  const res = await apiClient.get<ProjectDetailResponse>(
    `/project/data/${projectId}/org/${clientId}`
  );
  return res;
};

const editOne = async (
  clientId: number,
  projectId: number,
  reqObj: AddEditProjectRequest
) => {
  const res = await apiClient.put<GetOneProjectResponse>(
    `/project/data/${projectId}/org/${clientId}`,
    reqObj
  );
  return res;
};

const addOne = async (clientId: number, reqObj: AddEditProjectRequest) => {
  const res = await apiClient.post<GetOneProjectResponse>(
    `/project/data/org/${clientId}`,
    reqObj
  );
  return res;
};

const deleteOne = async (clientId: number, projectId: number) => {
  const res = await apiClient.delete(
    `/project/data/${projectId}/org/${clientId}`
  );
  return res;
};

const deleteProjectMeta = async (projectId: number, fieldKey: string) => {
  const res = await apiClient.delete<DeleteProjectMetaResponse>(
    `/project/${projectId}/field-key/${fieldKey}/meta`
  );
  return res;
};

const addUser = async (clientId: number, projectid: number, userid: number) => {
  const res = await apiClient.post(`/project/user/org/${clientId}`, {
    projectid,
    userid,
  });
  return res;
};

const removeUser = async (
  clientId: number,
  projectid: string,
  userid: number
) => {
  const res = await apiClient.delete(`/project/user/${clientId}`, {
    data: {
      projectid: Number(projectid),
      userid,
    },
  });
  return res;
};

const disableEnable = async (clientId: number, status: boolean, id: number) => {
  const reqObj = { status };
  const res = await apiClient.put<GetOneProjectResponse>(
    `/project/data/org/${clientId}/project/${id}/status`,
    reqObj
  );
  return res;
};

const getUsersWithProjects = async (clientId: number) => {
  const res = await apiClient.get<GetUsersWithProjectsResponse>(
    `/project/user/list/org/${clientId}`
  );
  return res;
};

const disableEnableProjectUsers = async (
  relationId: number,
  status: boolean
) => {
  const reqObj = { status };
  const res = await apiClient.put<ProjectUserResponse>(
    `/project/user/relation/${relationId}`,
    reqObj
  );
  return res;
};

const getPatientStatusList = async (clientId: number) => {
  const res = await apiClient.get<PatientStatusResponse>(
    `/project/status/list/org/${clientId}`
  );
  return res;
};

const updatePatientStatus = async (
  projectStatusId: number,
  patientProjectId: number
) => {
  const res = await apiClient.put<UpdatePatientStatusResponse>(
    `/project/patient/${patientProjectId}/status/${projectStatusId}`
  );
  return res;
};

const getProjectDashboardStats = async (
  clientId: number,
  projectId: number
) => {
  const res = await apiClient.get<ProjectDashboardStatsResponse>(
    `dashboard/status/org/${clientId}/project/${projectId}`
  );
  return res;
};

const validateICDCode = async(code: string) => {
  const res = await apiClient.post(`/project/validate-icd-code`, {
    icdCode: code,
  });
  return res;
}

export const projectService = {
  getAll,
  getOne,
  editOne,
  addOne,
  deleteOne,
  disableEnable,
  getFiles,
  addUser,
  removeUser,
  getUsersWithProjects,
  disableEnableProjectUsers,
  getPatientStatusList,
  updatePatientStatus,
  getProjectDashboardStats,
  getS3UploadURL,
  uploadFiles,
  createDocument,
  deleteDocument,
  deleteProjectMeta,
  validateICDCode
};
