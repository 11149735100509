import React from "react";
import { IconsProps } from "./Icons";
const ChartOrangeIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      fill="none"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 211 49"}
      width={width ? width : "211"}
      height={height ? height : "49"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_101_6)">
        <path
          d="M8.52966 18.6667C8.52966 19.88 6 20.6288 6 21.6667V42H205L199.941 6C199.941 11.709 194.192 17.1612 189.354 22.4074C187.021 24.937 185.055 27.3649 182.28 29.8333C180.824 31.1285 179.111 34.2893 175.487 34.9259C171.219 35.6758 170.428 32.2723 170.428 31.3519C170.428 27.1344 167.872 21.9889 162.183 18.4074C158.926 16.3569 155.169 19.1094 154.407 20.3148C153.83 21.2274 152.029 21.8989 151.456 22.8519C150.936 23.7147 151.503 24.6477 150.94 25.4815C149.721 27.2892 147.931 29.3237 142.789 29.9259C141.479 30.0794 138.131 27.9107 136.746 27.5C134.021 26.6921 133.206 26.6667 129.579 26.6667C125.282 26.6667 123.395 26.8557 123.208 24.9259C123.066 23.4729 120.232 20.846 118.242 19.7222C116.119 18.5232 114.558 16.2548 113.932 14.8333C113.309 13.4177 109.908 11.1052 106.999 10.1852C104.529 9.4041 103.424 6.86648 100.253 8.25926C98.2421 9.14263 95.1806 9.76767 93.3202 10.7037C90.2042 12.2714 87.5526 13.9096 83.951 15.3333C82.2323 16.0128 77.1789 18.3728 74.7225 17.8333C72.1746 17.2738 72.976 15.756 71.3027 15C66.7021 12.9215 59.3558 11.9676 55.0942 15C52.0371 17.1753 43.8237 20.337 37.6676 21.1481C31.9003 21.9081 28.3109 19.5063 25.0193 17.8333C21.3203 15.9533 22.8473 13.0144 16.9619 11.5926C13.2393 10.6933 13.9132 10.5224 10.5909 11.6481C8.18031 12.4649 8.52966 13.4889 8.52966 14.6667V18.6667Z"
          fill="url(#paint0_linear_101_6)"
          fillOpacity="0.6"
        />
      </g>
      <path
        d="M6 21.6489C6 20.6122 8.54348 19.8642 8.54348 18.6523C8.54348 17.3205 8.54348 15.9886 8.54348 14.6568C8.54348 13.4804 8.19222 12.4576 10.6159 11.6417C13.9564 10.5173 13.2788 10.688 17.0217 11.5862C22.9393 13.0064 21.404 15.942 25.1232 17.8199C28.4327 19.491 32.0417 21.8901 37.8406 21.1309C44.0303 20.3207 52.2886 17.1626 55.3623 14.9898C59.6473 11.9608 67.0337 12.9137 71.6594 14.9898C73.3419 15.7449 72.536 17.261 75.0978 17.8199C77.5677 18.3588 82.6487 16.0014 84.3768 15.3227C87.9981 13.9006 90.6642 12.2643 93.7971 10.6984C95.6677 9.76339 98.746 9.13906 100.768 8.25669C103.956 6.86549 105.067 9.40023 107.551 10.1804C110.476 11.0994 113.896 13.4093 114.522 14.8233C115.15 16.2431 116.72 18.509 118.855 19.7066C120.856 20.8292 123.706 23.4531 123.848 24.9044C124.037 26.832 125.933 26.6432 130.254 26.6432C133.901 26.6432 134.72 26.6686 137.46 27.4756C138.853 27.8858 142.219 30.0521 143.536 29.8988C148.706 29.2972 150.506 27.265 151.732 25.4594C152.297 24.6265 151.727 23.6946 152.25 22.8327C152.827 21.8808 154.637 21.2101 155.217 20.2986C155.984 19.0945 159.762 16.3452 163.036 18.3933C168.756 21.9707 171.326 27.1104 171.326 31.3231C171.326 32.2425 172.122 35.6421 176.413 34.8931C180.056 34.2572 181.779 31.1 183.243 29.8063C186.033 27.3406 188.009 24.9155 190.355 22.3888C195.22 17.1486 201 11.7025 201 6"
        stroke="#FC794C"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_f_101_6"
          x="0"
          y="0"
          width="211"
          height="48"
          filterUnits="userSpaceOnUse"
          // colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_101_6" />
        </filter>
        <linearGradient
          id="paint0_linear_101_6"
          x1="105.5"
          y1="6"
          x2="105.5"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC794C" />
          <stop offset="0.796875" stopColor="#FE4100" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChartOrangeIcon;
