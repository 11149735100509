import { Button, Tag, Progress } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectService } from "../../../api";
import { ProjectDetail, ProjectMeta, SummaryTypes } from "../../../interfaces";
import {
  openModal,
  removeProjectConfiguration,
  RootState,
  setEditSummaryData,
  setProjectConfiguration,
  setProjectDetail,
  updateDeleteConfirmation,
} from "../../../store";
import {
  isEmptyObject,
  MODAL_TYPE,
  openNotificationWithIcon,
} from "../../../utils";
import "./project-specific-configurations.scss";
import { ConfigIcon, DeleteIcon, EditIcon } from "../../../assets/icons";
import { Summary } from "../../patient-insight/patient-insight-container";
import SummarySection from "./SummarySection";

const NoProjectConfigurations = () => {
  const dispatch = useDispatch();
  return (
    <div className="project-specific-config-add basic-detail-content flex-center">
      <div>
        <h3>Add New Insights Field</h3>
        <p>
          Currently no data added in configuration please add by clicking below
          button
        </p>
        <div className="insigt-button-container">
        <Button
          className="outline"
          type="primary"
          onClick={() =>
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION))
          }
        >
          Add New Insights Field
        </Button>
        <Button
          className="outline"
          type="primary"
          onClick={() =>
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_SUMMARIZATION_TASK))
          }
        >
          Add Summarization task
        </Button>
        
        </div>
      </div>
    </div>
  );
};

interface ProjectConfigurationSectionProps {
  configuration: ProjectMeta;
  projectDetail: ProjectDetail;
}
const ProjectConfigurationSection = ({
  configuration,
  projectDetail,
}: ProjectConfigurationSectionProps) => {
  const { field_content, field_name: title } = configuration;
  const { details, threshold_confidence_score } = field_content;
  const dispatch = useDispatch();
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed
  );
  const editDeleteConfigObj = useSelector(
    (state: RootState) => state.project.projectConfiguration
  );
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleOnEditClick = useCallback(() => {
    dispatch(setProjectConfiguration(configuration));
    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION));
  }, [configuration, dispatch]);

  const handleOnDeleteClick = useCallback(() => {
    dispatch(setProjectConfiguration(configuration));
    dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
  }, [configuration, dispatch]);

  useEffect(() => {
    const deleteConfig = async () => {
      setDeleteLoading(true);
      try {
        const { data } = await projectService.deleteProjectMeta(
          projectDetail.Id,
          editDeleteConfigObj.field_key
        );
        dispatch(setProjectDetail({ ...projectDetail, meta: data.data.meta }));
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
      } finally {
        dispatch(updateDeleteConfirmation(false));
        dispatch(removeProjectConfiguration());
        setDeleteLoading(false);
      }
    };
    if (
      isDeleteConfirmed &&
      !isEmptyObject(editDeleteConfigObj) &&
      editDeleteConfigObj.field_key === configuration.field_key
    )
      deleteConfig();
  }, [
    editDeleteConfigObj,
    dispatch,
    isDeleteConfirmed,
    projectDetail,
    configuration.field_key,
  ]);  
  return (
    <div className="project-specific-config-edit-container">
      <div className="container-title flex gp aic jcsb ">
        <h4 className="mb-0">Title: {title}</h4>
        <div className="flex gp">
          <Button
            className="icon-btn"
            icon={<EditIcon />}
            onClick={handleOnEditClick}
            disabled={deleteLoading}
          ></Button>
          <Button
            className="icon-btn"
            icon={<DeleteIcon />}
            onClick={handleOnDeleteClick}
            loading={deleteLoading}
          ></Button>
        </div>
      </div>
      <div className="container-content customize-ant-tag">
        <div className="title-group">
          <h3>Title</h3>
          <h4 className="m-0">{title}</h4>
        </div>
        <div className="probability-group">
          <div className="flex jcsb aic">
            <h3>Field confidence score</h3>
            <h3>{`${threshold_confidence_score}%`}</h3>
          </div>
          <Progress
            strokeLinecap="butt"
            type="line"
            strokeColor="#3b70cd"
            width={45}
            strokeWidth={8}
            percent={threshold_confidence_score}
            showInfo={false}
          />
        </div>
        <div className="detail-group">
          <h3>Field Details</h3>
          <div>
            {details.map((item, index) => (
              <Tag key={`field-content-detail-${index}`}>
                {item.icdCode}/{item.label}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ProjectSpecificConfigurationsProps {
  projectDetail: ProjectDetail;
}

export const ProjectSpecificConfigurations = ({
  projectDetail,
}: ProjectSpecificConfigurationsProps) => {
  const dispatch = useDispatch();
  const projectId = useSelector(
    (state: RootState) => state.project.detail.detail.Id || -1,
  );
  return (
    <div className="project-specific-config">
      {projectDetail.meta?.length > 0 ? (
        <div className="project-specific-config-edit">
          <div className="flex jcsb aic">
            <p className="project-detail-container-left-title flex aic gp">
              <ConfigIcon className="icon-config" />
              Configurations
            </p>
            <Button
              className="fill"
              type="primary"
              onClick={() =>
                dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION))
              }
            >
              Add New Insights Field
            </Button>
          </div>
          <div className="project-configuration-container">
            {projectDetail.meta?.map((item, index) => (
              <ProjectConfigurationSection
                key={`project-configuration-${index}`}
                configuration={item}
                projectDetail={projectDetail}
              />
            ))}
          </div>  
        </div>
      ) : (
        <NoProjectConfigurations />
      )}
      <div className="flex jcsb aic" style={{marginTop: '50px'}}>
        <p className="project-detail-container-left-title flex aic gp">
          Summary
        </p>
        <Button
          className="fill"
          type="primary"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_SUMMARIZATION_TASK));
            dispatch(setEditSummaryData({} as SummaryTypes));
          }}
        >
          Add New Summary Task
        </Button>
      </div>
      <div className="project-configuration-container">
        <SummarySection projectId={projectId} />
      </div>
    </div>
  );
};
