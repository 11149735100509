import React from "react";
import { IconsProps } from "./Icons";

export const ThumbsUp = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 18 18"}
      width={width ? width : "18"}
      height={height ? height : "18"}
      fill={fill ? fill : "currentColor"}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M16.5348 6.75417C16.1407 6.28667 15.5723 6.02084 14.9582 6.02084H11.264V3.50001C11.264 2.10667 10.1365 0.979172 8.66984 0.979172C8.02817 0.979172 7.45067 1.36417 7.194 1.96001L4.86567 7.39584H3.1515C1.95067 7.39584 0.979004 8.3675 0.979004 9.56834V14.8575C0.979004 16.0492 1.95984 17.0208 3.1515 17.0208H13.7482C14.7473 17.0208 15.5998 16.3058 15.774 15.3158L16.984 8.44084C17.0848 7.83584 16.9198 7.22167 16.5257 6.75417H16.5348ZM3.1515 15.6458C2.7115 15.6458 2.354 15.2883 2.354 14.8575V9.56834C2.354 9.12834 2.7115 8.77084 3.1515 8.77084H4.62734V15.6458H3.1515ZM14.4265 15.0775C14.3715 15.4075 14.0873 15.6458 13.7482 15.6458H6.0115V8.22084L8.459 2.49167C8.49567 2.40917 8.57817 2.345 8.75234 2.345C9.38484 2.345 9.889 2.85834 9.889 3.49084V7.38667H14.9582C15.169 7.38667 15.3523 7.46917 15.4807 7.625C15.609 7.78084 15.664 7.9825 15.6273 8.19334L14.4173 15.0683L14.4265 15.0775Z" fill="#635F6A"/>
    </svg>
  );
};
