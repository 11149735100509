import React from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { createSearchParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AnnotationIcon, DeleteIcon } from "../../../assets/icons";
import { getLocalDateTimeStringFromISO, MODAL_TYPE, PAGE_URL } from "../../../utils";
import { AnnotationProjectFile } from "../../../interfaces";
import { openModal, updateAnnotationFileIdToDelete } from "../../../store";
import "./annotation-project-detail.scss";

interface DataType {
  key: React.Key;
  fileId: number;
  projectId: number;
  fileName: string;
  createdOn: string;
  createdBy: string;
  action: string;
}

const getColumns = (onFileDeleteClick: (fileId: number) => void): ColumnsType<DataType> => [
  {
    title: "File Name",
    dataIndex: "fileName",
    // render: (value, record) => <p>{`${record.fileId} - ${record.fileName}`}</p>,
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
  {
    title: "Created On",
    dataIndex: "createdOn",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value, record) => (
      <div className="flex gp jcc">
        <Link
          to={{
            pathname: `${PAGE_URL.ANNOTATE_FILE}/${record.fileName}`,
            search: createSearchParams({
              fileId: record.fileId.toString(),
              projectId: record.projectId.toString(),
            }).toString(),
          }}
        >
          <AnnotationIcon />
        </Link>
        <DeleteIcon onClick={() => onFileDeleteClick(record.fileId)} />
      </div>
    ),
  },
];

interface Props {
  files: AnnotationProjectFile[];
  loading: boolean;
}

export const AnnotationProjectDetailFileList = ({ files, loading }: Props) => {
  const dispatch = useDispatch();
  const dataSource: DataType[] = files.map((item, index) => {
    const { CreatedBy, FileName, Id, ProjectId, CreatedAt } = item;
    return {
      key: `annotation-files-${index}`,
      fileId: Id,
      projectId: ProjectId,
      fileName: FileName,
      createdBy: CreatedBy,
      createdOn: getLocalDateTimeStringFromISO(CreatedAt),
      action: "Annotate",
    };
  });

  const onFileDeleteClick = (fileId: number) => {
    dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
    dispatch(updateAnnotationFileIdToDelete(fileId));
  };

  return (
    <div className="flex gp p-block">
      <Table
        className="annotation-table basic-detail-content"
        rowClassName="cursor-pointer"
        columns={getColumns(onFileDeleteClick)}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
      />
    </div>
  );
};
