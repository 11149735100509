import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useOutletContext } from "react-router-dom";
import { useAuthorization, useFetchSearchResult } from "../../../hooks";
import { RootState, setSearchResult } from "../../../store";
import { SearchResults } from ".";
import "./search-result.scss";
import {
  STATUS_CODE,
  getOffsetFromPageAndPageSize,
  openNotificationWithIcon,
} from "../../../utils";

export const SearchResult = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    fetchSearchResults,
    searchLoading,
    searchResult: data,
    isApiCalled,
    onPaginationChange,
    page,
    pageSize,
    pageSizeOptions,
    totalSearchResults,
  } = useFetchSearchResult();
  const { setIsAuthorized } = useAuthorization();
  const autoSearch = searchParams.get("auto") === "true";
  const [callApi, setCallApi] = useOutletContext<any>();
  const { result } = useSelector((state: RootState) => state.search);

  const qs = useMemo(() => {
    if (!searchParams.get("patient_id")) {
      return `?limit=${pageSize}&offset=${getOffsetFromPageAndPageSize(
        page,
        pageSize
      )}`;
    } else {
      return `?limit=100&offset=0`;
    }
  }, [page, pageSize, searchParams]);

  useEffect(() => {
    setCallApi(true);
  }, [setCallApi, page, pageSize]);

  useEffect(() => {
    if (isApiCalled) {
      dispatch(setSearchResult(data));
    }
  }, [isApiCalled, data, dispatch]);

  useEffect(() => {
    const handlefetchSearchResults = async () => {
      try {
        if (callApi || autoSearch) {
          fetchSearchResults(qs);
          setCallApi(false);
        }
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      }
    };
    handlefetchSearchResults();
  }, [
    autoSearch,
    callApi,
    fetchSearchResults,
    setCallApi,
    qs,
    setIsAuthorized,
  ]);

  return (
    <div className="search-result">
      <SearchResults
        result={result}
        loading={searchLoading}
        {...{
          page,
          pageSize,
          pageSizeOptions,
          onPaginationChange,
          totalSearchResults,
        }}
      />
    </div>
  );
};
