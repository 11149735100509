import { Dispatch } from "redux";
import { connect } from "react-redux";
import AddEditProjectConfiguration from "./AddEditProjectConfiguration";
import {
  closeModal,
  removeProjectConfiguration,
  RootState,
  setProjectDetail,
} from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { ProjectDetail, ProjectMeta } from "../../../interfaces";
import { isEmptyObject } from "../../../utils";

type StateProps = {
  visibility: boolean;
  isEdit: boolean;
  editObj: ProjectMeta;
  projectDetail: ProjectDetail;
};
type DispatchProps = {
  handleClose: () => void;
  updateProjectDetail: (detail: ProjectDetail) => void;
};
export type AddEditProjectConfigurationProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const config = state.project.projectConfiguration;
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION],
    isEdit: !isEmptyObject(config),
    editObj: config,
    projectDetail: state.project.detail.detail,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(removeProjectConfiguration());
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION));
  },
  updateProjectDetail: (value) => {
    dispatch(setProjectDetail(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProjectConfiguration);
