import React from "react";
import { getNoEntryBelowThresholdMessage, NO_ENTRY_FOUND } from ".";

interface Props {
  customMessage?: any;
  belowEightyLength: number;
  aboveEightyLength: number;
  threshold?: number;
}

export const NoResultsFoundMessage = ({
  aboveEightyLength,
  belowEightyLength,
  customMessage,
  threshold,
}: Props) => {
  if (aboveEightyLength > 0) return null;
  const message =
    belowEightyLength > 0 && threshold
      ? getNoEntryBelowThresholdMessage(threshold)
      : NO_ENTRY_FOUND;
  return <div>{customMessage ? customMessage : <p className="placeholder-text">{message}</p>}</div>;
};
