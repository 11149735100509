import { Dispatch } from "redux";
import { connect } from "react-redux";
import AddEditClient from "./AddEditClient";
import {
  addNewClient,
  closeModal,
  RootState,
  setClientEditDelete,
  updateClient,
} from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { Client } from "../../../store/data/types";

type StateProps = {
  visibility: boolean;
  isEdit: boolean;
  editObj: Client;
};
type DispatchProps = {
  handleClose: () => void;
  handleUpdate: (client: Client) => void;
  handleAdd: (client: Client) => void;
};
export type AddEditClientProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const isEdit = state.data.clients.isEdit;
  const id = state.data.clients.id;
  let editObj = {} as Client;
  if (isEdit && id !== -1) {
    const obj = state.data.clients.list.find((item) => item.id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_CLIENT],
    isEdit,
    editObj,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setClientEditDelete({ id: -1, isEdit: false }));
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_CLIENT));
  },
  handleUpdate: (client: Client) => dispatch(updateClient(client)),
  handleAdd: (client: Client) => dispatch(addNewClient(client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditClient);
