import { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect } from "react";
import { Table } from "antd";
import { AppPagination } from "../../../components";
import { usePagination } from "../../../hooks";
import { IFetchCohortObj, useFetchCohorts } from "../../../hooks/useFetchCohorts";
import {
  getLocalDateTimeStringFromISO,
  getOffsetFromPageAndPageSize,
  MODAL_TYPE,
  PAGE_URL,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  RootState,
  setCohortId,
  setCohortName,
  setRetryGetCohort,
} from "../../../store";

interface ICohortListDataType {
  key: React.Key;
  cohortName: string;
  createdAt: string;
  updatedAt: string;
  editCohort: JSX.Element;
}

const getColumns = (): ColumnsType<ICohortListDataType> => [
  {
    title: "Cohort Name",
    dataIndex: "cohortName",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
  },
  {
    title: "Action",
    dataIndex: "editCohort",
  },
];

export const CohortList = () => {
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const { fetchCohorts, cohortsLoading, cohorts, totalCohorts } = useFetchCohorts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { retryGetCohort } = useSelector((state: RootState) => state.cohort);

  const cohortData: ICohortListDataType[] = cohorts.map((cohort) => ({
    key: cohort.Id,
    cohortName: cohort.Name,
    createdAt: getLocalDateTimeStringFromISO(cohort.createdAt),
    updatedAt: getLocalDateTimeStringFromISO(cohort.updatedAt),
    editCohort: (
      <a
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setCohortName(cohort.Name));
          dispatch(setCohortId(cohort.Id.toString()));
          dispatch(openModal(MODAL_TYPE.EDIT_COHORT));
        }}
      >
        Edit
      </a>
    ),
  }));

  const getCohorts = useCallback(async () => {
    const obj: IFetchCohortObj = {
      offset: getOffsetFromPageAndPageSize(page, pageSize),
      limit: pageSize,
    };
    await fetchCohorts(obj);
  }, [fetchCohorts, page, pageSize]);

  useEffect(() => {
    getCohorts();
  }, [getCohorts]);

  useEffect(() => {
    if (retryGetCohort) {
      getCohorts();
    }
    dispatch(setRetryGetCohort(false));
  }, [retryGetCohort, getCohorts, dispatch]);

  return (
    <div className="patients-tab">
      <h3 className="pb-0">Cohorts</h3>
      <AppPagination
        {...{
          showSizeChanger: true,
          current: page,
          total: totalCohorts,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
      <Table
        className="basic-detail-content tb-scroll four-column"
        rowClassName="cursor-pointer"
        columns={getColumns()}
        dataSource={cohortData}
        loading={cohortsLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              dispatch(setCohortId(record.key as string));
              navigate(`${PAGE_URL.COHORT}/${record.key}`);
            },
          };
        }}
        pagination={false}
      />
    </div>
  );
};
