import React, { useMemo } from "react";
import { EvidencePageResponseInfo } from "../../../api";
import { getEvidenceViewerMedicalEntitiesText } from ".";
import { getThresholdConfidenceScore } from "../../../utils";
import { ISources } from "../../../interfaces";

interface EvidenceViewerDefaultProps {
  pageInfo: EvidencePageResponseInfo;
  searchKeywords: string;
  selectedLegends: string[];
  sources: ISources;
}

interface EvidenceViewerOCRProps {
  pageInfo: EvidencePageResponseInfo;
}

export const EvidenceViewerDefault = ({
  pageInfo,
  searchKeywords,
  selectedLegends,
  sources
}: EvidenceViewerDefaultProps) => {
  console.log(sources)
  const highlightedSourceContent = useMemo(() => {
    const source = sources?.pages?.find(
      (item) => item.pageNo === pageInfo.pageNumber
    );
    if(source){
      let content = pageInfo.content;
      const terms = source.offsets.map((offset) =>
      pageInfo.content.substring(offset.begin, offset.end)
      );
      terms.forEach((term) => {
        const html = `<span class="bg-yellow">${term}</span>`;
        content = content.replace(term, html);
      });
      content = content.replaceAll(`\n`, `<br/>`);
      return content;
    }
    return pageInfo.content;
  }, [pageInfo.content]);

  return (
    <div className="e-text">
      <h2>{pageInfo.heading}</h2>
      <div
        className="e-paragraph"
        dangerouslySetInnerHTML={{
          __html: getEvidenceViewerMedicalEntitiesText(
            highlightedSourceContent,
            pageInfo.medicalEntities.length > 0 ? pageInfo.medicalEntities : [],
            searchKeywords,
            selectedLegends
          ),
        }}
      />
    </div>
  );
};

export const EvidenceViewerOCR = ({ pageInfo }: EvidenceViewerOCRProps) => {
  return (
    <div className="e-text">
      <h2>{pageInfo.heading}</h2>
      <div className="e-paragraph">{pageInfo.content}</div>
    </div>
  );
};
