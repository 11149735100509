import React, { Dispatch, SetStateAction, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./text-editor.scss";
import CustomToolbar from "./CustomToolbar";

interface Props {
  text: string;
  setText?: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  isDisabled?: boolean;
  displayToolbar?: boolean;
}

const modules = {
  toolbar: {
    container: "#toolbar",
  },
};
const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  // "video",
  // 'formula',
];
export const TextEditor = ({ text, setText, placeholder, displayToolbar, isDisabled }: Props) => {
  const handleChange = useCallback(
    (html: string) => {
      setText && setText(html);
    },
    [setText]
  );

  return (
    <>
      <CustomToolbar />
      <ReactQuill
        value={text}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        readOnly={isDisabled ? true : false}
        theme={displayToolbar ? "snow" : "bubble"}
      />
    </>
  );
};
