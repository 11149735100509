import React, { useEffect, useState } from "react";
import { Modal, AutoComplete } from "antd";
import { Button, Form } from "antd";
import { AddUserToProjectProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { projectService } from "../../../api";
import { setProjectDetail } from "../../../store";
import { openNotificationWithIcon } from "../../../utils";
import { useFetchUserList } from "../../../hooks";
import { Loading } from "../../../components";

type OptionType = { value: string; id: number };

const AddUserToProject = ({
  clientId,
  visibility,
  projectId,
  projectUsers,
  handleClose,
}: AddUserToProjectProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);
  const { fetchUsers, loading: usersLoading, usersList } = useFetchUserList();
  const [error, setError] = useState("");
  const options = usersList
    // .filter((item) => item.roleId === USER_ROLES_CODE.CLINICIAN)
    .filter((item) => item.isActive)
    .map((item) => ({
      value: `${item.name} (${item.email})`,
      id: item.id,
    }));

  useEffect(() => {
    const handleFetchUsers = async () => {
      try {
        await fetchUsers();
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        handleClose();
      }
    };
    handleFetchUsers();
  }, [fetchUsers, handleClose]);

  const onSelectChange = (value: string, option: OptionType) => {
    setSelectedOption(option.id);
    setError("");
  };

  const onChange = (value: string) => {
    const find = options.findIndex((item) => item.value === value);
    if (find === -1) setSelectedOption(-1);
  };

  const handleSubmit = async () => {
    if (projectId !== -1 && selectedOption !== -1) {
      setLoading(true);
      const isAlreadyInProject = projectUsers.findIndex(
        (item) => item.id === selectedOption
      );
      if (isAlreadyInProject !== -1) {
        setError("User already added to project");
        setLoading(false);
      } else {
        try {
          await projectService.addUser(clientId, projectId, selectedOption);
          const { data: projectDetailData } = await projectService.getOne(
            clientId,
            projectId.toString()
          );
          dispatch(setProjectDetail(projectDetailData.data));
        } catch (err: any) {
          openNotificationWithIcon("", err.response.data.message, "error");
        } finally {
          setLoading(false);
          handleClose();
        }
      }
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title="Add Client"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Add team members to project</h1>
      </div>
      <div className="auto-complete addClient-content">
        <p>Select a user to add</p>
        <AutoComplete
          options={options}
          onSelect={onSelectChange}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          notFoundContent={usersLoading && <Loading />}
        />
        {error && <h3 className="error">{error}</h3>}
        <Form.Item className="tr">
          <Button
            className="outline"
            onClick={handleSubmit}
            disabled={selectedOption === -1}
            loading={loading}
          >
            Add team members to project
          </Button>
        </Form.Item>
      </div>
    </Modal>
  );
};

export default AddUserToProject;
