import { apiClient } from ".";

export interface GetAllSummaryResponse {
  id: number;
  title: string;
  question: string;
}

export interface AddSummaryTask {
  title: string;
  question: string;
  project_id?: number;
}

const getAll = async () => {
  const res = await apiClient.get<GetAllSummaryResponse>(`/summary`);
  return res;
};

const getOneById = async (summaryId: number) => {
  const res = await apiClient.get<GetAllSummaryResponse>(
    `/summary/${summaryId}`
  );
  return res;
};

const getOneByProjectId = async (project_id: number) => {
  const res = await apiClient.get(
    `/summary/project/${project_id}`
  );
  return res;
};

const editOne = async (summaryId: number, reqObj: AddSummaryTask) => {
  const res = await apiClient.patch<GetAllSummaryResponse>(
    `/summary/${summaryId}`,
    reqObj
  );
  return res;
};

const deleteOne = async (summaryId: number) => {
  const res = await apiClient.delete<GetAllSummaryResponse>(
    `/summary/${summaryId}`
  );
  return res;
};

const addOne = async (reqObj: AddSummaryTask) => {
  const res = await apiClient.post<GetAllSummaryResponse>(`/summary`, reqObj);
  return res;
};

const getPatientSummary = async (documentUUID?: string) => {
  const res = await apiClient.get(`summary/document/${documentUUID}?summaryType=GENERAL`);
  return res;
};

const getProjectSpecificSummary = async(documentUUID?: string) => {
  const res = await apiClient.get(`summary/document/${documentUUID}?summaryType=INSIGHT`);
  //   return {
  //     "message": "OK",
  //     "data": [
  //         {
  //             "Id": 9,
  //             "documentUuid": "2c77ad34-e6e9-41ef-b0e0-93c5825ffea7",
  //             "summaryId": null,
  //             "summaryType": "GENERAL",
  //             "summary": "Demographic information: The patient's name is Casey Parker, with a date of birth of December 19, 1947. The patient has a history of dyspnea on exertion, hypertension, lymphoma, and syncope. The patient has had several surgical procedures, including cesarean sections, resection of ovarian malignancy, tonsillectomies, and lymph node biopsies. The patient has a family history of CVA, malignant neoplasm of the prostate, myocardial infarction, and malignant neoplasm of the uterus.\n\nCurrent medical conditions: The patient has been diagnosed with follicular lymphoma and is being treated with Shingrix 50 MCG Intramuscular Suspension Reconstituted. The patient also has anxiety, arthritis of the right hip, atherosclerosis of the coronary artery, CAD, chest discomfort, CKD stage G3a/A1, cough, COVID-19, elevated d-dimer, elevated serum creatinine, fatigue, folliculitis, HTN, hypercalcemia, hyperlipidemia, idiopathic insomnia, left hip pain, left shoulder pain, lesion of pelvic bone, leukopenia, lumbar radiculopathy, myositis of left shoulder, osteoarthritis, pain of right hip point, parapelvic renal cyst, rotator cuff arthropathy of left shoulder, screening for colon cancer.\n\nRecent medical procedures: No recent medical procedures were mentioned in the provided clinical document.",
  //             "createdAt": "2024-03-22T17:56:40.140Z",
  //             "updatedAt": "2024-03-22T17:56:40.140Z"
  //         },
  //         {
  //           "Id": 9,
  //           "documentUuid": "2c77ad34-e6e9-41ef-b0e0-93c5825ffea7",
  //           "summaryId": null,
  //           "summaryType": "GENERAL",
  //           "summary": "Demographic information: The patient's name is Casey Parker, with a date of birth of December 19, 1947. The patient has a history of dyspnea on exertion, hypertension, lymphoma, and syncope. The patient has had several surgical procedures, including cesarean sections, resection of ovarian malignancy, tonsillectomies, and lymph node biopsies. The patient has a family history of CVA, malignant neoplasm of the prostate, myocardial infarction, and malignant neoplasm of the uterus.\n\nCurrent medical conditions: The patient has been diagnosed with follicular lymphoma and is being treated with Shingrix 50 MCG Intramuscular Suspension Reconstituted. The patient also has anxiety, arthritis of the right hip, atherosclerosis of the coronary artery, CAD, chest discomfort, CKD stage G3a/A1, cough, COVID-19, elevated d-dimer, elevated serum creatinine, fatigue, folliculitis, HTN, hypercalcemia, hyperlipidemia, idiopathic insomnia, left hip pain, left shoulder pain, lesion of pelvic bone, leukopenia, lumbar radiculopathy, myositis of left shoulder, osteoarthritis, pain of right hip point, parapelvic renal cyst, rotator cuff arthropathy of left shoulder, screening for colon cancer.\n\nRecent medical procedures: No recent medical procedures were mentioned in the provided clinical document.",
  //           "createdAt": "2024-03-22T17:56:40.140Z",
  //           "updatedAt": "2024-03-22T17:56:40.140Z"
  //       }
  //     ],
  //     "transaction_id": "5a44b7a8-9189-4422-b148-16a56dcca055"
  // }
  return res;
};

export const summaryService = {
  getAll,
  editOne,
  deleteOne,
  addOne,
  getOneById,
  getOneByProjectId,
  getPatientSummary,
  getProjectSpecificSummary
};
