import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { ClientsList } from "./dashboard-super-admin-container";
import { withAuthorization } from "../../components/hoc/withAuthorization";

const AuthorizedClientsList = withAuthorization(ClientsList);

export const SuperAdminDashboardContainer = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="flex jcsb aic clientSearch">
        <p className="table-heading">Clients</p>

        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_CLIENT));
          }}
        >
          Create New Client
        </Button>
        <AuthorizedClientsList {...{ loading, setLoading }} />
      </div>
    </>
  );
};
