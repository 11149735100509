import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientPaginationMapping } from "../../api";

interface IPatientInfo {
  id: number;
  name: string;
}
interface PatientsPaginationSlice {
  list: PatientPaginationMapping[];
  info: IPatientInfo;
}

export const patientSliceInitialState: PatientsPaginationSlice = {
  list: [] as PatientPaginationMapping[],
  info: { id: -1, name: "" },
};

export const patientSlice = createSlice({
  name: "patient",
  initialState: patientSliceInitialState,
  reducers: {
    setPatientList: (
      state: PatientsPaginationSlice,
      { payload }: PayloadAction<PatientPaginationMapping[]>
    ) => {
      state.list = [...payload];
    },
    setPatientInformation: (state: PatientsPaginationSlice, { payload }: PayloadAction<IPatientInfo>) => {
      state.info.id = payload.id;
      state.info.name = payload.name;
    },
  },
});

export const { setPatientList, setPatientInformation } = patientSlice.actions;

export default patientSlice.reducer;
