import React from "react";
import { IconsProps } from "./Icons";

export const ListDataIcon = ({ className, onClick }: IconsProps) => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0 1.57895C0 0.706919 0.671573 0 1.5 0H22.5C23.3284 0 24 0.706919 24 1.57895C24 2.45098 23.3284 3.15789 22.5 3.15789H1.5C0.671573 3.15789 0 2.45098 0 1.57895Z"
        fill="currentColor"
      />
      <path
        d="M0 10C0 9.12797 0.671573 8.42105 1.5 8.42105H22.5C23.3284 8.42105 24 9.12797 24 10C24 10.872 23.3284 11.5789 22.5 11.5789H1.5C0.671573 11.5789 0 10.872 0 10Z"
        fill="currentColor"
      />
      <path
        d="M0 18.4211C0 17.549 0.671573 16.8421 1.5 16.8421H22.5C23.3284 16.8421 24 17.549 24 18.4211C24 19.2931 23.3284 20 22.5 20H1.5C0.671573 20 0 19.2931 0 18.4211Z"
        fill="currentColor"
      />
    </svg>
  );
};
