import React from "react";
import { Col, Button } from "antd";
import { PatientProjects } from "../../../api";
import { Dropdown, Loading } from "../../../components";
import { DropdownOption } from "../../../interfaces";

interface Props {
  projects: PatientProjects[];
  selectedProject: PatientProjects;
  setSelectedProject: (item: PatientProjects) => void;
  patientProjectsLoading: boolean;
}
export const PatientProfileProjects = ({
  projects,
  selectedProject,
  setSelectedProject,
  patientProjectsLoading,
}: Props) => {
  const handleProjectChange = (projectName: string, option: DropdownOption) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedChangedProject = projects.find((project) => project.Id === option.id)!;
    setSelectedProject(selectedChangedProject);
  };
  return (
    <div className="m-b assigned-project-container">
      <Col span={24}>
        <h3 className="pb-0">Assigned Projects</h3>
        {patientProjectsLoading ? (
          <Loading />
        ) : (
          <div>
            {projects.length > 0 ? (
              <div className="assigned-project-dropdown">
                <Dropdown
                  value={selectedProject.Name}
                  options={projects.map((project) => ({
                    id: project.Id,
                    value: project.Name,
                  }))}
                  loading={patientProjectsLoading}
                  onSelect={(e, option) => handleProjectChange(e, option)}
                  style={{ width: 245 }}
                />
                {/* <div className="flex flex-column gp-10">
                  {projects.map((project) => (
                    <Button
                      key={`patient-project-${project.Id}`}
                      className={`timeline ${
                        selectedProject.Id === project.Id ? "tab-active" : "tab-inactive"
                      }`}
                      onClick={() => setSelectedProject(project)}
                    >
                      {project.Name}
                    </Button>
                  ))}
                </div> */}
              </div>
            ) : (
              <p>No project assigned to patient</p>
            )}
          </div>
        )}
      </Col>
    </div>
  );
};

export default PatientProfileProjects;
