import { Dispatch } from "redux";
import { connect } from "react-redux";
import ProjectDocumentProcessingDetailStatus from "./ProjectDocumentProcessingDetailStatus";
import { closeModal, removeProjectDocumentDetail, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { RecentDocument } from "../../../api";
import "./uploading-status.scss";

type StateProps = {
  visibility: boolean;
  documentDetail: RecentDocument;
};
type DispatchProps = {
  handleClose: () => void;
};
export type ProjectDetailProcessingDetailStatusProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS],
  documentDetail: state.project.documentDetail,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(removeProjectDocumentDetail());
    dispatch(closeModal(MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocumentProcessingDetailStatus);
