import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FeedbackForm } from "./FeedbackForm";

interface StateProps extends IQuesAnsWithPatientId {
  feedbackType: -1 | 1| 0;
  visibility: boolean;
}
type DispatchProps = {
  handleClose: () => void;
};

export type PositiveFeedbackProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.FEEDBACK],
  Question: state.feedback.Question,
  Answer: state.feedback.Answer,
  PatientId: state.feedback.PatientId,
  feedbackType: state.feedback.feedbackType,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.FEEDBACK));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
