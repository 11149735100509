import React from "react";
import { IconsProps } from "./Icons";

export const TwoByTwoTileOption = ({ className, onClick }: IconsProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0 1.875C0 0.839466 0.839466 0 1.875 0H6.875C7.91053 0 8.75 0.839466 8.75 1.875V6.875C8.75 7.91053 7.91053 8.75 6.875 8.75H1.875C0.839466 8.75 0 7.91053 0 6.875V1.875Z"
        fill="currentColor"
      />
      <path
        d="M0 13.125C0 12.0895 0.839466 11.25 1.875 11.25H6.875C7.91053 11.25 8.75 12.0895 8.75 13.125V18.125C8.75 19.1605 7.91053 20 6.875 20H1.875C0.839466 20 0 19.1605 0 18.125V13.125Z"
        fill="currentColor"
      />
      <path
        d="M11.25 1.875C11.25 0.839466 12.0895 0 13.125 0H18.125C19.1605 0 20 0.839466 20 1.875V6.875C20 7.91053 19.1605 8.75 18.125 8.75H13.125C12.0895 8.75 11.25 7.91053 11.25 6.875V1.875Z"
        fill="currentColor"
      />
      <path
        d="M11.25 13.125C11.25 12.0895 12.0895 11.25 13.125 11.25H18.125C19.1605 11.25 20 12.0895 20 13.125V18.125C20 19.1605 19.1605 20 18.125 20H13.125C12.0895 20 11.25 19.1605 11.25 18.125V13.125Z"
        fill="currentColor"
      />
    </svg>
  );
};
