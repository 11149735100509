import React from "react";
import { IconsProps } from "./Icons";

export const HeightIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 49 48"}
      width={width ? width : "49"}
      height={height ? height : "48"}
      fill={fill ? fill : "none"}
    >
        <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="0.381836" width="48" height="48" rx="6" fill="#93FF91"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M19.3818 14C21.1618 14 22.0518 16.16 20.8018 17.42C19.5418 18.67 17.3818 17.78 17.3818 16C17.3818 15.4696 17.5925 14.9609 17.9676 14.5858C18.3427 14.2107 18.8514 14 19.3818 14ZM17.8818 19H20.8818C21.4123 19 21.921 19.2107 22.296 19.5858C22.6711 19.9609 22.8818 20.4696 22.8818 21V26.5H21.3818V34H17.3818V26.5H15.8818V21C15.8818 20.4696 16.0925 19.9609 16.4676 19.5858C16.8427 19.2107 17.3514 19 17.8818 19ZM31.3818 20H33.3818L30.3818 16L27.3818 20H29.3818V28H27.3818L30.3818 32L33.3818 28H31.3818M34.3818 14H26.3818V16H34.3818M34.3818 32H26.3818V34H34.3818" fill="#64B462"/>
        
    </svg>
  );
};
