import React from "react";
import { ConfigurationObj } from "../../../api";
import { AddRemoveTags, SingleSlider } from "../../../components";
import { getThresholdConfidenceScore } from "../../../utils";

interface PatientInsightConfigurationSectionProps {
  configuration: ConfigurationObj;
  isEdit: boolean;
  editConfiguration: (obj: ConfigurationObj) => void;
}

export const PatientInsightConfigurationSection = ({
  isEdit,
  configuration,
  editConfiguration,
}: PatientInsightConfigurationSectionProps) => {
  const searchKeywords = [...configuration.field_content];
  const setSearchKeywords = (tags: string[]) => {
    editConfiguration({ ...configuration, field_content: tags });
  };

  return (
    <>
      <div>
        <h3>{configuration.field_name}</h3>
        <p>Click, Type and press enter to add new items below</p>
      </div>
      <div className="project-config-container customize-ant-tag">
        <AddRemoveTags
          setTags={setSearchKeywords}
          tags={searchKeywords}
          disableTagAddEdit={!isEdit}
          newTagTitle="Click to add new item"
          allowAddingNewTag
        />
      </div>
    </>
  );
};

export const PatientInsightConfidenceConfigurationSection = (
  props: PatientInsightConfigurationSectionProps
) => {
  const { configuration, editConfiguration, isEdit } = props;
  const sliderValue = Array.isArray(configuration.field_content)
    ? getThresholdConfidenceScore(configuration.field_content)
    : getThresholdConfidenceScore([]);
  const setSliderValue = (value: number) => {
    editConfiguration({ ...configuration, field_content: [value.toString()] });
  };

  return (
    <>
      <h3>{`${configuration.field_name} : ${sliderValue}%`}</h3>
      <div className="project-config-container">
        <SingleSlider setValue={setSliderValue} disabled={!isEdit} value={sliderValue} />
      </div>
    </>
  );
};
