import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IQuesAnsInitialState {
  quesAns: IQuesAns[];
  patientId: string;
  ansContextView: IQuesAns;
}

export const quesAnsInitialState: IQuesAnsInitialState = {
  quesAns: [] as IQuesAns[],
  patientId: "",
  ansContextView: {} as IQuesAns,
};

export const quesAnsSlice = createSlice({
  name: "quesAns",
  initialState: quesAnsInitialState,
  reducers: {
    setQuestionsAnswers: (
      state: IQuesAnsInitialState,
      { payload }: PayloadAction<IQuesAns[]>
    ) => {
      state.quesAns = [...payload];
    },
    resetQuestionAnswerState: (state: IQuesAnsInitialState, { payload }: PayloadAction<string>) => {
      state.quesAns = quesAnsInitialState.quesAns;
      state.patientId = payload;
    },
    setContextViewAns: (state: IQuesAnsInitialState, { payload }: PayloadAction<IQuesAns>) => {
      state.ansContextView = payload;
    },
  },
});

export const { setQuestionsAnswers, resetQuestionAnswerState, setContextViewAns } =
  quesAnsSlice.actions;
export default quesAnsSlice.reducer;
