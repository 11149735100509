import React from "react";
import { IconsProps } from "./Icons";

export const AnnotationIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 14 14"}
      width={width ? width : "14"}
      height={height ? height : "14"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12.9985 10.3969V3.6033C13.3017 3.43819 13.5547 3.19465 13.7309 2.89827C13.9071 2.60188 14.0001 2.26361 14 1.919C14 0.859192 13.1388 9.2476e-08 12.0766 9.2476e-08C11.7648 -9.65325e-05 11.4577 0.0755284 11.1818 0.220343C10.9059 0.365158 10.6695 0.57481 10.4929 0.831191H3.50706C3.33053 0.57481 3.0941 0.365158 2.81818 0.220343C2.54227 0.0755284 2.23516 -9.65325e-05 1.9234 9.2476e-08C0.861164 9.2476e-08 7.73567e-08 0.859192 7.73567e-08 1.919C7.73567e-08 2.64555 0.404732 3.2776 1.00149 3.6033V10.3967C0.698312 10.5618 0.445312 10.8054 0.269082 11.1017C0.0928528 11.3981 -9.78873e-05 11.7364 7.73567e-08 12.081C7.73567e-08 13.1408 0.861164 14 1.9234 14C2.73046 14 3.42028 13.5035 3.70574 12.8004H10.2941C10.5795 13.5033 11.2694 14 12.0764 14C13.1387 14 13.9998 13.1408 13.9998 12.081C13.9998 11.3546 13.5951 10.7228 12.9985 10.3969ZM2.84328 10.3956V3.60459C3.22445 3.39698 3.52433 3.06722 3.69447 2.66858H10.3057C10.4759 3.06719 10.7758 3.39693 11.1569 3.60459V10.3956C10.9032 10.5344 10.6841 10.7284 10.5156 10.9632H3.48454C3.31613 10.7284 3.09697 10.5344 2.84328 10.3956Z"
        fill="currentColor"
      />
    </svg>
  );
};
