import React, { Dispatch, useEffect, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProject,
  openModal,
  RootState,
  setProjectEditDelete,
  setProjects,
  updateDeleteConfirmation,
  updateProject,
} from '../../store';
import {
  CLINICIAN_PERMISSIONS,
  getLocalDateTimeStringFromISO,
  MODAL_TYPE,
  openNotificationWithIcon,
  PAGE_URL,
  STATUS_CODE,
} from '../../utils';
import { projectService } from '../../api';
import { useAuthorization, useUser } from '../../hooks';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { DeleteIcon } from '../../assets/icons';
import { EditIcon } from '../../assets/icons';
import './project-list.scss';
import { useNavigate } from "react-router-dom";

interface Props {
  atDashboard?: boolean;
}

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  details: string;
  clientId: number;
  status: boolean;
  createdOn: string;
}

const getColumns = (
  dispatch: Dispatch<AnyAction>,
  roleCode: string,setProjectId : React.Dispatch<React.SetStateAction<number>>): ColumnsType<DataType> => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    sorter : {
      compare: (a, b) => a.name.localeCompare(b.name)
    }
  },
  {
    title: 'Created On',
    key: 'createdOn',
    dataIndex: 'createdOn',
    sorter: {
      compare: (a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
    },
  },
  {
    title: 'Action',
    key: 'details',
    dataIndex: 'details',
    render: (text, value) => {
      const onChange = async (status: boolean) => {
        try {
          const { data } = await projectService.disableEnable(
            value.clientId,
            status,
            Number(value.key),
          );
          dispatch(updateProject(data.data));
        } catch (err: any) {
          openNotificationWithIcon('', err.response.data.message, 'error');
        }
      };

      return (
        <div className="editDelete jcc flex gp">
          <Link to={`${PAGE_URL.PROJECT_DETAIL}/${value.key}`}>View More</Link>
          {!CLINICIAN_PERMISSIONS.includes(roleCode) && (
            <>
              <EditIcon
                className={value.status ? 'cursor-pointer' : 'cursor-disable'}
                onClick={() => {
                  if (value.status) {
                    dispatch(
                      setProjectEditDelete({
                        id: Number(value.key),
                        isEdit: true,
                      }),
                    );
                    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
                  }
                }}
              />
              <Switch size="small" checked={value.status} onChange={onChange} />
              <DeleteIcon onClick={()=>{
                setProjectId(Number(value.key))
                dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION))
              }}/>
            </>
          )}
        </div>
      );
    },
  },
];

export const ProjectsList = ({ atDashboard }: Props) => {
  const dispatch = useDispatch();
  const { roleCode, clientId } = useUser();
  const { projects } = useSelector((state: RootState) => state.data);
  const { setIsAuthorized } = useAuthorization();
  const navigate = useNavigate();

  const [projectId,setProjectId] = useState(-1);
  // const { id: projectId } = useSelector(
  //   (state: RootState) => state.data.projects,
  // );
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed,
  );
  const [loading, setLoading] = useState(false);
  const projectData: DataType[] = projects.list.map((item) => {
    const { Description, Id, Name, OrganizationId, status, createdAt } = item;
    return {
      key: Id,
      name: Name,
      description: Description,
      details: '',
      clientId: OrganizationId,
      status,
      createdOn: getLocalDateTimeStringFromISO(createdAt),
    };
  });

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await projectService.getAll(clientId);
        const { data: projectList } = data;
        dispatch(setProjects(projectList));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon('', err.response.data.message, 'error');
        }
      } finally {
        setLoading(false);
      }
    };
    if (clientId) fetch();
  }, [clientId, dispatch, setIsAuthorized]);

  useEffect(() => {
    const deleteProjectAPI = async () => {
      try {
        await projectService.deleteOne(clientId, projectId);
        dispatch(deleteProject(projectId));
        dispatch(updateDeleteConfirmation(false));
      } catch (err: any) {
        openNotificationWithIcon('', err.response.data.message, 'error');
      }
    };

    if (isDeleteConfirmed && projectId !== -1) deleteProjectAPI();
  }, [projectId, dispatch, isDeleteConfirmed, clientId]);

  return (
    <>
      {!atDashboard && (
        <div className="flex aie jcsb m-t">
          <h3 className="no-padding">Projects</h3>
          {!CLINICIAN_PERMISSIONS.includes(roleCode) && (
            <Button
              className="outline"
              onClick={() => {
                dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
              }}
            >
              Add New Project
            </Button>
          )}
        </div>
      )}
      <div className="client-table project-list basic-detail-content">
        <Table
          dataSource={projectData}
          columns={getColumns(dispatch, roleCode, setProjectId)}
          loading={loading}
          pagination={false}
          onRow={(record) => ({
            onClick: () => navigate(`${PAGE_URL.PROJECT_DETAIL}/${record.key}`),
          })}
          rowClassName={"cursor-pointer"}
        ></Table>
      </div>
    </>
  );
};
