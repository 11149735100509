import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { addUserRole, updateUserRole } from "../../../store";
import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useDispatch } from "react-redux";
import { rolesService } from "../../../api/roles";
import { AddNewRoleProps } from ".";
import { UserRole } from "../../../interfaces";
import { MandatoryFieldsText } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";

interface RoleOption {
  value: string;
  id: string;
  name: string;
  description: string;
}

export const AddEditUserRole = ({
  visibility,
  editObj,
  isEdit,
  roles,
  handleClose,
}: AddNewRoleProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [error, setError] = useState("");

  const options: RoleOption[] = roles
    .reduce((arr: UserRole[], item) => {
      !arr.find((v) => v.RoleCode === item.RoleCode) && arr.push(item);
      return arr;
    }, [])
    .map((role) => ({
      value: role.Name,
      id: role.RoleCode,
      name: role.Name,
      description: role.Description,
    }));

  const [roleOption, setRoleOption] = useState({} as RoleOption);

  useEffect(() => {
    const { VisualName } = editObj;
    form.setFieldsValue({
      name: VisualName,
      role: isEdit ? editObj.Name : "",
    });
  }, [form, editObj, isEdit]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleOptionChange = (value: string, option: RoleOption) => {
    setRoleOption(option);
  };

  const handleAddSubmit = async (e: any) => {
    try {
      const newRole = e.target[0].value;
      const { description, id, name } = roleOption.value
        ? roleOption
        : options[0];
      const obj = {
        visualName: newRole,
        roleCode: id,
        name,
        description,
      };

      const { data } = await rolesService.createRole(obj);
      dispatch(addUserRole(data.data));
      handleClose();
    } catch (err: any) {
      setError(err.response.data.message);
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleEditSubmit = async (e: any) => {
    try {
      const newRole = e.target[0].value;
      const { data } = await rolesService.updateRole(editObj.Id, newRole);
      dispatch(updateUserRole(data.data));
      handleClose();
    } catch (err: any) {
      setError(err.response.data.message);
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    setError("");
    if (isEdit) await handleEditSubmit(e);
    else await handleAddSubmit(e);
    setLoading(false);
    handleClose();
  };

  return (
    <Modal
      className="autonomizeModal new-role"
      centered
      width={580}
      title=""
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{`${isEdit ? "Edit" : "Add"} Role`}</h1>
      </div>
      <div className="addClient-content">
        <Form
          form={form}
          onSubmitCapture={handleSubmit}
          onFieldsChange={handleFormChange}
          colon={false}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Role Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="role" label="Select Autonomize Role">
            <Select
              placeholder="Select Autonomize Role"
              value={roleOption.value}
              options={options}
              disabled={isEdit}
              onSelect={handleOptionChange}
            ></Select>
          </Form.Item>

          {error && <h3 className="error">{error}</h3>}
          <Form.Item className="mandatory-text">
            <MandatoryFieldsText />
            <Button
              className="outline"
              // type="primary"
              htmlType="submit"
              disabled={disableSave}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
