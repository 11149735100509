import { Dispatch } from "redux";
import { connect } from "react-redux";
import AddUserToProject from "./AddUserToProject";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { ProjectUser } from "../../../interfaces";

type StateProps = {
  visibility: boolean;
  clientId: number;
  projectId: number;
  projectUsers: ProjectUser[];
};
type DispatchProps = {
  handleClose: () => void;
};
export type AddUserToProjectProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.ADD_USER_TO_PROJECT],
  clientId: state.user.user.clientId,
  projectId: state.project.detail.detail.Id || -1,
  projectUsers: state.project.detail.detail.users || [],
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.ADD_USER_TO_PROJECT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToProject);
