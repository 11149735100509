import React from "react";
import { PatientDetailCardProps } from "..";
import "../patient-insight-aside.scss"

interface Props {
  patientInfo: {
    mrn: string;
    source: string;
  };
  reviewStatusMap: PatientDetailCardProps[];
}

const PatientDetailCard = (detail: PatientDetailCardProps) => {
  return (
    <div className="m-b">
      <h5 className="patient-aside-info-title">{detail.heading}</h5>
      <span>{detail.value ? detail.value : "-"}</span>
    </div>
  );
};

export const PatientProfileInfo = ({ patientInfo, reviewStatusMap }: Props) => {
  const { mrn, source } = patientInfo;
  const patientIdDetail = { heading: "Subject ID", value: mrn };
  const sourceDetail = {
    heading: "Source (Provider/System)",
    value: source ? source : "System",
  };

  return (
    <div className="m-t">
      <PatientDetailCard {...patientIdDetail} />
      <PatientDetailCard {...sourceDetail} />
      {reviewStatusMap.length > 0 && (
        <div className="flex jcsb aic">
          {reviewStatusMap.map((detail, index) => (
            <PatientDetailCard key={`patient-review-detail-${index}`} {...detail} />
          ))}
        </div>
      )}
    </div>
  );
};
