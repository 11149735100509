import React from "react";
import {
  RecentSearches,
  SavedSearches,
} from "../../search/dashboard-saved-and-recent-search";
import "./top-searches.scss";
import { withAuthorization } from "../../../components/hoc/withAuthorization";

const AuthorizedRecentSearches = withAuthorization(RecentSearches);
const AuthorizedSavedSearches = withAuthorization(SavedSearches);

export const TopSearches = () => {
  return (
    <div className="ant-card topSearch">
      <AuthorizedRecentSearches />
      <AuthorizedSavedSearches />
    </div>
  );
};
