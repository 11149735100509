import React, { useEffect, useRef, useState } from "react";
import { Button, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { QuestionAnsweringService } from "../../../api/questionAnswering";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { IPatient } from "../../../interfaces";
import {
  DrawerIcon,
  MenuIcon,
  ThumbsDown,
  ThumbsUp,
} from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  setContextViewAns,
  setFeedbackType,
  setQuestionAnswerPatientId,
  setQuestionsAnswers,
} from "../../../store";
import { autonomizeLogo } from "../../../assets/images";
import { Loading } from "../../../components";
import { QAHistory } from "./QAHistory";
import "./askQuestion.scss";
import { EVIDENCE_VIEWER_URL } from "./tile-helper-components";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

interface Props {
  patientInfo: IPatient;
}

export const AskAQuestion = ({ patientInfo }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quesAns } = useSelector((state: RootState) => state.quesAns);
  const questionAnswerListRef = useRef<HTMLDivElement>(null);
  const [isQAHistoryCollapsed, setIsQAHistoryCollapsed] = useState(true);

  const handleSearchSubmit = async (e: string) => {
    if (e.trim().length > 0) {
      setIsLoading(true);
      try {
        const { data } = await QuestionAnsweringService.getAnswer({
          patientId: patientInfo.patientId,
          question: e.trim(),
          documentUUID: patientInfo.fileList[0]?.UUID
        });
        dispatch(
          setQuestionsAnswers([
            ...quesAns,
            {
              Answer: {answer: data.data},
              Question: e.trim(),
            },
          ])
        );
        setInputValue("");
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleThumbUp = (item: IQuesAns) => {
    dispatch(setFeedbackType("up"));
    dispatch(
      setQuestionAnswerPatientId({ ...item, PatientId: patientInfo.patientId })
    );
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  const handleThumbDown = (item: IQuesAns) => {
    dispatch(setFeedbackType("down"));
    dispatch(
      setQuestionAnswerPatientId({ ...item, PatientId: patientInfo.patientId })
    );
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  const handleSearchNavigate = (answer: string) => {
    navigate(
      `${EVIDENCE_VIEWER_URL}&search=${encodeURIComponent(answer)}&patient_id=${
        patientInfo.patientId
      }`
    );
  };

  const handleContextClick = (item: IQuesAns) => {
    dispatch(setContextViewAns(item));
    dispatch(openModal(MODAL_TYPE.VIEW_QUES_ANS_CONTEXT));
  };

  useEffect(() => {
    if (questionAnswerListRef.current) {
      questionAnswerListRef.current.scrollTo({
        top: questionAnswerListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [quesAns]);

  return (
    <div className={`ask-question ${isQAHistoryCollapsed ? "collapse" : ""}`}>
      <div className="ask-question-left-conatiner">
        <div
          ref={questionAnswerListRef}
          style={{ maxHeight: "450px", overflowY: "scroll" }}
        >
          {quesAns.map((item, index) => (
            <div className="ask-question-result" key={index}>
              <div className="ask-question-result-ques">
                <MenuIcon className="question-icon" />
                <h4 className="mb-0">{item.Question}</h4>
              </div>
              <div className="ask-question-result-answer">
                {/* <div className="flex aic gp"> */}
                <img
                  className="ask-question-result-answer-img"
                  src={autonomizeLogo}
                />
                <div style={{whiteSpace: "pre-line"}}>
                  <p>{item.Answer.answer}</p>
                </div>
                {/* </div> */}
                {/* <div className="flex gp-10 aic">
                  <a onClick={() => handleContextClick(item)}>Context</a>
                  <a onClick={() => handleSearchNavigate(item.Answer.answer)}>
                    Evidence
                  </a>
                </div> */}
                <div className="flex gp aic">
                  <div
                    onClick={() => handleThumbUp(item)}
                    className="cursor-pointer flex"
                  >
                    <ThumbsUp />
                  </div>
                  <div
                    onClick={() => handleThumbDown(item)}
                    className="cursor-pointer flex"
                  >
                    <ThumbsDown />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="ask-question-search-bar">
          <Search
            className="record-search m-b"
            placeholder="Enter your question"
            enterButton={
              isLoading ? (
                <Loading tip="" />
              ) : (
                <Button icon={<SendOutlined />} />
              )
            }
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onSearch={(e) => handleSearchSubmit(e)}
            disabled={isLoading}
          />
          <p>
            This is an interactive way to ask any question regarding the
            datasets in question.
          </p>
        </div>
      </div>
      {/* <div className="ask-question-right-holder">
        <span
          className="overlap-icon cursor-pointer"
          onClick={() => setIsQAHistoryCollapsed(!isQAHistoryCollapsed)}
          title={`${isQAHistoryCollapsed ? "View" : "Hide"} Chat History`}
        >
          <DrawerIcon
            className={`ask-question-icon  ${
              isQAHistoryCollapsed ? "" : "rotate-icon"
            } `}
          />
        </span>
        {!isQAHistoryCollapsed && (
          <QAHistory
            {...{ patientInfo }}
            setAskAQuestionLoading={setIsLoading}
          />
        )}
      </div> */}
    </div>
  );
};
