import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "antd";
import ChartBlueIcon from "../../../assets/icons/ChartBlueIcon";
import ChartOrangeIcon from "../../../assets/icons/ChartOrangeIcon";
import { DashboardData, DashboardService } from "../../../api";
import { PATIENTS_LIST_TABS } from "../../patients-list";
import "./project-and-patient-tile.scss";
import { useAuthorization, useUser } from "../../../hooks";
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const initialData: DashboardData = {
  patient: {
    count: 0,
    status: {
      reviewed: 0,
      toBeReviewed: 0,
    },
  },
  project: {
    count: 0,
    status: {
      inProgress: 0,
      pending: 0,
    },
  },
  kpiSettings: {
    kpi_patient_stats_card: [""],
    kpi_project_stats_card: [""],
    kpi_time_per_card: [""],
  },
};

export const ProjectAndPatientTile = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const { clientId } = useUser();
  const { setIsAuthorized } = useAuthorization();
  const { projects } = useSelector((state: RootState) => state.data);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const { data: res } = await DashboardService.getData(clientId);
        if(res.data.kpiSettings.kpi_project_stats_card === null){
          setData({ ...initialData});
        }else{
          setData({ ...initialData, ...res.data });
        }
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    if(clientId) fetchDashboardData();
  }, [clientId, setIsAuthorized, projects]);
  return (
    <>
      {data.kpiSettings.kpi_project_stats_card.length > 0 && (
        <Col className="gutter-row" span={12}>
          <Card
            className="ant-card-no-border default-padding"
            loading={loading}
          >
            <Row className="project-card">
              <Col span={8} className="project-card-item">
                <span className="project-card-label">Projects</span>
                <span className="project-card-value">{data.project.count}</span>
              </Col>
              <Col span={16} className="project-card-item asc tr">
                <ChartBlueIcon />
              </Col>
            </Row>
            <Row className="project-card">
              <Col span={12} className="project-card-item">
                <span className="project-card-label">In Progress</span>
                <span className="project-card-value-number">
                  {data.project.status.inProgress}
                </span>
              </Col>
              <Col span={12} className="project-card-item">
                <span className="project-card-label">Pending</span>
                <span className="project-card-value-number">
                  {data.project.status.pending}
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {data.kpiSettings.kpi_patient_stats_card.length > 0 && (
        <Col className="gutter-row" span={12}>
          <Card
            className="ant-card-no-border default-padding"
            loading={loading}
          >
            <Row className="project-card">
              <Col span={8} className="project-card-item">
                <span className="project-card-label">Subjects</span>
                <span className="project-card-value">{data.patient.count}</span>
              </Col>
              <Col span={16} className="project-card-item asc tr">
                <ChartOrangeIcon />
              </Col>
            </Row>
            <Row className="project-card">
              <Col span={12} className="project-card-item">
                <span className="project-card-label">
                  {PATIENTS_LIST_TABS.ASSOCIATED}
                </span>
                <span className="project-card-value-number">
                  {data.patient.status.reviewed}
                </span>
              </Col>
              <Col span={12} className="project-card-item">
                <span className="project-card-label">
                  {PATIENTS_LIST_TABS.UNASSOCIATED}
                </span>
                <span className="project-card-value-number">
                  {data.patient.status.toBeReviewed}
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </>
  );
};
