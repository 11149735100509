import React, { useState } from "react";
import { EditCohortProps } from ".";
import { Button, Form, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { cohortService } from "../../../api/cohort";
import { openNotificationWithIcon } from "../../../utils";
import { useDispatch } from "react-redux";
import { setRetryGetCohort } from "../../../store";

const EditCohort = ({ handleClose, visibility, cohortName, cohortId }: EditCohortProps) => {
  const [cohortNameValue, setCohortNameValue] = useState(cohortName);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await cohortService.updateCohort(Number(cohortId), {
        name: cohortNameValue,
      });
      handleClose();
      dispatch(setRetryGetCohort(true));
      openNotificationWithIcon("", data.response, "success");
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="Delete MOdal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Edit Cohort</h1>
      </div>
      <div className="addClient-content">
        <p className="m-b">Cohort Name </p>
        <Input value={cohortNameValue} onChange={(e) => setCohortNameValue(e.target.value)}></Input>


      <Form.Item className="tr m-t">
        <Button
          className="outline"
          disabled={cohortNameValue === cohortName || cohortNameValue === ""}
          onClick={handleUpdateSubmit}
          loading={isLoading}
        >
          Update
        </Button>
        </Form.Item>
        </div>
    </Modal>
  );
};

export default EditCohort;
