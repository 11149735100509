import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { SearchForm } from "./search-container";
import { withAuthorization } from "../../components/hoc/withAuthorization";

const AuthorizedSearchForm = withAuthorization(SearchForm);

export const SearchLayoutContainer = () => {
  const [callApi, setCallApi] = useState(false);

  return (
    <>
      <AuthorizedSearchForm setCallApi={setCallApi} />
      <Outlet context={[callApi, setCallApi]} />
    </>
  );
};

export { SearchHome, SearchResult } from "./search-container";
