import { useCallback, useState } from "react";
import { cohortService } from "../api/cohort";
import { useAuthorization } from "./useAuthorization";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";

export interface IFetchCohortObj {
  limit: number;
  offset: number;
}

export const useFetchCohorts = () => {
  const [loading, setLoading] = useState(false);
  const [cohorts, setCohorts] = useState([] as ICohort[]);
  const [total, setTotal] = useState(0);
  const { setIsAuthorized } = useAuthorization();
  const fetchCohorts = useCallback(
    async (obj: IFetchCohortObj) => {
      setLoading(true);
      try {
        const { data } = await cohortService.getCohortList(obj.limit, obj.offset);
        setCohorts([...data.data]);
        setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );
  return {
    cohortsLoading: loading,
    fetchCohorts,
    cohorts,
    totalCohorts: total,
  };
};
