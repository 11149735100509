import React from "react";
import {
  EvidencePageResponseInfo,
  SearchOccurrence,
  SearchResultResponse,
} from "../../api";
import {
  EvidencePageHeader,
  EvidenceViewerMultipleFileSearchResult,
  EvidenceViewerSources,
  SearchResultOccurrences,
} from "./evidence-viewer-aside";
import { ISources } from "../../interfaces";

interface Props {
  pageData: EvidencePageResponseInfo;
  currPage: number;
  searchResult: SearchResultResponse;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  searchLoading: boolean;
  fileId: string;

  searchKeywords: string | null;
  setSearchKeywords: React.Dispatch<React.SetStateAction<string | null>>;
  tileType: string | null;
  searchResultOccurrenceLoading: boolean;
  occurencesList: SearchOccurrence[];
  initialSearchKeywords: string | null;
  sources: ISources;
}

export const EvideceViewerAside = (props: Props) => {
  const {
    currPage,
    pageData,
    fileId,
    searchLoading,
    searchResult,
    initialSearchKeywords,
    occurencesList,
    searchKeywords,
    searchResultOccurrenceLoading,
    setCurrPage,
    setSearchKeywords,
    tileType,
    sources,
  } = props;

  return (
    <>
      <EvidencePageHeader pageInfo={pageData} currPage={currPage} sources={sources} />
      <EvidenceViewerMultipleFileSearchResult
        {...{ fileId, searchLoading, searchResult }}
      />
      {((searchKeywords && searchKeywords.length > 0) || tileType) && (
        <SearchResultOccurrences
          {...{
            currPage,
            setCurrPage,
            loading: searchResultOccurrenceLoading,
            searchKeywords,
            setSearchKeywords,
            occurencesList,
            initialSearchKeywords,
          }}
        />
      )}
      {!!Object.keys(sources).length && (
        <EvidenceViewerSources
          {...{
            sources,
            currPage,
            setCurrPage,
            searchKeywords,
            setSearchKeywords,
            initialSearchKeywords,
          }}
        />
      )}
    </>
  );
};
