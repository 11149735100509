import { apiClient } from ".";
import { UserRole } from "../interfaces";

interface UserRoleResponse {
  data: UserRole[];
  message: string;
}

interface UserRoleAddEditResponse {
  data: UserRole;
  message: string;
}

interface CreateRoleRequest {
  name: string;
  description: string;
  roleCode: string;
  visualName: string;
}

const getAll = async (skipSelf = false) => {
  const res = await apiClient.get<UserRoleResponse>(`/role?skip=${skipSelf}`);
  return res;
};

const updateRole = async (id: number, updatedRole: string) => {
  const res = await apiClient.put<UserRoleAddEditResponse>(`/role/${id}`, {
    visualName: updatedRole,
  });
  return res;
};

const createRole = async (reqObj: CreateRoleRequest) => {
  const res = await apiClient.post<UserRoleAddEditResponse>(`/role`, reqObj);
  return res;
};

const deleteRole = async (id: number) => {
  const res = await apiClient.delete<UserRoleAddEditResponse>(`/role/${id}`);
  return res;
};

export const rolesService = {
  getAll,
  updateRole,
  createRole,
  deleteRole,
};
