import { Button, Input, Modal } from "antd";
import React, { useState } from "react";
import { LicenseKeyProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../utils";
import { useDispatch } from "react-redux";
import { setLicenseKey } from "../../../store";
import { ActiveDirectoryService } from "../../../api";

export const LicenseKey = ({ handleClose, visibility }: LicenseKeyProps) => {
  const [error, setError] = useState("");
  const [key, setKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmitClick = async () => {
    try {
      setIsLoading(true);
      const { data } = await ActiveDirectoryService.validateLicenseKey(key);
      if (data.isValid) {
        dispatch(setLicenseKey(data.licenseKey));
        handleClose();
        navigate(PAGE_URL.DASHBOARD);
      }
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1 className="mb-0">License Key</h1>
      </div>
      <div className="addClient-content">
        <div className="license-key">
          <p>Enter License Key : </p>
          <Input value={key} onChange={(e) => setKey(e.target.value)} />
          {/* <p className="error">{error}</p> */}
        </div>
        <div className="tr m-t">
          {/* <a onClick={handleSubmitClick}>Hello</a> */}
          <Button
            className="fill"
            onClick={handleSubmitClick}
            loading={isLoading}
            disabled={!key}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
