import React from "react";
import { IconsProps } from "./Icons";

export const PasswordSuccessIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 96 78"}
      width={width ? width : "96"}
      height={height ? height : "78"}
      fill={fill ? fill : "none"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M93 38V13C93 10.3478 91.9464 7.8043 90.0711 5.92893C88.1957 4.05357 85.6522 3 83 3H13C10.3478 3 7.8043 4.05357 5.92893 5.92893C4.05357 7.8043 3 10.3478 3 13V43C3 45.6522 4.05357 48.1957 5.92893 50.0711C7.8043 51.9464 10.3478 53 13 53H48M60.5 65.5L70.5 75.5L90.5 55.5M48 28.05L48.05 27.995M68 28.05L68.05 27.995M28 28.05L28.05 27.995"
        stroke="#ffffff"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
