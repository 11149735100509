import React from "react";
import {
  BloodPressureIcon,
  BodyMassIndexIcon,
  GenderIcon,
  HeartRateIcon,
  HeightIcon,
  RespiratoryRateIcon,
  TempratureIcon,
  WeightIcon,
} from "../../../../assets/icons";

const data = [
  {
    icon: <BloodPressureIcon />,
    title: "Blood Pressure",
    value: "80 / 120",
  },
  {
    icon: <HeartRateIcon />,
    title: "Heart Rate",
    value: "60-70",
  },
  {
    icon: <RespiratoryRateIcon />,
    title: "Respiratory Rate",
    value: "12 to 16",
  },
  {
    icon: <TempratureIcon />,
    title: "Temperature",
    value: "98.7",
  },
  {
    icon: <HeightIcon />,
    title: "Height",
    value: "5ft. 11in",
  },
  {
    icon: <WeightIcon />,
    title: "Weight",
    value: "120 lbs",
  },
  {
    icon: <BodyMassIndexIcon />,
    title: "Body Mass Index",
    value: "12.3",
  },
];

export const VitalSignsAndPhysicalMeasurements = () => {
  return (
    <>
      <h5 className="insight-card-title m-t-60">VITAL SIGNS & PHYSICAL MEASUREMENTS</h5>
      <div className="vital-sign-conatiner m-t">
        {data.map((vitalSign, index) => (
          <div className="flex gp aic" key={index}>
            <div>{vitalSign.icon}</div>
            <div>
              <p className="vital-sign-title">{vitalSign.title}</p>
              <p>{vitalSign.value}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
