import React, { useState } from "react";
import { Project } from "../../../interfaces";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { useDispatch } from "react-redux";
import { EditIcon,DeleteIcon } from "../../../assets/icons";
import { deleteProject, openModal, setProjectEditDelete, updateProject } from "../../../store";
import { Switch } from "antd";
import { projectService } from "../../../api";
import DeleteConfirmation from "../../modals/delete-confirmation/DeleteConfirmation";
import { useUser } from "../../../hooks";

interface Props {
  project: Project;
}

export const ProjectActions = ({ project }: Props) => {
  const {clientId} = useUser();
  const [confirmModal , setConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const onChange = async (status: boolean) => {
    try {
      const { data } = await projectService.disableEnable(
        project.OrganizationId,
        status,
        Number(project.Id)
      );
      dispatch(updateProject(data.data));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };
  const handleDeleteProject = async () =>{
    try {
      dispatch(deleteProject(project.Id))
      console.log(clientId,project.Id);
      const res = await projectService.deleteOne(clientId,project.Id);
      setConfirmModal(false);
    } catch (error) {
      console.log("error",error);
      throw error;      
    }
  }
  return (
    <div className="editDelete flex gp">
      {/* <Link to={`${PAGE_URL.PROJECT_DETAIL}/${project.Id}`}>View More</Link> */}
      <EditIcon
        className={project.status ? "cursor-pointer" : "cursor-disable"}
        onClick={() => {
          if (project.status) {
            dispatch(setProjectEditDelete({ id: Number(project.Id), isEdit: true }));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
          }
        }}
      />
      <Switch size="small" checked={project.status} onChange={onChange} />
      <DeleteIcon
        onClick={()=>{
          setConfirmModal(true)
        }}
      />
      {confirmModal && <DeleteConfirmation visibility={confirmModal} handleClose={()=>{setConfirmModal(false)}} handleDelete={handleDeleteProject}/>}
    </div>
  );
};
