import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { MailServiceConfiguration } from "../configuration";
import { UsersList } from "../tables";
import { NotAccessibleFallback } from "../../components";

interface Props {
  clientId: number;
  isFetchUsersAuthorized: boolean;
}

export const ClientDetailContainer = ({
  clientId,
  isFetchUsersAuthorized,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <>
      <MailServiceConfiguration clientId={clientId} />
      <div className="clientList m-t">
        <div className="flex jcsb aic clientDetails-title">
          <p className="table-heading">Enterprise Admins</p>
          <Button
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
            }}
          >
            Add Enterprise Admin
          </Button>
        </div>
        {!isFetchUsersAuthorized ? (
          <NotAccessibleFallback />
        ) : (
          <UsersList loading={false} />
        )}
      </div>
    </>
  );
};
