import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import {
  RootState,
  openModal,
  setEditSummaryData,
  updateDeleteConfirmation,
} from "../../../store";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { SummaryTypes } from "../../../interfaces";
import { summaryService } from "../../../api/summarization";

const SummarySection = ({ projectId }: { projectId: number }) => {
  const [summaryList, setSummaryList] = useState([]);
  const dispatch = useDispatch();
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed
  );
  const editSummaryData = useSelector(
    (state: RootState) => state.ui.editSummaryData
  );

  useEffect(() => {
    const listSummaries = async () => {
      try {
        const res = await summaryService.getOneByProjectId(projectId);
        console.log(res.data.data);
        setSummaryList(res.data.data)
      } catch (err: any) {
        openNotificationWithIcon(
          "",
          err.response.data?.message || "Error",
          "error"
        );
      }
    };
    listSummaries();
  }, [editSummaryData, dispatch, isDeleteConfirmed]);


  useEffect(() => {
    const deleteProjectAPI = async () => {
      try {
        await summaryService.deleteOne(editSummaryData.Id);
        dispatch(updateDeleteConfirmation(false));
      } catch (err: any) {
        openNotificationWithIcon(
          "",
          err.response.data?.message || "Error",
          "error"
        );
      }
    };

    if (isDeleteConfirmed && projectId !== -1) deleteProjectAPI();
  }, [projectId, dispatch, isDeleteConfirmed]);

  const handleOnEditClick = useCallback((item: SummaryTypes) => {
    dispatch(setEditSummaryData(item));
    dispatch(openModal(MODAL_TYPE.ADD_EDIT_SUMMARIZATION_TASK));
  }, []);

  const handleOnDeleteClick = useCallback((item: SummaryTypes) => {
    dispatch(setEditSummaryData(item));
    dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
  }, []);

  return (
    <div className="project-specific-config-edit-container">
      {summaryList?.map((item: any, index: any) => (
        <div key={index}>
          <div className="container-title flex gp aic jcsb ">
            <h4 className="mb-0">Title: {item.title}</h4>
            <div className="flex gp">
              <Button
                className="icon-btn"
                icon={<EditIcon />}
                onClick={() => handleOnEditClick(item)}
                // disabled={deleteLoading}
              ></Button>
              <Button
                className="icon-btn"
                icon={<DeleteIcon />}
                onClick={() => handleOnDeleteClick(item)}
              ></Button>
            </div>
          </div>
          <div className="container-content customize-ant-tag">
            <div className="title-group">
              <h3>Question</h3>
              <h4 className="m-0">{item.question}</h4>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SummarySection;
