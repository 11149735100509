import React from "react";
import { IconsProps } from "./Icons";

export const BackArrowIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 24 24"}
      width={width ? width : "24"}
      height={height ? height : "24"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="12" fill="#FAFAFE" />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M14.5456 7.25832C14.8058 7.6832 14.712 8.23502 14.3261 8.5501L10.6988 11.5111C10.2087 11.9112 10.2087 12.6603 10.6988 13.0604L14.3261 16.0214C14.712 16.3365 14.8058 16.8883 14.5456 17.3132C14.2304 17.8277 13.5338 17.9461 13.0664 17.5646L7.54884 13.0604C7.05867 12.6603 7.05867 11.9112 7.54884 11.5111L13.0664 7.00698C13.5338 6.62543 14.2304 6.7438 14.5456 7.25832Z"
        fill="#3647DD"
      />
    </svg>
  );
};
