import { Button, Input } from 'antd';
import React from 'react';
import { Dropdown } from '../../../components';
import { DropdownOption } from '../../../interfaces';
import { useUser } from '../../../hooks';
import { CLINICIAN_PERMISSIONS } from '../../../utils';
import '../../../assets/sass/layout/search.scss';
import './pateint-list-container.scss';

const { Search } = Input;

interface Props {
  onPatientsList?: boolean;
  assignProjectButtonClick?: () => void;
  disableAssignProjectButton?: boolean;
  searchKeyword: string;
  onSearchChange: (str: string) => void;
  patientStatusLoading: boolean;
  patientStatusOptions: DropdownOption[];
  onPatientStatusChange: (value: string, option: DropdownOption) => void;
}

export const PatientListFilter = (props: Props) => {
  const {
    onPatientsList,
    assignProjectButtonClick,
    onSearchChange,
    onPatientStatusChange,
    patientStatusLoading,
    patientStatusOptions,
    disableAssignProjectButton,
  } = props;
  const { roleCode } = useUser();

  return (
    <>
      <div className="flex gp">
        <div className="custom-search pr">
          <Search
            className="record-search"
            style={{ width: '100%' }}
            placeholder="Search for subjects"
            onChange={(e) => !e.target.value && onSearchChange('')}
            onSearch={(e) => {
              onSearchChange(e);
            }}
          />
        </div>
        {onPatientsList ? (
          <div className="ai-select-box">
            <Dropdown
              defaultValue={
                patientStatusOptions.length > 0
                  ? patientStatusOptions[0].value
                  : ''
              }
              options={patientStatusOptions}
              loading={patientStatusLoading}
              onSelect={onPatientStatusChange}
            />
          </div>
        ) : (
          !CLINICIAN_PERMISSIONS.includes(roleCode) && (
            <Button
              type="primary"
              className="outline"
              onClick={assignProjectButtonClick}
              disabled={disableAssignProjectButton}
            >
              Assign to Project
            </Button>
          )
        )}
      </div>
    </>
  );
};
