import React from "react";
import { Collapse } from "antd";
import {
  PreviousSearches,
  SavedSearches,
  SEARCH_HEADING,
} from "./search-aside";
import {
  PaginationRightArrowIcon,
  PaginationLeftArrowIcon,
} from "../../assets/icons";
import "./search-aside.scss";
import { withAuthorization } from "../../components/hoc/withAuthorization";

const { Panel } = Collapse;

const AuthorizedPreviousSearches = withAuthorization(PreviousSearches);
const AuthorizedSavedSearches = withAuthorization(SavedSearches);
export const SearchAside = () => {
  return (
    <div className="searches flex flex-col customize-accordion">
      <Collapse
        accordion
        defaultActiveKey={[SEARCH_HEADING.SAVED]}
        expandIconPosition="end"
        expandIcon={(e) =>
          e.isActive ? (
            <PaginationLeftArrowIcon />
          ) : (
            <PaginationRightArrowIcon />
          )
        }
      >
        <Panel header={SEARCH_HEADING.PREVIOUS} key={SEARCH_HEADING.PREVIOUS}>
          <AuthorizedPreviousSearches />
        </Panel>
        <Panel header={SEARCH_HEADING.SAVED} key={SEARCH_HEADING.SAVED}>
          <AuthorizedSavedSearches />
        </Panel>
      </Collapse>
    </div>
  );
};
