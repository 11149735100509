import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { MODAL_TYPE } from "../../utils";
import { openModal } from "../../store";
import "./roleMapping.scss";
import { RoleMappingList } from "./role-mapping-container/RoleMappingList";
import { withAuthorization } from "../../components/hoc/withAuthorization";

const AuthorizedRoleMappingList = withAuthorization(RoleMappingList);

export const RoleMappingContainer = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="flex jcsb aic">
        <p className="table-heading">Roles</p>
        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER_ROLE));
          }}
        >
          Add New Role
        </Button>
      </div>
      <AuthorizedRoleMappingList />
    </>
  );
};
