import { Modal } from 'antd';
import React from 'react';
import { VeiwQuesAnsContextProps } from '.';
import { CloseOutlined } from '@ant-design/icons';

export const QuesAnsContext = ({
  handleClose,
  quesAnsContext,
  visibility,
}: VeiwQuesAnsContextProps) => {
  const highlightContexts = (
    content: string,
    contextOffsets: { end: number; start: number }[],
  ) => {
    const contexts = contextOffsets.map((offset) =>
      content.substring(offset.start, offset.end),
    );
    contexts.forEach((context) => {
      const html = `<span style='border:3px solid #000;margin:5px;border-radius:6px; padding:4px 6px;'>${context}</span>`;
      const expression = context.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
      content = content.replaceAll(new RegExp(expression, 'gi'), html);
    });
    return content;
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <div className="flex gp-10 aic">
          <h4 className="mb-0">Answer Context</h4>
        </div>
      </div>
      <div className="addClient-content no-pd-tp">
        <div
          className="e-paragraph"
          style={{ lineHeight: 2.5 }}
          // dangerouslySetInnerHTML={{
          //   __html: highlightContexts(quesAnsContext.Answer.context, quesAnsContext.Answer.offsets_in_context),
          // }}
        />
      </div>
    </Modal>
  );
};

