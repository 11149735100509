import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { cohortService } from "../../api/cohort";
import { AppPagination } from "../../components";
import { IFetchCohortPatientObj, useFetchCohortPatients, usePagination } from "../../hooks";
import {
  openModal,
  RootState,
  setCohortPatient,
  setRetryGetCohortPatient,
  updateDeleteConfirmation,
} from "../../store";
import {
  getLocalDateTimeStringFromISO,
  getOffsetFromPageAndPageSize,
  MODAL_TYPE,
  openNotificationWithIcon,
  PAGE_URL,
} from "../../utils";

interface ICohortPatientsDataType {
  key: React.Key;
  patientId: string;
  patientName: string;
  gender: string;
  createdAt: string;
  removePatient: JSX.Element;
}

const getColumns = (): ColumnsType<ICohortPatientsDataType> => [
  {
    title: "Subject ID",
    dataIndex: "patientId",
  },
  {
    title: "Subject Name",
    dataIndex: "patientName",
  },
  {
    title: "Gender",
    dataIndex: "gender",
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
  },
  {
    title: "Action",
    dataIndex: "removePatient",
  },
];

export const CohortPatientContainer = () => {
  const { cohortId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const {
    fetchCohortPatients,
    cohortPatientsLoading,
    cohortPatients,
    totalCohortPatients,
    setCohortPatientLoading,
  } = useFetchCohortPatients();
  const { retryGetCohortPatient, cohortPatientToBeDeleted } = useSelector(
    (state: RootState) => state.cohort
  );
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);

  const cohortPatientData: ICohortPatientsDataType[] = cohortPatients.map((cohortPatient) => ({
    key: cohortPatient.PatientId,
    patientId: cohortPatient.Patient.Mrn,
    patientName: cohortPatient.Patient.Name,
    gender: cohortPatient.Patient.Gender,
    createdAt: getLocalDateTimeStringFromISO(cohortPatient.createdAt),
    removePatient: (
      <a
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setCohortPatient(cohortPatient));
          dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
        }}
      >
        Remove Subject
      </a>
    ),
  }));

  const handleRowClick = (listItem: ICohortPatientsDataType) => {
    navigate(`${PAGE_URL.PATIENT_PROFILE}/${listItem.key}`);
  };

  const getCohortPatients = useCallback(async () => {
    const obj: IFetchCohortPatientObj = {
      offset: getOffsetFromPageAndPageSize(page, pageSize),
      limit: pageSize,
      cohortId: cohortId as string,
    };
    await fetchCohortPatients(obj);
  }, [fetchCohortPatients, page, pageSize, cohortId]);

  useEffect(() => {
    getCohortPatients();
  }, [getCohortPatients]);

  useEffect(() => {
    if (retryGetCohortPatient) {
      getCohortPatients();
    }
    dispatch(setRetryGetCohortPatient(false));
  }, [retryGetCohortPatient, getCohortPatients, dispatch]);

  useEffect(() => {
    const removePatient = async () => {
      setCohortPatientLoading(true);
      try {
        const { data } = await cohortService.deleteCohortPatient(
          Number(cohortId as string),
          cohortPatientToBeDeleted.PatientId
        );
        dispatch(setRetryGetCohortPatient(true));
        dispatch(updateDeleteConfirmation(false));
        openNotificationWithIcon("", data.response, "success");
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setCohortPatientLoading(false);
      }
    };
    if (isDeleteConfirmed) removePatient();
  }, [cohortId, cohortPatientToBeDeleted, dispatch, isDeleteConfirmed, setCohortPatientLoading]);

  return (
    <>
      <div className="flex aic jcsb">
        <h3 className="pb-0">Cohort Subjects</h3>
        <AppPagination
          {...{
            showSizeChanger: true,
            current: page,
            total: totalCohortPatients,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div>
      <Table
        className="basic-detail-content m-t last-column-center"
        rowClassName="cursor-pointer"
        columns={getColumns()}
        dataSource={cohortPatientData}
        loading={cohortPatientsLoading}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record),
          };
        }}
      />
    </>
  );
};
