import { Table } from "antd";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  deleteUserRole,
  openModal,
  setUserRoleEdit,
  setUserRoles,
  updateDeleteConfirmation,
} from "../../../store";
import { AnyAction } from "redux";
import { ColumnsType } from "antd/es/table";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import {
  MODAL_TYPE,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import { rolesService } from "../../../api";
import { useAuthorization } from "../../../hooks";
import { Loading } from "../../../components";

interface DataType {
  key: React.Key;
  role: string;
  autonomizeRole: string;
  action: string;
  isDefault: boolean;
}

export const getColumns = (
  dispatch: Dispatch<AnyAction>
): ColumnsType<DataType> => [
  {
    title: "New Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Autonomize Role",
    dataIndex: "autonomizeRole",
    key: "autonomizeRole",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (value, record) => (
      <div className="editDelete jcc flex gp">
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            dispatch(setUserRoleEdit(Number(record.key)));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER_ROLE));
          }}
        />
        <DeleteIcon
          className={record.isDefault ? "cursor-disable" : "cursor-pointer"}
          onClick={() => {
            if (!record.isDefault) {
              dispatch(setUserRoleEdit(Number(record.key)));
              dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
            }
          }}
        />
      </div>
    ),
  },
];

export const RoleMappingList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userRoles = useSelector((state: RootState) => state.user.roles);
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed
  );
  const { setIsAuthorized } = useAuthorization();
  const userRolesData: DataType[] =
    userRoles && userRoles.list
      ? userRoles.list.map((item) => {
          const { Id, VisualName, Name, IsDefault } = item;
          return {
            key: Id,
            autonomizeRole: Name,
            role: VisualName,
            action: "",
            isDefault: IsDefault,
          };
        })
      : [];
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setLoading(true);
        const { data } = await rolesService.getAll();
        dispatch(setUserRoles(data.data));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
    return () => {
      dispatch(setUserRoles([]));
    };
  }, [dispatch, setIsAuthorized]);

  useEffect(() => {
    const deleteRole = async () => {
      setLoading(true);
      try {
        await rolesService.deleteRole(userRoles.id);
        dispatch(deleteUserRole(userRoles.id));
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
      } finally {
        dispatch(updateDeleteConfirmation(false));
        setLoading(false);
      }
    };
    if (isDeleteConfirmed && userRoles.id !== -1) deleteRole();
  }, [userRoles.id, dispatch, isDeleteConfirmed]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Table
          className="user-table"
          dataSource={userRolesData}
          columns={getColumns(dispatch)}
          loading={loading}
          pagination={false}
        ></Table>
      )}
    </>
  );
};
