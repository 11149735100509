import React from "react";
import { IconsProps } from "./Icons";

export const TempratureIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 49 48"}
      width={width ? width : "49"}
      height={height ? height : "48"}
      fill={fill ? fill : "none"}
    >
        <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="0.381836" width="48" height="48" rx="6" fill="#FFE587"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M37.3816 15.4286V13.7143H30.5245V25.7143H32.2387V20.5714H36.5245V18.8571H32.2387V15.4286H37.3816Z" fill="#D97136"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M27.0959 15.4286C28.0427 15.4286 28.8102 14.6611 28.8102 13.7143C28.8102 12.7675 28.0427 12 27.0959 12C26.1491 12 25.3816 12.7675 25.3816 13.7143C25.3816 14.6611 26.1491 15.4286 27.0959 15.4286Z" fill="#D97136"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M20.2387 27.5863V20.5714H18.5245V27.5863C17.9526 27.7885 17.4706 28.1863 17.1636 28.7096C16.8567 29.2328 16.7446 29.8476 16.8472 30.4455C16.9498 31.0433 17.2604 31.5857 17.7242 31.9767C18.188 32.3676 18.775 32.5821 19.3816 32.5821C19.9882 32.5821 20.5753 32.3676 21.039 31.9767C21.5028 31.5857 21.8134 31.0433 21.916 30.4455C22.0186 29.8476 21.9065 29.2328 21.5996 28.7096C21.2927 28.1863 20.8106 27.7885 20.2387 27.5863Z" fill="#D97136"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M19.3816 36C18.2004 36.0013 17.0452 35.6536 16.061 35.0006C15.0768 34.3476 14.3074 33.4183 13.8495 32.3295C13.3917 31.2407 13.2657 30.0409 13.4874 28.8807C13.7092 27.7206 14.2688 26.6518 15.0959 25.8086V16.2857C15.0959 15.1491 15.5474 14.059 16.3511 13.2553C17.1549 12.4515 18.245 12 19.3816 12C20.5182 12 21.6083 12.4515 22.4121 13.2553C23.2158 14.059 23.6673 15.1491 23.6673 16.2857V25.8086C24.4945 26.6518 25.054 27.7206 25.2758 28.8807C25.4975 30.0409 25.3715 31.2407 24.9137 32.3295C24.4558 33.4183 23.6865 34.3476 22.7022 35.0006C21.718 35.6536 20.5628 36.0013 19.3816 36ZM19.3816 13.7143C18.6998 13.715 18.0462 13.9861 17.5641 14.4682C17.082 14.9503 16.8109 15.6039 16.8102 16.2857V26.5569L16.5256 26.8131C15.8784 27.3916 15.422 28.1531 15.2171 28.9966C15.0121 29.8402 15.0681 30.7261 15.3777 31.5371C15.6873 32.3481 16.2359 33.0459 16.9509 33.5383C17.6659 34.0306 18.5135 34.2943 19.3816 34.2943C20.2497 34.2943 21.0973 34.0306 21.8123 33.5383C22.5273 33.0459 23.0759 32.3481 23.3855 31.5371C23.6951 30.7261 23.7511 29.8402 23.5461 28.9966C23.3412 28.1531 22.8849 27.3916 22.2376 26.8131L21.953 26.5569V16.2857C21.9524 15.6039 21.6812 14.9503 21.1991 14.4682C20.717 13.9861 20.0634 13.715 19.3816 13.7143Z" fill="#D97136"/>

    </svg>
  );
};
