import { WarningFilled } from "@ant-design/icons";
import React from "react";
import "./fall-back.scss";

export const NotAccessibleFallback = () => {
  return (
    <div className="tc">
      <WarningFilled className="warning-icon m-b" />
      <h2 className="m-b">We are Sorry</h2>
      <p>The page you are trying to access have restricted access.</p>
      <p>Please refer to your system administrator</p>
    </div>
  );
};
