import React, { Suspense } from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";
import { FallbackLoading } from "../components";
import { RedirectLayout } from "./RedirectLayout";

export const AppRoutes = () => {
  const location = useLocation();
  const displayAnnotation = process.env.REACT_APP_DISPLAY_ANNOTATION === "true";

  return (
    <Routes location={location}>
      <Route element={<PrivateLayout />}>
        <Route
          path={PAGE_URL.DASHBOARD}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.Dashboard />
            </Suspense>
          }
        />
        <Route
          path={PAGE_URL.PATIENTS_LIST}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.PatientsList />
            </Suspense>
          }
        />
        <Route
          path={`${PAGE_URL.PATIENT_PROFILE}/:patientId`}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.PatientInsights />
            </Suspense>
          }
        />
        <Route path={PAGE_URL.SEARCH.LAYOUT} element={<Pages.Search.Layout />}>
          <Route path={PAGE_URL.SEARCH.HOME} element={<Pages.Search.Home />} />
          <Route
            path={PAGE_URL.SEARCH.RESULT}
            element={<Pages.Search.Result />}
          />
        </Route>
        <Route
          path={PAGE_URL.EVIDENCE_VIEWER}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.EvidenceViewer />
            </Suspense>
          }
        />
        <Route
          path={`${PAGE_URL.CLIENT_DETAIL}/:clientId`}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.ClientDetail />
            </Suspense>
          }
        />
        <Route
          path={PAGE_URL.SETTINGS}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.SettingsPage />
            </Suspense>
          }
        />
        <Route
          path={`${PAGE_URL.PROJECT_DETAIL}/:projectId`}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.ProjectDetail />
            </Suspense>
          }
        />
        <Route
          path={`${PAGE_URL.ROLE_MAPPING}`}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.RoleMapping />
            </Suspense>
          }
        />
        <Route
          path={`${PAGE_URL.CONFIGURATIONS}`}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.EnterpsieAdminConfigurations />
            </Suspense>
          }
        />
        <Route
          path={`${PAGE_URL.COHORT}/:cohortId`}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.CohortPatients />
            </Suspense>
          }
        />
        {displayAnnotation && (
          <Route
            path={PAGE_URL.ANNOTATION}
            element={
              <Suspense fallback={<FallbackLoading />}>
                <Pages.AnnotationProjectDetail />
              </Suspense>
            }
          />
        )}
        {displayAnnotation && (
          <Route
            path={`${PAGE_URL.ANNOTATE_FILE}/:fileName`}
            element={
              <Suspense fallback={<FallbackLoading />}>
                <Pages.AnnotateFile />
              </Suspense>
            }
          />
        )}
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
        <Route
          path={PAGE_URL.RESET_PASSWORD}
          element={<Pages.ResetPassword.Form />}
        />
        <Route
          path={PAGE_URL.RESET_PASSWORD_SUCCESS}
          element={<Pages.ResetPassword.Success />}
        />
        <Route
          path={PAGE_URL.CREATE_PASSWORD}
          element={<Pages.CreatePassword.Form />}
        />
        <Route
          path={PAGE_URL.CREATE_PASSWORD_SUCCESS}
          element={<Pages.CreatePassword.Success />}
        />
      </Route>
      <Route element={<RedirectLayout />}>
        <Route
          path={PAGE_URL.REDIRECTION}
          element={
            <Suspense fallback={<FallbackLoading />}>
              <Pages.Redirect />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
