import { Dispatch } from 'redux';
import { RootState, closeModal } from '../../../store';
import { MODAL_TYPE } from '../../../utils';
import { connect } from 'react-redux';
import { QuesAnsContext } from './QuesAnsContext';

type StateProps = {
  visibility: boolean;
  quesAnsContext: IQuesAns;
};
type DispatchProps = {
  handleClose: () => void;
};

export type VeiwQuesAnsContextProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.VIEW_QUES_ANS_CONTEXT],
  quesAnsContext: state.quesAns.ansContextView,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.VIEW_QUES_ANS_CONTEXT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuesAnsContext);

