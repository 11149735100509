import { Card, Row, Col } from "antd";
import React from "react";
import ChartGreenIcon from "../../../assets/icons/ChartGreenIcon";

export const TimePerChartTile = () => {
  return (
    <Card className="ant-card-no-border default-padding">
      <Row className="project-card">
        <Col span={14} className="project-card-item">
          <span className="project-card-label">Time per Chart</span>
          <span className="project-card-value">10 min</span>
        </Col>
        <Col span={10} className="project-card-item asc tr">
          <ChartGreenIcon />
        </Col>
      </Row>
      <Row className="project-card">
        <Col span={14} className="project-card-item">
          <span className="project-card-label">Average Pages</span>
          <span className="project-card-value-number">684</span>
        </Col>
        <Col span={10} className="project-card-item">
          <span className="project-card-label">Avg</span>
          <span className="project-card-value-number">15</span>
        </Col>
      </Row>
    </Card>
  );
};
