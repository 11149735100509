export enum MAIL_SERVICE_CONFIGURATION_KEYS {
  mailService = 'MAIL_SERVICE',
  sendGridApiKey = 'SENDGRID_API_KEY',
  senderEmail = 'SENDER_EMAIL',
  smtpHost = 'SMTP_HOST',
  smtpPort = 'SMTP_PORT',
  smtpSecure = 'SMTP_SECURE',
  smtpUser = 'SMTP_USER',
  smtpPassword = 'SMTP_PASS',
  adminEmail = 'ADMIN_EMAIL',
  startTls = 'START_TLS',
}

export enum AWS_SERVICE_CONFIGURATION_KEYS {
  awsS3Bucket = 'AWS_S3_BUCKET',
  awsDynamoDBTable = 'AWS_DYNAMO_DB_TABLE',
}

