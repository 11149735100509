import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFeedbackSlice extends IQuesAnsWithPatientId{
  feedbackType: 1 | -1 | 0;
}

export const feedbackInitialState: IFeedbackSlice = {
  Question: "",
  Answer: {} as IAnswer,
  PatientId: -1,
  feedbackType: 0,
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: feedbackInitialState,
  reducers: {
    setQuestionAnswerPatientId: (
      state: IFeedbackSlice,
      { payload }: PayloadAction<IQuesAnsWithPatientId>
    ) => {
      const { Answer, PatientId, Question } = payload;
      state.Question = Question;
      state.Answer = Answer;
      state.PatientId = PatientId;
    },
    setFeedbackType: (state: IFeedbackSlice, { payload }: PayloadAction<string>) => {
      if (payload === "up") {
        state.feedbackType = 1;
      } else if (payload === "down") {
        state.feedbackType = -1;
      }
    },
  },
});

export const { setFeedbackType, setQuestionAnswerPatientId } = feedbackSlice.actions;
export default feedbackSlice.reducer;
