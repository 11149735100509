import React from "react";
import { EvidencePageResponseInfo } from "../../../api";
import ReactJson from "react-json-view";

interface Props {
  pageInfo: EvidencePageResponseInfo;
}

export const EvidenceContentJSON = ({ pageInfo }: Props) => {
  return (
    <div className="e-text">
      <h2>{pageInfo.heading}</h2>
      <div className="e-paragraph">
        <ReactJson src={JSON.parse(JSON.stringify(pageInfo))} enableClipboard />
      </div>
    </div>
  );
};
