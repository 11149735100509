import { apiClient } from ".";

interface ClientResponse {
  Attributes: string;
  CreatedBy: number;
  Description: string;
  Id: 5;
  IsActive: true;
  Name: string;
  createdAt: string;
  updatedAt: string;
}

interface GetAllClientResponse {
  data: ClientResponse[];
  message: string;
}

interface AddEditClientResponse {
  data: ClientResponse;
  message: string;
}

export interface AddEditClientRequest {
  name: string;
  description: string;
}

const getAll = async () => {
  const resp = await apiClient.get<GetAllClientResponse>("/client");
  return resp;
};

const getOne = async (id: string) => {
  const res = await apiClient.get<AddEditClientResponse>(`/client/${id}`);
  return res;
};

const editOne = async (id: number, reqObj: AddEditClientRequest) => {
  const res = await apiClient.put<AddEditClientResponse>(
    `/client/${id}`,
    reqObj
  );
  return res;
};

const addOne = async (reqObj: AddEditClientRequest) => {
  const res = await apiClient.post<AddEditClientResponse>(
    `/client`,
    reqObj
  );
  return res;
};

const deleteOne = async (id: number) => {
  const res = await apiClient.delete(`/client/${id}`);
  return res;
};

export const clientService = {
  getAll,
  getOne,
  editOne,
  deleteOne,
  addOne,
};
