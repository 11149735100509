import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./ProjectSpecificInsights.scss";
import {
  CancerConditionTile,
  NSCLCDiagnosisTile,
  PdStatusTile,
  PembrolizumabTherapyTile,
  GeneMutationTile,
  CancerStagingTile,
} from "./project-specific-tiles";
import {
  BloodBasedBiomarkerCard,
  ConditionsCard,
  RiskFactorCard,
} from "./patient-insight-tiles";
import {
  Biomarker,
  Condition,
  ProjectSpecific,
  ProjectSpecificTiles,
} from "../../../interfaces";
import { BaseEntity } from "../../../api";
import { getThresholdConfidenceScore } from "../../../utils";
import ProjectSpecificConditionCard from "./patient-insight-tiles/ProjectSpecificConditionCard";
import { summaryService } from "../../../api/summarization";
interface ClinicalDocument {
  [key: string]: string;
}
interface Props {
  projectSpecific: ProjectSpecific[];
  patientId: number;
  threshold_confidence_score: string[];
  biomarkers: Biomarker[];
  riskFactors: BaseEntity[];
  projectSpecificSummary?: any;
}

export const ProjectSpecificInsightTiles = ({
  projectSpecific,
  patientId,
  threshold_confidence_score,
  biomarkers,
  riskFactors,
  projectSpecificSummary,
}: Props) => {
  console.log(projectSpecificSummary);
  // const {
  //   cancer_staging,
  //   gene_mutations,
  //   nslc_cancer,
  //   pdl1_status,
  //   pembrolizumab_therapy,
  //   cancer_expression,
  // } = projectSpecific;
  const threshold = getThresholdConfidenceScore(threshold_confidence_score);

  const [question, setQuestion] = useState() as any;

  useEffect(() => {
    const getPrompts = async () => {
      const res = await summaryService.getOneByProjectId(patientId);
    };
    getPrompts();
  }, []);

  return (
    <>
      {/* <h4 className="tile-heading">Project Specific Insights</h4> */}
      <div className="project-specific  m-t">
        <Row gutter={[40, 40]}>
          {projectSpecific && (
            <ProjectSpecificConditionCard project_specifics={projectSpecific} />
          )}
          {/* <Col className="gutter-row" span={12}>
            {nslc_cancer && (
              <NSCLCDiagnosisTile
                nsclcCancer={nslc_cancer.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={nslc_cancer.confidence_score_threshold}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {riskFactors && (
              <RiskFactorCard
                riskFactors={riskFactors}
                patientId={patientId}
                threshold={threshold}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {cancer_expression && (
              <CancerStagingTile
                cancerStaging={cancer_expression.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={cancer_expression.confidence_score_threshold}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {cancer_staging && (
              <CancerConditionTile
                cancerConditions={cancer_staging.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={cancer_staging.confidence_score_threshold}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {pdl1_status && (
              <PdStatusTile
                pdl1Status={pdl1_status.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={pdl1_status.confidence_score_threshold}
              />
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {gene_mutations && (
              <GeneMutationTile
                geneMutations={gene_mutations.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={gene_mutations.confidence_score_threshold}
              />
            )}
          </Col> */}
          {projectSpecificSummary &&
          projectSpecificSummary?.length &&
          Array.isArray(projectSpecificSummary) ? (
            <>
              <div>
                <h4
                  className="insight-card-title"
                  style={{ padding: "0px 20px" }}
                >
                  PROJECT SPECIFIC SUMMARY
                </h4>
                {projectSpecificSummary?.map((item: any, index: number) => (
                  <div key={index} style={{ padding: "20px" }}>
                    <h4>{item.SummarizationTask.title}</h4>
                    <div style={{ whiteSpace: "pre-line" }}>{item.summary}</div>
                  </div>
                ))}
              </div>
              {/* <Col className="gutter-row" span={12}>
                <h4 className="insight-card-title">DEMOGRAPHIC INFORMATION</h4>
                <div>{projectSummary?.["Demographic information"]}</div>
              </Col>
              <Col className="gutter-row" span={12}>
                <h4 className="insight-card-title">
                  CURRENT MEDICAL CONDITIONS
                </h4>
                <div>{projectSummary?.["Current medical conditions"]}</div>
              </Col>
              <Col className="gutter-row" span={12}>
                <h4 className="insight-card-title">
                  RECENT MEDICAL PROCEDURES
                </h4>
                <div>{projectSummary?.["Recent medical procedures"]}</div>
              </Col> */}
            </>
          ) : (
            <Col className="gutter-row" span={12}>
              <h4 className="insight-card-title">PROJECT SPECIFIC SUMMARY</h4>
              <div>{"No Summary found for the provided documentUuid"}</div>
            </Col>
          )}

          {/* <Col className="gutter-row" span={12}>
            {pembrolizumab_therapy && (
              <PembrolizumabTherapyTile
                pembrolizumabTherapy={pembrolizumab_therapy.data}
                patientId={patientId}
                threshold={threshold}
                // threshold={pdl1_status.confidence_score_threshold}
              />
            )}
          </Col> */}

          {/* <Col className="gutter-row" span={9}>
          {biomarkers && (
            <BloodBasedBiomarkerCard
              data={biomarkers}
              patientId={patientId}
              threshold={threshold}
            />
          )}
        </Col> */}
        </Row>
      </div>
    </>
  );
};
