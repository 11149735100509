import { Spin } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EvidencePageResponseInfo } from "../../../api";
import { PAGE_URL, isEmptyObject } from "../../../utils";
import "./evidence-viewer-aside.scss";
import { ISources } from "../../../interfaces";

interface EvidencePageHeaderProps {
  currPage: number;
  pageInfo: EvidencePageResponseInfo;
  sources: ISources;
}

export const EvidencePageHeader = ({
  pageInfo,
  currPage,
  sources,
}: EvidencePageHeaderProps) => {
  const navigate = useNavigate();
  console.log(sources);

  return (
    <>
      <div className="evidence-viewer">
        {isEmptyObject(pageInfo) ? (
          <Spin tip="Loading..." />
        ) : (
          <>
            <div className="e-content-row">
              <div
                className="e-content-row-first"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `${PAGE_URL.PATIENT_PROFILE}/${pageInfo.patient.patientId}`
                  )
                }
              >
                <p>{pageInfo.patient.name}</p>
              </div>
              <div className="e-content-row-second">
                <div className="flex no-wrap">
                  <h5>Subject ID</h5>
                  <span>{pageInfo.patient.mrn}</span>
                </div>
              </div>
              <div className="e-content-row-third">
                {!!Object.keys(sources).length && (
                  <>
                    <div>
                      <h5>Question</h5>
                      <div>{sources.DisplayQuery}</div>
                    </div>
                    <div className="flex no-wrap">
                      <h5>Response:</h5>
                      <span>{sources.Response}</span>
                    </div>
                  </>
                )}
                <div>
                  <h5>Page No.</h5>
                  <span>{currPage}</span>
                </div>
                <div className="flex no-wrap">
                  <h5>File Name</h5>
                  <span>{pageInfo.patient.fileName}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
