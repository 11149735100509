import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Annotation } from "../../interfaces";

interface IAnnotation {
  [page: number]: Annotation[];
}

interface AnnotationSlice {
  annotations: IAnnotation;
  annotationFileIdToDelete: number;
}
export const annotationInitialState: AnnotationSlice = {
  annotations: {} as IAnnotation,
  annotationFileIdToDelete: -1,
};

export const annotationSlice = createSlice({
  name: "annotation",
  initialState: annotationInitialState,
  reducers: {
    updateAnnotations: (
      state: AnnotationSlice,
      { payload }: PayloadAction<{ pageAnnotations: Annotation[]; page: number }>
    ) => {
      const { pageAnnotations, page } = payload;
      state.annotations[page] = pageAnnotations;
    },
    clearAnnotations: (state: AnnotationSlice) => {
      state.annotations = {};
    },
    updateAnnotationFileIdToDelete: (
      state: AnnotationSlice,
      { payload }: PayloadAction<number>
    ) => {
      state.annotationFileIdToDelete = payload;
    },
  },
});

export const { updateAnnotations, clearAnnotations, updateAnnotationFileIdToDelete } =
  annotationSlice.actions;

export default annotationSlice.reducer;
