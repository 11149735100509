import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { evidenceViewerService } from "../../../api";
import { ThumbsUp, ThumbsDown } from "../../../assets/icons";
import { openNotificationWithIcon } from "../../../utils";

interface Props {
  pageNo: number;
  fileUUID: string;
}

export const FeedbackForm = ({ pageNo, fileUUID }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    setError("");
    const feedback = e.target[0].value;
    try {
      const res = await evidenceViewerService.submitFeedback(
        pageNo,
        fileUUID,
        feedback
      );
      form.setFieldsValue({ feedback: "" });
      setMessage(
        "Thank you for your feedback, your feedback was submitted successfully"
      );
    } catch (err: any) {
      setError(err.response.data.message || err.response.message);
      openNotificationWithIcon("", err.repsonse.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (message !== "") {
      setTimeout(() => setMessage(""), 4000);
    }
  }, [message]);

  return (
    <div className="e-feedback">
      <Form
        form={form}
        onSubmitCapture={handleSubmit}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          name="feedback"
          label="Please add your feedback (Active Learning in Progress)"
          rules={[{ required: true, message: "" }]}
        >
          <TextArea
            rows={8}
            placeholder="Errors, omissions, feedback, annotations etc.."
          />
        </Form.Item>
        {error && <h3 className="error">{error}</h3>}
        {message && <h3>{message}</h3>}
        <div className="submitIcon flex jcsb ais">
          {/* <div className="flex">
            <ThumbsUp />
            <ThumbsDown />
          </div> */}
          <p>{/* <i>max character limit is 250</i> */}</p>
          <Button
            className="outline"
            // type="primary"
            htmlType="submit"
            disabled={disableSave}
            loading={loading}
          >
            Submit Feedback
          </Button>
        </div>
      </Form>
    </div>
  );
};
