import React from "react";

interface Props {
  text: string;
  value: string;
  // className : string;
}

export const PatientInsightTag = ({ text, value }: Props) => {
  return (
    <div className="flex aic  cursor-pointer">
      <p className="data-title-text-tag">{text}</p>
      <h5 className="data-title-score-tag mb-0">{value}</h5>
    </div>
  );
};
