import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  closeModal,
  removeProjectConfiguration,
  RootState,
  setEditSummaryData,
} from "../../../store";
import { isEmptyObject, MODAL_TYPE } from "../../../utils";
import { FormEventHandler } from "react";
import AddEditSummarizationTask from "./AddEditSummarizationTask";
import { SummarizationTask, SummaryTypes } from "../../../interfaces";

type StateProps = {
  visibility: boolean;
  projectId: number;
  editObj: SummarizationTask;
  isEdit: boolean;
};
type DispatchProps = {
  handleClose: () => void;
};
export type AddSummarizationTaskProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const currentTask = state.project.summaryTasks.currentTask;
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_SUMMARIZATION_TASK],
    projectId: state.project.detail.detail.Id || -1,
    isEdit: !isEmptyObject(currentTask),
    editObj: currentTask
  }
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_SUMMARIZATION_TASK));
    dispatch(setEditSummaryData({} as SummaryTypes));
  }
});

export default connect(mapStateToProps,mapDispatchToProps)(AddEditSummarizationTask);
