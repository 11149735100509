import React from "react";
import { IconsProps } from "./Icons";

export const UserSwitchIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 14 16"}
      width={width ? width : "14"}
      height={height ? height : "16"}
      fill={fill ? fill : "none"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M11.3418 4.88867C11.3418 2.48047 9.39066 0.529297 6.98246 0.529297C4.57425 0.529297 2.62308 2.48047 2.62308 4.88867C2.62308 6.34414 3.33675 7.63438 4.43363 8.42539C4.4266 8.42891 4.42132 8.43066 4.41781 8.43242C3.63207 8.76465 2.92718 9.24102 2.32074 9.84922C1.71429 10.4557 1.23968 11.1623 0.907458 11.9498C0.582262 12.7197 0.408239 13.5371 0.390661 14.3773C0.388903 14.4564 0.452184 14.5215 0.531286 14.5215H1.58422C1.6598 14.5215 1.72308 14.46 1.72484 14.3844C1.76 13.0273 2.30316 11.7564 3.26468 10.7932C4.25785 9.79648 5.57621 9.24805 6.98246 9.24805C9.39066 9.24805 11.3418 7.29688 11.3418 4.88867ZM6.98246 7.91211C5.31254 7.91211 3.95902 6.55859 3.95902 4.88867C3.95902 3.21875 5.31254 1.86523 6.98246 1.86523C8.65238 1.86523 10.0059 3.21875 10.0059 4.88867C10.0059 6.55859 8.65238 7.91211 6.98246 7.91211ZM8.82816 11.7969H13.4688C13.5461 11.7969 13.6094 11.7336 13.6094 11.6562V10.6719C13.6094 10.5945 13.5461 10.5312 13.4688 10.5312H10.3662L11.1959 9.4748C11.2153 9.4502 11.2258 9.41855 11.2258 9.38867C11.2258 9.31133 11.1625 9.24805 11.0852 9.24805H9.80902C9.72289 9.24805 9.64203 9.28848 9.58754 9.35527L8.38343 10.8863C8.30609 10.9848 8.2639 11.1078 8.2639 11.2344C8.26566 11.5455 8.51703 11.7969 8.82816 11.7969ZM13.0469 12.9219H8.40629C8.32894 12.9219 8.26566 12.9852 8.26566 13.0625V14.0469C8.26566 14.1242 8.32894 14.1875 8.40629 14.1875H11.5088L10.6791 15.2439C10.6598 15.2686 10.6493 15.3002 10.6493 15.3301C10.6493 15.4074 10.7125 15.4707 10.7899 15.4707H12.0661C12.1522 15.4707 12.233 15.4303 12.2875 15.3635L13.4916 13.8324C13.569 13.734 13.6112 13.6109 13.6112 13.4844C13.6094 13.1732 13.358 12.9219 13.0469 12.9219Z"
        fill="currentColor"
      />
    </svg>
  );
};
