import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { projectService } from "../api";
import { DropdownOption } from "../interfaces";
import { RootState } from "../store";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";

interface Props {
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useFetchPatientStatusOptions = ({ setIsAuthorized }: Props) => {
  const { clientId } = useSelector((state: RootState) => state.user.user);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([] as DropdownOption[]);

  const fetchPatientStatusList = useCallback(async () => {
    try {
      if (clientId) {
        setStatusLoading(true);
        const { data } = await projectService.getPatientStatusList(clientId);
        setStatusOptions(
          data.data.map((item) => ({ value: item.Name, id: item.Id }))
        );
      }
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setStatusLoading(false);
    }
  }, [clientId, setIsAuthorized]);

  return {
    fetchPatientStatusList,
    patientStatusLoading: statusLoading,
    setPatientStatusLoading: setStatusLoading,
    patientStatusOptions: statusOptions,
  };
};
