import React, { Dispatch, SetStateAction, useState } from "react";
import { Button } from "antd";
import { Loading } from "../../../components";
import { SearchOccurrence } from "../../../api";
import { SearchOccurrencesFilter } from "../../modals";
import { EditLineIcon } from "../../../assets/icons";
import "./evidence-viewer-container.scss";
import { ISources } from "../../../interfaces";

interface Props {
  currPage: number;
  setCurrPage: Dispatch<SetStateAction<number>>;
  searchKeywords: string | null;
  initialSearchKeywords: string | null;
  setSearchKeywords: Dispatch<SetStateAction<string | null>>;
  sources: ISources;
}

export const EvidenceViewerSources = (props: Props) => {
  const {
    currPage,
    setCurrPage,
    searchKeywords,
    setSearchKeywords,
    initialSearchKeywords,
    sources,
  } = props;
  const [displayDrawer, setDisplayDrawer] = useState(false);

  const showDrawer = () => {
    setDisplayDrawer(true);
  };

  return (
    <>
      <div className="result-pdf">
        <div className="result-occurrences-title flex">
          {`Search Results (${sources.pages.length})`}
          &nbsp;
          {initialSearchKeywords && searchKeywords && (
            <div className="edit-icon flex gp-10 aic" onClick={showDrawer}>
              <EditLineIcon />
              <span>Filter</span>
            </div>
          )}
        </div>

        {displayDrawer && (
          <SearchOccurrencesFilter
            {...{
              modalState: {
                visible: displayDrawer,
                setVisible: setDisplayDrawer,
              },
              searchKeywords,
              setSearchKeywords,
              initialSearchKeywords,
            }}
          />
        )}
        <div className="occurrences-group flex flex-column gp-10">
          {sources.pages.map((x, i) => (
            <Button
              key={`search-occurences-${i}`}
              className={`result-occurrences-content aic w-full ${
                currPage === x.pageNo ? "tab-active" : "tab-inactive"
              }`}
              onClick={() => {
                setCurrPage(x.pageNo);
              }}
            >
              {/* <span className="result-occurrences-count flex-center">
                  {x.inOccured ? x.inOccured : 1}
                </span> */}
              <p>Results in Page No. {x.pageNo}</p>
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};
