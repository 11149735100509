import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, setRecentSearch } from "../../../store";
import { searchService } from "../../../api";
import { Spin } from "antd";
import {
  PAGE_URL,
  SEARCH_KEYWORD_DELIMINATOR,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import { SEARCH_HEADING } from "../search-aside";
import "./recent-saved-search.scss";
import { useAuthorization } from "../../../hooks";

export const RecentSearches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { recent } = useSelector((state: RootState) => state.search);
  const [loading, setLoading] = useState(false);
  const { setIsAuthorized } = useAuthorization();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await searchService.getRecent();
        dispatch(setRecentSearch(data.data));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [dispatch, setIsAuthorized]);

  return (
    <div className={`recent-searches dashboard-topSearch`}>
      <h3>{SEARCH_HEADING.PREVIOUS}</h3>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <ul className="scrollBar">
          {recent.map((item, i) => (
            <li
              key={`recent-searches-${i}`}
              className="flex aic jcsb searches-li"
            >
              <span
                className="searches-title"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate({
                    pathname: `${PAGE_URL.SEARCH.LAYOUT}/${PAGE_URL.SEARCH.RESULT}`,
                    search: createSearchParams({
                      auto: "true",
                      search: item.query.join(SEARCH_KEYWORD_DELIMINATOR),
                      type: "All",
                    }).toString(),
                  })
                }
              >
                {[...item.query].slice(0, 3).join(", ")}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
