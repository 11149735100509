import React from "react";

interface Props {
  children?: JSX.Element;
  className?: string;
}

export const PageContainerWrapper = ({ children, className }: Props) => {
  return <div className={`main-container ${className ? className : ""}`}>{children}</div>;
};
