import React, { Dispatch, useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { projectService } from "../../../api";
import {
  openModal,
  RootState,
  setUserWithProjects,
  setUserWithProjectsEdit,
} from "../../../store";
import { CheckOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { AnyAction } from "redux";
import {
  MODAL_TYPE,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import "./assign-users-to-projects.scss";
import { useAuthorization } from "../../../hooks";

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  action: string;
  project: { Id: number; Name: string }[];
}

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<DataType> => [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Project",
    key: "project",
    dataIndex: "project",
    render: (text, record) => (
      <div className="multi-project flex flex-column">
        {record.project.map((item, index) => (
          <p className="flex gp-10 aic" key={index}>
            <CheckOutlined />
            {item.Name}
          </p>
        ))}
      </div>
    ),
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (value, record) => (
      <div className="editDelete jcc flex">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setUserWithProjectsEdit(Number(record.key)));
            dispatch(openModal(MODAL_TYPE.ASSIGN_PROJECT));
          }}
        >
          Assign to Project
        </a>
      </div>
    ),
  },
];

export const AssignUsersToProjects = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state: RootState) => state.data);
  const { clientId } = useSelector((state: RootState) => state.user.user);
  const [loading, setLoading] = useState(false);
  const { setIsAuthorized } = useAuthorization();
  const usersWithProjectData: DataType[] = users.usersWithProject.list.map(
    (item) => {
      const { Email, Id, Name, projects } = item;
      return {
        key: Id,
        name: Name,
        email: Email,
        action: "",
        project: projects,
      };
    }
  );
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await projectService.getUsersWithProjects(clientId);
        dispatch(setUserWithProjects(data.data));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    if(clientId) fetch();
  }, [clientId, dispatch, setIsAuthorized]);

  return (
    <div className="clientSearch dataAdmin assign basic-detail-content">
      <div className="clientList">
        <div className="client-table assign-user">
          <Table
            dataSource={usersWithProjectData}
            columns={getColumns(dispatch)}
            loading={loading}
            pagination={false}
          ></Table>
        </div>
      </div>
    </div>
  );
};
