import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { PAGE_URL } from "../../../../utils";
import { AppPagination } from "../../../../components";

interface Props {
  patientId: number;
}

export const PatientPagination = ({ patientId }: Props) => {
  const navigate = useNavigate();
  const patientList = useSelector((state: RootState) => state.patient.list);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const index = patientList.findIndex((patient) => patient.patientId === patientId);
    if (index !== -1) setPageNumber(patientList[index].sequenceNo);
  }, [patientId, patientList]);

  const getPatientId = (page: number) => {
    let res = 1;
    const index = patientList.findIndex((patient) => patient.sequenceNo === page);
    if (index !== -1) res = patientList[index].patientId;
    return res.toString();
  };

  return (
    <AppPagination
      total={patientList.length * 10}
      simple
      current={pageNumber}
      onChange={(page) =>
        navigate({
          pathname: `${PAGE_URL.PATIENT_PROFILE}/${getPatientId(page)}`,
        })
      }
    />
  );
};
