import axios from "axios";
import { apiClient } from ".";
import {
  AnnotationProjectFile,
  AnnotationProject,
  AnnotationFile,
  AnnotationSaveUploadedFilesRequest,
  SaveAnnotationsRequest,
  SavedAnnotation,
} from "../interfaces";

interface GetFileListResponse {
  data: AnnotationProjectFile[] | null;
  message: string;
}

interface GetAnnotationProjects {
  data: AnnotationProject[];
  message: string;
}

interface GetUploadUrlResponse {
  response: string;
  message: string;
}

interface GetFileResponse {
  message: string;
  data: AnnotationFile;
}

interface GetAnnotatedDataResponse {
  message: string;
  response: SavedAnnotation | null;
}

const getProjectsList = async () => {
  const res = await apiClient.get<GetAnnotationProjects>(`/annotation/getAnnotationProjects`);
  return res;
};

const getProjectFileList = async (projectId: number) => {
  const res = await apiClient.get<GetFileListResponse>(`/annotation/getfilelist/${projectId}`);
  return res;
};

const getUploadUrl = async (projectId: number, fileName: string) => {
  const res = await apiClient.get<GetUploadUrlResponse>(
    `/annotation/uplaodFile?projectId=${projectId}&fileName=${fileName}`
  );
  return res;
};

const saveUploadedFile = async (reqObj: AnnotationSaveUploadedFilesRequest[]) => {
  const res = await apiClient.post(`/annotation/saveFiles`, reqObj);
  return res;
};

const getFile = async (fileId: string) => {
  const res = await apiClient.get<GetFileResponse>(`/annotation/getFile/${fileId}`);
  return res;
};

const deleteFile = async (fileId: number) => {
  const res = await apiClient.delete(`/annotation/deleteFile/${fileId}`);
  return res;
};

const uploadFile = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: { "Content-Type": `multipart/form-data;` },
  });
  return res;
};

const saveAnnotatedData = async (reqObj: SaveAnnotationsRequest[]) => {
  const res = await apiClient.post(`/annotation/saveAnnotatedData`, reqObj);
  return res;
};

const getAnnotatedData = async (fileId: string, page: number) => {
  const res = await apiClient.get<GetAnnotatedDataResponse>(
    `/annotation/getAnnotatedData/${fileId}/${page}`
  );
  return res;
};

export const annotationService = {
  getProjectsList,
  getProjectFileList,
  getUploadUrl,
  uploadFile,
  saveUploadedFile,
  getFile,
  deleteFile,
  saveAnnotatedData,
  getAnnotatedData,
};
