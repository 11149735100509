import React from "react";
import { IconsProps } from "./Icons";

export const HeartRateIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 49 48"}
      width={width ? width : "49"}
      height={height ? height : "48"}
      fill={fill ? fill : "none"}
    >
        <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="0.381836" width="48" height="48" rx="6" fill="#FFD5EE"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M29.8735 33.9754C27.8984 35.4254 25.9204 36.4833 24.3654 37C19.4712 35.3737 10.3818 28.3819 10.3818 20.691C10.3818 16.4435 13.8253 13 18.0728 13C20.6737 13 22.974 14.2914 24.3654 16.268C25.0746 15.2578 26.0167 14.4334 27.1121 13.8645C28.2075 13.2957 29.4238 12.9991 30.658 13C34.9055 13 38.349 16.4435 38.349 20.691C38.349 21.9124 38.1196 23.1157 37.7148 24.2826M26.4629 28.3819H29.2597L31.3572 25.5852L33.4547 31.1786L35.5257 28.3819H38.349" stroke="#CC3D82" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        
    </svg>
  );
};
