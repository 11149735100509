import React, { forwardRef, useCallback, useEffect } from 'react';
import UTIF, { IFD } from 'utif';
import axios from 'axios';
import { AppPagination } from '../app-pagination/AppPagination';
import './tiff-viewer.scss';
import { Loading } from '../Loading';
import { Progress } from 'antd';

const getImageFromTiffPage = (data: any, ifd: IFD, index: number) => {
  UTIF.decodeImage(data, ifd);
  const rgba = UTIF.toRGBA8(ifd);
  const canvas = document.createElement('canvas');
  canvas.width = ifd.width;
  canvas.height = ifd.height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    const img = ctx.createImageData(ifd.width, ifd.height);
    img.data.set(rgba);
    ctx.putImageData(img, 0, 0);
  }
  const dataUrl = canvas.toDataURL('image/png');
  const imageEle = <img key={index} src={dataUrl} alt={`Page ${index + 1}`} />;
  return imageEle;
};

interface Props {
  tiff: any;
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  currPage: number;
  setCurrPage: (page: number) => void;
}

export const TIFFViewer = (props: Props) => {
  const { tiff, currPage, setCurrPage } = props;

  const [tiffs, setTiffs] = React.useState<JSX.Element[]>([]);
  const [total, setTotal] = React.useState(0);
  const [currTiff, setCurrTiff] = React.useState<JSX.Element>(<></>);
  const [loadingProgress, setLoadingProgress] = React.useState(0);

  function imgLoaded(e: any) {
    const data = e.target.response;
    const ifds = UTIF.decode(data);
    const pages = ifds.length;
    const _tiffs = [] as JSX.Element[];
    for (let index = 0; index < pages; index++) {
      //   setLoadingProgress((prev) => {
      //     console.log('prev: ', prev);
      //     console.log('new: ', prev + 100 / (pages * 2));
      //     return prev + 100 / (pages * 2);
      //   });
      const img = getImageFromTiffPage(data, ifds[index], index);
      _tiffs.push(img);
      if (index === 0) setCurrTiff(img);
      // document.getElementById('tiff-inner-container')?.appendChild(canvas);
    }
    setTotal(_tiffs.length);
    setTiffs(_tiffs);
  }

  const displayTIFF = useCallback(async (url: string) => {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader(
              'x-decompressed-content-length',
            );
        if (totalLength !== null) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / totalLength,
          );
          setLoadingProgress(percentCompleted);
        }
      },
    });
    imgLoaded({ target: { response: response.data } });
  }, []);

  useEffect(() => {
    displayTIFF(tiff);
  }, [displayTIFF, tiff]);

  useEffect(() => {
    // if (total > 0 && canvasRef.current) {
    //   canvasRef.current.innerHTML = '';
    //   canvasRef.current.appendChild(tiffs[currPage]);
    // }
    if (total > 0) {
      setCurrTiff(tiffs[currPage - 1]);
    }
  }, [currPage, tiffs, total]);

  return (
    <div className={'e-viewer e-content'} id="tiff-container">
      {total === 0 ? (
        loadingProgress < 100 ? (
          <Progress percent={Math.ceil(loadingProgress)} showInfo />
        ) : (
          <Loading tip="Processing file..." />
        )
      ) : (
        <>
          <div className="flex-center e-pdf-pagination">
            <AppPagination
              total={total * 10}
              simple
              current={currPage}
              onChange={(page) => setCurrPage(page)}
            />
          </div>
          {currTiff}
          {/* <div id="tiff-inner-container" className={''} ref={canvasRef} /> */}
        </>
      )}
    </div>
  );
};
