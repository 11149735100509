export * from "./ProjectDetailAside";
export * from "./ProjectDetailContainer";

export const PROJECT_DETAIL_TABS = {
  OVERVIEW: { name: "Overview", isAccessibleToClinician: true },
  PROJECT_INSIGHTS: { name: "Insights", isAccessibleToClinician: true },
  PROJECT_TEAM: { name: "Team", isAccessibleToClinician: false },
  ASSIGNED_PATIENTS: { name: "Assigned Subjects", isAccessibleToClinician: true },
  PATIENT_DOCUMENTS: { name: "Documents", isAccessibleToClinician: true },
  PROJECT_CONFIGURATION: { name: "Configurations", isAccessibleToClinician: false },
  // RECOMMENDER: { name: "AI Assisted Patient Recommender", isAccessibleToClinician: true },
};

export const FILE_UPLOAD_INITIAL_STATUS = "File Uploaded";
