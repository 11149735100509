import { useCallback, useState } from "react";
import { cohortService } from "../api/cohort";
import { useAuthorization } from "./useAuthorization";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";

export interface IFetchCohortPatientObj {
  limit: number;
  offset: number;
  cohortId: string;
}

export const useFetchCohortPatients = () => {
  const [loading, setLoading] = useState(false);
  const [cohortPatients, setCohortPatients] = useState([] as ICohortPatient[]);
  const [total, setTotal] = useState(0);
  const { setIsAuthorized } = useAuthorization();

  const fetchCohortPatients = useCallback(
    async (obj: IFetchCohortPatientObj) => {
      setLoading(true);
      try {
        const qs = `${obj.cohortId}?offset=${obj.offset}&limit=${obj.limit}`;
        const { data } = await cohortService.getCohortPatientList(qs);
        setCohortPatients([...data.data]);
        setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );
  return {
    cohortPatientsLoading: loading,
    fetchCohortPatients,
    cohortPatients,
    totalCohortPatients: total,
    setCohortPatientLoading: setLoading,
  };
};
