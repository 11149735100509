import React from "react";
import { ProjectDetail } from "../../interfaces";
import "./project-detail-aside.scss";
import { ProjectTeam } from "./project-detail-aside";

interface Props {
  projectDetail: ProjectDetail;
}

export const ProjectDetailAside = (props: Props) => {
  const { projectDetail } = props;

  return (
    <>
      <ProjectTeam {...{ projectDetail }} />
    </>
  );
};
