import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import { logoutUser, openModal, RootState } from "../../../store";
import { MODAL_TYPE, PAGE_TITLE, USER_ROLES_CODE } from "../../../utils";
import { PAGE_URL } from "../../../utils";
import {
  // EditProfileIcon,
  LogoutIcon,
  MenuIcon,
  UserSwitchIcon,
} from "../../../assets/icons";
import { useUser } from "../../../hooks";
import { BackArrow } from "../../../components";
import "./app-header.scss";
import { ActiveDirectoryService } from "../../../api";

export const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectName, patientName } = useSelector(
    (state: RootState) => state.ui
  );
  const location = useLocation();
  const {
    firstName,
    lastName,
    originalRoleName,
    isImpersonationActive,
    roleName,
    originalRoleCode,
  } = useUser();
  const { pageTitle, displayBackButton } = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    let backButton = false;
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
        backButton = page.displayBackButton;
      }
      if (pathname.includes(PAGE_URL.PROJECT_DETAIL)) {
        title = projectName;
      }
      if (
        pathname.includes(PAGE_URL.PATIENT_PROFILE) &&
        pathname !== PAGE_URL.PATIENTS_LIST
      ) {
        title = patientName;
      }
    });
    return { pageTitle: title, displayBackButton: backButton };
  }, [location.pathname, projectName, patientName]);

  const logout = async (e: any) => {
    e.preventDefault();
    const { data } = await ActiveDirectoryService.activeDirectoryLogout(
      window.location.origin
    );
    if (data) {
      dispatch(logoutUser());
      navigate(data.data.logoutUri);
    }
  };

  return (
    <Header className="flex aic jcsb header">
      <div className="flex gp aic">
        {displayBackButton && <BackArrow />}
        <h1 className="header-h1 m-0">{pageTitle}</h1>
        {isImpersonationActive && (
          <div className="impersonation-bar">{`Impersonation for ${roleName}`}</div>
        )}
      </div>
      {/* <Button onClick={() => toggleTheme()}>toggle theme</Button> */}
      <div className="header-right flex gp-10 aic">
        <div className="header-content-menu pr cursor-pointer">
          <a className="header-dropdown" href="#">
            <MenuIcon />
          </a>
          <div className="header-dropdown-menu flex flex-column">
            <div className="header-content-profile-content">
              <p>
                {firstName} {lastName} <span>{originalRoleName}</span>
              </p>
            </div>
            {/* <a
              href="#"
              className="header-dropdown-menu-item flex aic no-wrap"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openModal(MODAL_TYPE.EDIT_PROFILE));
              }}
            >
              <EditProfileIcon />
              Edit Profile
            </a> */}
            {originalRoleCode === USER_ROLES_CODE.ENTERPRISE_ADMIN && (
              <a
                href="#"
                className="header-dropdown-menu-item flex aic no-wrap"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(openModal(MODAL_TYPE.USER_MULTIPLE_ROLES));
                }}
              >
                <UserSwitchIcon />
                Switch Role
              </a>
            )}
            <a
              href="#"
              className="header-dropdown-menu-item flex aic no-wrap"
              onClick={logout}
            >
              <LogoutIcon />
              Logout
            </a>
          </div>
        </div>
      </div>
    </Header>
  );
};
