import { CloseOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Form, Input, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AddUserToCohortProps } from ".";
import { cohortService, ISaveCohortResponse } from "../../../api/cohort";
import { setCohorts } from "../../../store";
import { openNotificationWithIcon } from "../../../utils";

type OptionType = { value: string; id: number };

export const AddPatientToCohort = ({
  visibility,
  handleClose,
  patientId,
  patientName,
  cohorts,
}: AddUserToCohortProps) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState({
    id: -2,
    value: "",
  } as OptionType);
  const [isLoading, setIsLoading] = useState(false);
  const [isCohortsLoading, setIsCohortLoading] = useState(false);
  const options: OptionType[] = cohorts.map((item) => ({
    value: item.Name,
    id: item.Id,
  }));

  useEffect(() => {
    const fetchCohorts = async () => {
      try {
        setIsCohortLoading(true);
        const { data } = await cohortService.getCohortList();
        dispatch(setCohorts([...data.data]));
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        handleClose();
      } finally {
        setIsCohortLoading(false);
      }
    };
    fetchCohorts();
  }, [dispatch, handleClose]);

  const onSelectChange = (value: string, option: OptionType) => {
    setSelectedOption(option);
  };

  const onChange = (value: string) => {
    if (value === "") {
      setSelectedOption({ id: -2, value: "" });
    } else {
      setSelectedOption({ id: -1, value });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let data = {} as ISaveCohortResponse;
    try {
      if (selectedOption.id === -1) {
        const response = await cohortService.saveCohort(
          selectedOption.value,
          patientId
        );
        data = response.data;
      } else {
        const response = await cohortService.saveCohort(
          selectedOption.value,
          patientId,
          selectedOption.id
        );
        data = response.data;
      }
      openNotificationWithIcon("", data.response, "success");
      handleClose();
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.response, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title="Assign patient to cohort"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Assign Cohort</h1>
      </div>
      <div className="auto-complete addClient-content">
        <p className="m-b">Subject Name </p>
        <Input value={patientName} disabled />
        <p className="m-t">Cohorts</p>
        <AutoComplete
          // dropdownClassName="align-dropdown"
          //placeholder="Type or Select from the dropdown"
          value={selectedOption.value}
          options={options}
          onSelect={onSelectChange}
          onChange={onChange}
          notFoundContent={isCohortsLoading && <Spin tip="Loading..." />}
        />

        <Form.Item className="tr">
          <Button
            className="outline"
            loading={isLoading}
            onClick={onSubmit}
            disabled={selectedOption.id === -2}
          >
            Assign Patient
          </Button>
        </Form.Item>
      </div>
    </Modal>
  );
};
