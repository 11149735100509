import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdvanceSearchParams, RecentAndSaveSearch, SearchResultResponse } from "../../api";

interface SearchIntialState {
  result: SearchResultResponse;
  recent: RecentAndSaveSearch[];
  saved: RecentAndSaveSearch[];
  advanceSearchParams: AdvanceSearchParams;
}
export const searchInitialState: SearchIntialState = {
  result: {} as SearchResultResponse,
  recent: [],
  saved: [],
  advanceSearchParams: {} as AdvanceSearchParams,
};

export const searchSlice = createSlice({
  name: "search",
  initialState: searchInitialState,
  reducers: {
    setSearchResult: (
      state: SearchIntialState,
      { payload }: PayloadAction<SearchResultResponse>
    ) => {
      state.result = { ...payload };
    },
    setRecentSearch: (
      state: SearchIntialState,
      { payload }: PayloadAction<RecentAndSaveSearch[]>
    ) => {
      state.recent = [...payload];
    },
    updatedRecentSearch: (
      state: SearchIntialState,
      { payload }: PayloadAction<RecentAndSaveSearch>
    ) => {
      state.recent = state.recent.map((item) => (item.id === payload.id ? { ...payload } : item));
    },
    setSavedSearch: (
      state: SearchIntialState,
      { payload }: PayloadAction<RecentAndSaveSearch[]>
    ) => {
      state.saved = [...payload];
    },
    addRecentSearch: (
      state: SearchIntialState,
      { payload }: PayloadAction<RecentAndSaveSearch>
    ) => {
      state.recent = [payload, ...state.recent];
    },
    addSavedSearch: (state: SearchIntialState, { payload }: PayloadAction<RecentAndSaveSearch>) => {
      state.saved = [payload, ...state.saved];
    },
    removeSavedSearch: (state: SearchIntialState, { payload }: PayloadAction<number>) => {
      state.saved = state.saved.filter((item) => item.id !== payload);
    },
    setAdvanceSearchParams: (
      state: SearchIntialState,
      { payload }: PayloadAction<AdvanceSearchParams>
    ) => {
      state.advanceSearchParams = { ...payload };
    },
  },
});

export const {
  setSearchResult,
  setRecentSearch,
  updatedRecentSearch,
  setSavedSearch,
  addRecentSearch,
  addSavedSearch,
  removeSavedSearch,
  setAdvanceSearchParams,
} = searchSlice.actions;

export default searchSlice.reducer;
