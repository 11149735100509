import apiClient from "./apiClient";

interface IUserSingleDirectoryLoginResponse {
  data: {
    authCodeUrl: string;
    verifier: string;
  };
  status: string;
}

interface IAccount {
  username: string;
  name: string;
  accessToken: string;
  tokenType: string;
  expiresOn: string;
}

interface IRole {
  id: number;
  name: string;
}

interface IGetActiveDirectoryUserResponse {
  account: IAccount;
  role: IRole;
  orgId: number;
}

interface IGetActiveDirectoryUserResponseWrapper {
  data: IGetActiveDirectoryUserResponse;
  status: string;
}

interface IActiveDirectoryLogoutResponse {
  data: {
    logoutUri: string;
  };
  status: string;
}

const activeDirectoryLogin = async (
  redirectURI: string,
  successRedirectURI: string
) => {
  const res = await apiClient.post<IUserSingleDirectoryLoginResponse>(
    "/auth/login",
    {
      redirectURI,
      successRedirectURI,
    }
  );
  return res;
};

const activeDirectoryAcquireToken = async (
  code: string,
  redirectURI: string,
  codeVerifier: string
) => {
  const res = await apiClient.post<IGetActiveDirectoryUserResponseWrapper>(
    "/auth/requestToken",
    {
      code,
      redirectURI,
      codeVerifier,
    }
  );
  return res;
};
const activeDirectoryLogout = async (logoutURI: string) => {
  const res = await apiClient.post<IActiveDirectoryLogoutResponse>(
    "/auth/logout",
    {
      logoutURI,
    }
  );
  return res;
};

const validateLicenseKey = async (
  licenseKey: string
): Promise<{
  data: { isValid: boolean; licenseKey: string };
  status: string;
}> => {
  // const res = await active.post<IValidateLicenseKeyResponse>("", { licenseKey });
  // return res;
  return { data: { isValid: true, licenseKey }, status: "" };
};

export const ActiveDirectoryService = {
  activeDirectoryLogin,
  activeDirectoryAcquireToken,
  activeDirectoryLogout,
  validateLicenseKey,
};
