import { apiClient } from ".";
import { IPagination } from "../interfaces";

interface IGetAnswerRequest {
  patientId: number;
  question: string;
  documentUUID?: string;
}

interface IGetAnswerResponse {
  message: string;
  data: {
    answer: IAnswer;
    query: string;
    subject_id: number;
  };
}

interface IGetQAHistoryRequest {
  patientId: number;
  limit: number;
  offset: number;
}

interface IGetQAHistoryResponse {
  message: string;
  data: IQAHistory[];
  pagination: IPagination;
}

interface IGetSingleQARequest {
  patientId: number;
  logId: number;
}

interface IGetSingleQAResponse {
  message: string;
  data: IQAHistory;
}

interface IClearHistoryResponse {
  message: string;
}

const getAnswer = async (obj: IGetAnswerRequest) => {
  const { patientId, question, documentUUID } = obj;
  const res = await apiClient.get(
    `/qa/${documentUUID}?query=${question}`
  );
  return res;
};

const getQAHistory = async ({
  limit,
  offset,
  patientId,
}: IGetQAHistoryRequest) => {
  const res = await apiClient.get<IGetQAHistoryResponse>(
    `patient/${patientId}/qa-model/logs?limit=${limit}&offset=${offset}`
  );
  return res;
};

const getSingleQa = async ({ logId, patientId }: IGetSingleQARequest) => {
  const res = await apiClient.get<IGetSingleQAResponse>(
    `patient/qa-model/${patientId}/log/${logId}`
  );
  return res;
};

const clearHistory = async (patientId: number) => {
  const res = await apiClient.delete<IClearHistoryResponse>(
    `patient/${patientId}/qa-model/logs`
  );
  return res;
};

export const QuestionAnsweringService = {
  getAnswer,
  getQAHistory,
  getSingleQa,
  clearHistory,
};
