import React, { useCallback, useMemo, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { SearchResultResponse, searchService } from "../../../api";
import { BookmarkIcon, FilledBookmarkIcon } from "../../../assets/icons";
import { AppPagination, Loading } from "../../../components";
import {
  RootState,
  addSavedSearch,
  removeSavedSearch,
  updatedRecentSearch,
} from "../../../store";
import { PAGE_URL, openNotificationWithIcon } from "../../../utils";

interface DataType {
  key: React.Key;
  patientId: number;
  fileId: string;
  patientName: string;
  mrn: string;
  fileName: string;
  occurences: number;
  action: string;
}

interface KeyValuePair {
  [key: string]: string;
}

const getColumns = (
  getExistingSearchParams: () => KeyValuePair
): ColumnsType<DataType> => [
  {
    title: "Subject Name",
    dataIndex: "patientName",
    render: (value, record) => (
      <Link
        style={{ color: "#475569", textDecoration: "none", display: "block" }}
        to={`${PAGE_URL.PATIENT_PROFILE}/${record.patientId}`}
      >
        {value}
      </Link>
    ),
  },
  {
    title: "Subject ID",
    dataIndex: "mrn",
  },
  {
    title: "File Name",
    dataIndex: "fileName",
  },
  {
    title: "No. of Occurences",
    dataIndex: "occurences",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value, record) => (
      <Link
        style={{ color: "#3b70cd", textDecoration: "none", display: "block" }}
        to={{
          pathname: PAGE_URL.EVIDENCE_VIEWER,
          search: createSearchParams({
            fileName: record.fileName,
            fileId: record.fileId,
            ...getExistingSearchParams(),
          }).toString(),
        }}
      >
        <span title={record.fileName}>Evidence Viewer</span>
      </Link>
    ),
  },
];

interface Props {
  loading: boolean;
  result: SearchResultResponse;
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void;
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  totalSearchResults: number;
}

export const SearchResults = ({
  loading,
  result,
  onPaginationChange,
  page,
  pageSize,
  pageSizeOptions,
  totalSearchResults,
}: Props) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const { saved: savedSearches } = useSelector(
    (state: RootState) => state.search
  );

  const isFeatured = useMemo(() => {
    let res = false;
    if (result.lastSearch) {
      const record = savedSearches.filter(
        (item) => item.id === result.lastSearch.id
      );
      if (record.length > 0) res = record[0].featured;
    }
    return res;
  }, [result.lastSearch, savedSearches]);

  const saveSearch = async () => {
    try {
      setBookmarkLoading(true);
      const record = result.lastSearch;
      const { data } = await searchService.bookmarkSearch(
        record.id,
        !isFeatured
      );
      dispatch(updatedRecentSearch(data.data));
      if (data.data.featured) dispatch(addSavedSearch(data.data));
      else dispatch(removeSavedSearch(data.data.id));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setBookmarkLoading(false);
    }
  };

  const getExistingSearchParams = useCallback(() => {
    const params = [];
    for (const entry of searchParams.entries()) {
      params.push(entry);
    }
    const res: { [key: string]: string } = {};
    params.map(([key, value]) => {
      res[key] = value;
    });
    return res;
  }, [searchParams]);

  const dataSource: DataType[] =
    result.data &&
    result.data.map((item, index) => {
      const { fileId, fileName, mrn, occurences, patientId, patientName } =
        item;
      return {
        action: "Evidence Viewer",
        fileId,
        fileName,
        key: `search-result-${index}`,
        mrn,
        occurences,
        patientId,
        patientName,
      };
    });

  return (
    <div className="result">
      <div className="result-title gp flex jcsb">
        {/* <h2 className="searches-h2">
          {!loading && result.data && `${result.data.length} results found`}
        </h2> */}
        <div className="flex gp aic saveSearch" onClick={() => saveSearch()}>
          {bookmarkLoading ? (
            <Loading tip="" size="small" />
          ) : isFeatured ? (
            <FilledBookmarkIcon />
          ) : (
            <BookmarkIcon />
          )}
          <h2>Save this Search</h2>
        </div>
        <AppPagination
          {...{
            showSizeChanger: true,
            current: page,
            total: totalSearchResults,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div>
      {/* {loading ? (
        <Loading tip="Seaching..." />
      ) : ( */}
      <>
        <Table
          className="recent-searches"
          rowClassName="cursor-pointer"
          columns={getColumns(getExistingSearchParams)}
          dataSource={dataSource}
          loading={loading}
          pagination={false}
        />
      </>
      {/* )} */}
    </div>
  );
};
