import { Dispatch } from "redux";
import { connect } from "react-redux";
import AddEditProject from "./AddEditProject";
import { closeModal, RootState, setProjectEditDelete } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { Project } from "../../../interfaces";

type StateProps = {
  visibility: boolean;
  isEdit: boolean;
  editObj: Project;
  clientId: number;
};
type DispatchProps = {
  handleClose: () => void;
};
export type AddEditProjectProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const isEdit = state.data.projects.isEdit;
  const id = state.data.projects.id;
  let editObj = {} as Project;
  if (isEdit && id !== -1) {
    const obj = state.data.projects.list.find((item) => item.Id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_PROJECT],
    isEdit,
    editObj,
    clientId: state.user.user.clientId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setProjectEditDelete({ id: -1, isEdit: false }));
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_PROJECT));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProject);
