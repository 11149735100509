import React, { useState } from "react";
import { Form, Input, Modal } from "antd";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";
import { AddSummarizationTaskProps } from ".";
import "./add-edit-summarization-task.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setEditSummaryData } from "../../../store";
import { isEmptyObject } from "../../../utils";
import { AddSummaryTask, summaryService } from "../../../api/summarization";
import { SummaryTypes } from "../../../interfaces";

const FORM_KEYS = {
  title: "title",
  question: "question",
};

const AddEditSummarizationTask = ({
  handleClose,
  visibility,
  projectId,
}: AddSummarizationTaskProps) => {
  const editSummaryData = useSelector(
    (state: RootState) => state.ui.editSummaryData
  );
  const [form] = Form.useForm();
  const [disableSave, setDisableSave] = useState(true);
  const dispatch = useDispatch();

  const handleAddSummary = async (reqObj: AddSummaryTask) => {
    await summaryService.addOne(reqObj);
  };

  const handleEditSummary = async (reqObj: AddSummaryTask) => {
    await summaryService.editOne(editSummaryData.Id, reqObj);
  };

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { title, question } = values;
      isEmptyObject(editSummaryData)
        ? await handleAddSummary({
            title: title,
            question: question,
            project_id: projectId,
          })
        : await handleEditSummary(values);
        dispatch(setEditSummaryData({} as SummaryTypes))
      console.log("Summarization task added:", values, projectId);
      handleClose();
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };
  const handleFormChange = () => {
    const hasErrors =
      form
        .getFieldsError()
        .filter(({ errors }) => errors.length)
        .some((error) => error.name[0] === FORM_KEYS.title || error.name[0] === FORM_KEYS.question);
  
    // Check if editSummaryData is empty
    if (isEmptyObject(editSummaryData)) {
      // Enable the button only if both fields are touched
      const isBothFieldsTouched = form.isFieldTouched(FORM_KEYS.title) && form.isFieldTouched(FORM_KEYS.question);
      setDisableSave(hasErrors || !isBothFieldsTouched);
    } else {
      // Enable the button if any field is touched
      setDisableSave(hasErrors);
    }
  };
  
  

  return (
    <Modal
      className="autonomizeModal addClient"
      centered
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>
          {isEmptyObject(editSummaryData)
            ? "Add Summarization Task"
            : "Edit Summarization Task"}
        </h1>
      </div>
      <div className="addClient-content customize-ant-tag">
        <Form
          form={form}
          onFinish={handleFormSubmit}
          onValuesChange={handleFormChange}
          onFieldsChange={handleFormChange}
        >
          <h1>Title *</h1>
          <Form.Item
            name={FORM_KEYS.title}
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input
              defaultValue={
                isEmptyObject(editSummaryData) ? "" : editSummaryData.title
              }
            />
          </Form.Item>
          <div>
            <h1>Enter your question *</h1>
            <p>
              {`e.g, Describe the patient's history as it relates to heart
              disease or family history of cancer .. etc.`}
            </p>
          </div>
          <Form.Item
            name={FORM_KEYS.question}
            rules={[{ required: true, message: "Please enter the question" }]}
          >
            <Input.TextArea
              className="summary-area"
              defaultValue={
                isEmptyObject(editSummaryData) ? "" : editSummaryData.question
              }
            />
          </Form.Item>

          <Form.Item className="tr mandatory-text summary-button">
            <Button
              className="fill summary-button"
              htmlType="submit"
              disabled={disableSave}
            >
              Save Details
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditSummarizationTask;
