import { Dispatch } from "redux";
import { connect } from "react-redux";
import UserMultipleRoles from "./UserMutlipleRoles";
import { closeModal, removeImpersonation, RootState, setImpersonation } from "../../../store";
import { MODAL_TYPE } from "../../../utils";

type StateProps = {
  visibility: boolean;
  impersonation: { roleCode: string; isActive: boolean };
};
type DispatchProps = {
  handleClose: () => void;
  impersonateUser: (roleCode: string, roleName: string) => void;
  removeImpersonation: () => void;
};
export type UserMultipleRolesProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.USER_MULTIPLE_ROLES],
  impersonation: state.user.impersonate,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.USER_MULTIPLE_ROLES));
  },
  impersonateUser: (roleCode, roleName) => {
    dispatch(setImpersonation({ roleCode, roleName }));
  },
  removeImpersonation: () => {
    dispatch(removeImpersonation());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMultipleRoles);
