import React from "react";

interface Props {
  text: string;
  value: string;
}

export const PatientInsightTile = ({ text, value }: Props) => {
  return (
    <div className="flex aic jcsb data-two-column-row cursor-pointer">
      <p className="data-title-text">{text}</p>
      <h5 className="data-title-score mb-0">{value}</h5>
    </div>
  );
};
