export * from "./PatientInsightAside";
export * from "./PatientInsightContainer";

export const PATIENT_INSIGHT_TABS = {
  PATIENT_INSIGHTS: "Patient Insights",
  PROJECT_SPECIFIC_INSIGHTS: "Project Specific Insights",
};

export const PATIENT_INSIGHT_TABS_NEW = {
  SUMMARY: "Summary",
  CLINICAL_INSIGHTS: "Clinical Insights",
  HEDIS_CARE_GAPS: "HEDIS Care Gaps",
  PROJECT_SPECIFIC_INSIGHTS: "Project Specific Insights",
  // HEDIS_MEASURE_INISGHTS: "HEDIS Measure Insights",
  ASK_A_QUESTION: "Ask Auto",
};

export interface PatientDetailCardProps {
  heading: string;
  value: string;
}
