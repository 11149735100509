import React, { Dispatch, SetStateAction } from 'react';
import { PatientProjects } from '../../../api';
import { PatientDetailCardProps } from '..';
import { IPatient } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { useUser } from '../../../hooks';
import { openModal, updateAssignPatientsList } from '../../../store';
import { CLINICIAN_PERMISSIONS, MODAL_TYPE } from '../../../utils';
import PatientProfileProjects from './PatientProfileProjects';
import { Button } from 'antd';
import { PatientProfileInfo } from './PatientProfileInfo';
import { FileListCard } from './FileListCard';
import { PatientInsightStatus } from './PatientInsightStatus';

interface Props {
  patientInfo: IPatient;
  patientProjects: PatientProjects[];
  selectedProject: PatientProjects;
  setSelectedProject: (item: PatientProjects) => void;
  reviewStatusMap: PatientDetailCardProps[];
  patientProjectsLoading: boolean;
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PatientInsightHeader = (props: Props) => {
  const {
    patientInfo,
    patientProjects,
    selectedProject,
    setSelectedProject,
    reviewStatusMap,
    patientProjectsLoading,
    setReviewStatusMap,
    isAuthorized,
    setIsAuthorized,
  } = props;
  const { patientId, mrn, source, fileList } = patientInfo;
  const dispatch = useDispatch();
  const { roleCode } = useUser();

  const onAssignProject = () => {
    dispatch(
      updateAssignPatientsList({
        patientIds: [patientId],
        alreadyAssignedProjects: patientProjects.map((item) => item.Id),
      }),
    );
    dispatch(openModal(MODAL_TYPE.ADD_PATIENTS_TO_PROJECT));
  };

  const onAssignCohort = () => {
    dispatch(openModal(MODAL_TYPE.ADD_PATIENT_TO_COHORT));
  };
  return (
    <div>
      <PatientProfileProjects
        {...{
          projects: patientProjects,
          selectedProject,
          setSelectedProject,
          patientProjectsLoading,
        }}
      />
      <div className="flex gp-10 m-b">
        {!CLINICIAN_PERMISSIONS.includes(roleCode) && (
          <>
            <Button type="primary" className="fill" onClick={onAssignProject}>
              Assign to Project
            </Button>
          </>
        )}
        <Button type="primary" className="fill" onClick={onAssignCohort}>
          Assign to Cohort
        </Button>
      </div>
      <PatientProfileInfo
        patientInfo={{
          mrn,
          source: source,
        }}
        reviewStatusMap={reviewStatusMap}
      />
      <div className="flex aic gp m-t">
        <h4 className="mb-0">Status: </h4>
        <PatientInsightStatus
          {...{
            selectedProject,
            patientInfo,
            patientProjects,
            setReviewStatusMap,
            isAuthorized,
            setIsAuthorized,
          }}
        />
      </div>
      {fileList && <FileListCard fileList={fileList} />}
    </div>
  );
};
