import React from "react";
import { ConfigIcon, DocumentsIcon, ChecklistIcon, OverviewIcon } from "../../../assets/icons";

export const PROJECT_DETAIL_TABS = {
  OVERVIEW: { name: "Overview", isAccessibleToClinician: true, icon: <OverviewIcon /> },
  // PROJECT_INSIGHTS: { name: "Insights", isAccessibleToClinician: true, icon: <ChartReviewIcon /> },
  // PROJECT_TEAM: { name: "Team", isAccessibleToClinician: false, icon: <ChartReviewIcon /> },
  ASSIGNED_PATIENTS: {
    name: "Assigned Subjects",
    isAccessibleToClinician: true,
    icon: <ChecklistIcon />,
  },

  PATIENT_DOCUMENTS: {
    name: "Documents",
    isAccessibleToClinician: true,
    icon: <DocumentsIcon />,
  },
  PROJECT_CONFIGURATION: {
    name: "Configurations",
    isAccessibleToClinician: false,
    icon: <ConfigIcon />,
  },
  // RECOMMENDER: { name: "AI Assisted Patient Recommender", isAccessibleToClinician: true , icon: <ChartReviewIcon />},
};

interface Props {
  name: string;
  icon: JSX.Element;
}

export const ProjectDetailsTabsHeadings = ({ icon, name }: Props) => {
  return (
    <div>
      <div className="tc">{icon}</div>
      <div>{name}</div>
    </div>
  );
};
