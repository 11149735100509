import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import {
  TopSearches,
  ProjectListInTableAndTile,
  ProjectAndPatientTile,
  Sidebar,
} from "./dashboard-container";
import "./Clinician-and-data-admin-container.scss";
import { withAuthorization } from "../../components/hoc/withAuthorization";
import { useAuthorization, useUser } from "../../hooks";
import { DashboardData, DashboardService } from "../../api";
import { STATUS_CODE, openNotificationWithIcon } from "../../utils";

const AuthorizedProjectAndPatientTile = withAuthorization(
  ProjectAndPatientTile
);

const initialData: DashboardData  = {
  patient: {
    count: 0,
    status: {
      reviewed: 0,
      toBeReviewed: 0,
    },
  },
  project: {
    count: 0,
    status: {
      inProgress: 0,
      pending: 0,
    },
  },
  kpiSettings: {
    kpi_patient_stats_card: [""],
    kpi_project_stats_card: [""],
    kpi_time_per_card: [""],
  },
};


export const ClinicianAndDataAdminContainer = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const { clientId } = useUser();
  const { setIsAuthorized } = useAuthorization();  

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const { data: res } = await DashboardService.getData(clientId);
        setData({ ...initialData, ...res.data});
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    if(clientId) fetchDashboardData();
  }, [clientId, setIsAuthorized]);

  return (
    <div className="dashboard">
      <div className="top-search-result">
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <AuthorizedProjectAndPatientTile />
        </Row>
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <div className="projectsList">
            <ProjectListInTableAndTile className="scroll-small" />
          </div>
        </Row>
        <Row className="top-search-result-item" gutter={[21, 20]}>
          <Col span={24}>
            <h2>Top Searches</h2>
          </Col>
          <Col span={24}>
            <TopSearches />
          </Col>
        </Row>
      </div>
      <Sidebar data={data} />
    </div>
  );
};
