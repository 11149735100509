import { PAGE_URL } from "../../../../utils";

export * from "./PatientCardWrapper";
export * from "./PatientDetailCardInfo";
export * from "./PatientTableWrapper";
export * from "./ViewMoreResults";
export * from "./NoResultsFoundMessage";
export * from "./PatientInsightTag";
export * from "./PatientInsightTile";

export const EVIDENCE_VIEWER_URL = `${PAGE_URL.EVIDENCE_VIEWER}?type=All&gender=Both&ageGroup=All&location=All+Countries&interventions=All&multipleFiles=true`;
export const EVIDENCE_VIEWER_URL_SINGLE_FILE = `${PAGE_URL.EVIDENCE_VIEWER}?type=All&gender=Both&ageGroup=All&location=All+Countries&interventions=All`;
export const CONFIDENCE_SCORE_INFO =
  "Shows the % confidence of AI models in identifying and classifying these entities";
export const NO_ENTRY_FOUND = "No entries found.";
export const getNoEntryBelowThresholdMessage = (threshold: number) =>
  `No entries found that met the ${threshold}% confidence.`;
export const NO_ENTRY_BELOW_80 = "No entries found that met the 80% confidence.";

export const SEARCH_PAGE_REDIRECT = `${PAGE_URL.SEARCH.LAYOUT}/${PAGE_URL.SEARCH.RESULT}?auto=true&type=All&gender=Both&ageGroup=All&location=All+Countries&interventions=All`;
