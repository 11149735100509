import React from "react";
import { IconsProps } from "./Icons";

export const OverviewIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 14 16"}
      width={width ? width : "14"}
      height={height ? height : "16"}
      fill={fill ? fill : "currentColor"}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M11.6364 1.45455H8.59636C8.29091 0.610909 7.49091 0 6.54545 0C5.6 0 4.8 0.610909 4.49455 1.45455H1.45455C0.654545 1.45455 0 2.10909 0 2.90909V14.5455C0 15.3455 0.654545 16 1.45455 16H11.6364C12.4364 16 13.0909 15.3455 13.0909 14.5455V2.90909C13.0909 2.10909 12.4364 1.45455 11.6364 1.45455ZM6.54545 1.45455C6.94545 1.45455 7.27273 1.78182 7.27273 2.18182C7.27273 2.58182 6.94545 2.90909 6.54545 2.90909C6.14545 2.90909 5.81818 2.58182 5.81818 2.18182C5.81818 1.78182 6.14545 1.45455 6.54545 1.45455ZM11.6364 14.5455H1.45455V2.90909H2.90909V5.09091H10.1818V2.90909H11.6364V14.5455Z" fill="currentColor"/>
    </svg>
  );
};
