import { apiClient } from ".";

interface IAddFeddbackRequest {
  patientId: number;
  question: string;
  answer: IAnswer;
  feedback: string;
  feedbackType: boolean;
}

interface IAddFeddbackResponseData {
  Id: number;
  PatientId: number;
  Question: string;
  Answer: IAnswer;
  Feedback: string;
  FeedbackType: boolean;
  CreatedBy: number;
  updatedAt: string;
  createdAt: string;
}

interface IAddFeddbackResponse {
  message: string;
  data: IAddFeddbackResponseData;
}

const addFeedback = async (obj: IAddFeddbackRequest) => {
  const res = await apiClient.post<IAddFeddbackResponse>(
    `/patient/${obj.patientId}/qa-model/feedback`,
    obj
  );
  return res;
};

export const feedbackService = {
  addFeedback,
};
