import React, { useState } from "react";
import { NotAccessibleFallback } from "../NotAccessibleFallback";
import { AuthorizationContext } from "../../hooks";

export const withAuthorization = <T extends object>(
  WrappedComponent: React.ComponentType<T>
) => {
  const AuthorizedComponent = (props: T) => {
    const [isAuthorized, setIsAuthorized] = useState(true);

    if (!isAuthorized) {
      return <NotAccessibleFallback />;
    }

    return (
      <AuthorizationContext.Provider value={{ isAuthorized, setIsAuthorized }}>
        <WrappedComponent {...props} />
      </AuthorizationContext.Provider>
    );
  };

  return AuthorizedComponent;
};
