import React, { useEffect, useState } from "react";
import {
  AssociatedPatientsList,
  UnassociatedPatientsList,
  PatientsListTab,
  CohortList,
} from "./patients-list-container";
import { PATIENTS_LIST_TABS } from ".";
import { useSearchParams } from "react-router-dom";
import { appendTabToRouteQuery } from "../../utils";
import { withAuthorization } from "../../components/hoc/withAuthorization";

const AuthorizedAssociatedPatientsList = withAuthorization(
  AssociatedPatientsList
);
const AuthorizedUnassociatedPatientsList = withAuthorization(
  UnassociatedPatientsList
);
const AuthorizedCohortList = withAuthorization(CohortList);

export const PatientsListContainer = () => {
  const [selectedTab, setSelectedTab] = useState(PATIENTS_LIST_TABS.ASSOCIATED);
  const [component, setComponent] = useState(<></>);
  const [searchParams, setSearchParams] = useSearchParams();
  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    setSelectedTab(tab || PATIENTS_LIST_TABS.ASSOCIATED);
  }, []);

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PATIENTS_LIST_TABS.ASSOCIATED:
        res = <AuthorizedAssociatedPatientsList />;
        break;
      case PATIENTS_LIST_TABS.UNASSOCIATED:
        res = <AuthorizedUnassociatedPatientsList />;
        break;
      case PATIENTS_LIST_TABS.COHORTS:
        res = <AuthorizedCohortList />;
        break;
    }
    setComponent(res);
    appendTabToRouteQuery(selectedTab);
  }, [selectedTab]);

  return (
    <>
      <PatientsListTab {...{ onChange, selectedTab }} />
      {component}
    </>
  );
};
