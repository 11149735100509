import { useCallback, useState } from "react";
import { PatientService, PatientWithFilters } from "../api";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";

export interface FetchPatientsObj {
  search?: string;
  limit?: number;
  offset?: number;
  assigned?: boolean;
  statusId?: number;
  sortField?: string;
  sortType?: "asc" | "desc";
  projectId?: number;
}

interface Props {
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useFetchPatients = ({ setIsAuthorized }: Props) => {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([] as PatientWithFilters[]);
  const [total, setTotal] = useState(0);
  const fetchPatients = useCallback(
    async (obj: FetchPatientsObj) => {
      setLoading(true);
      try {
        let qs = "total=true";
        for (const [key, value] of Object.entries(obj)) {
          qs = `${qs ? `${qs}&` : ""}${key}=${value}`;
        }
        const { data } = await PatientService.getPatientsListWithFilters(qs);
        setPatients([...data.data]);
        setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  return {
    patientsLoading: loading,
    fetchPatients,
    patients,
    totalPatients: total,
  };
};
