import React, { Dispatch, SetStateAction } from "react";
import { UploadFile } from "antd";
import { FileUploader } from "../../../components";
import { annotationService } from "../../../api";
import { AnnotationSaveUploadedFilesRequest } from "../../../interfaces";

interface Props {
  projectId: number;
  setFetchFiles: Dispatch<SetStateAction<boolean>>;
}

export const AnnotationFileUploader = ({ projectId, setFetchFiles }: Props) => {
  const onFileUpload = async (file: UploadFile<any>) => {
    const { data } = await annotationService.getUploadUrl(projectId, file.name);
    await annotationService.uploadFile(data.response, file);
  };

  const onFilesUploadComplete = async (fileNames: string[]) => {
    const reqObj: AnnotationSaveUploadedFilesRequest[] = fileNames.map((item) => ({
      FileName: item,
      ProjectId: projectId,
    }));
    if (reqObj.length > 0) {
      await annotationService.saveUploadedFile(reqObj);
      setFetchFiles(true);
    }
  };

  return (
    <FileUploader
      onSubmit={onFileUpload}
      onFilesUploadComplete={onFilesUploadComplete}
      formats={[".pdf"]}
    />
  );
};
