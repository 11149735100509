import { AxiosResponse } from "axios";
import { apiClient } from ".";
import {
  IHEDISMeasureInsightResponse,
  IPagination,
  IPatient,
} from "../interfaces";

export interface PatientListInfo {
  id: number;
  name: string;
  patientId: string;
  age: number;
  gender: string;
  source: string;
  exclusioninclusioncriteria: string;
  demographicfit: string;
  geographicfit: string;
  conditionfit: string;
  procedurefit: string;
  physicalactivityfit: string;
  PatientRelationId: number;
}

export interface PatientWithBasicInfo {
  Id: number;
  Name: string;
  Dob: string;
  Gender: string;
  IsDeleted: boolean;
  OrganizationId: number;
  CreatedBy: number;
  Mrn: string;
  createdAt: string;
  updatedAt: string;
}

export interface PatientPaginationMapping {
  patientId: number;
  sequenceNo: number;
}

interface PatientPaginationMappingResponse {
  data: PatientPaginationMapping[];
  message: string;
  total: number;
}

interface PatientListResponse {
  list: PatientListInfo[];
}

interface PatientListByProjectIdResponse {
  list: {
    projectData: {
      name: string;
      s3Folder: string;
    };
    projectDocument: PatientListInfo[];
  };
}

interface PatientListService {
  (): Promise<AxiosResponse<PatientListResponse>>;
}

export interface AddPatientsToProjectRequest {
  projectId: number;
  patientIds: number[];
}

export interface PatientProjects {
  Id: number;
  Name: string;
  S3Folder: string;
  createdAt: string;
}

interface PatientProjectsResponse {
  message: string;
  data: PatientProjects[];
}

export interface PatientWithFilters extends PatientWithBasicInfo {
  projectId: number;
  projectName: string;
  projectStatusColorCode: string;
  projectStatusUpdatedAt: string;
  projectStatusId: number;
  projectStatusName: string;
  CreatedAt: string;
  FileName?: string;
}

interface PatientswithFiltersResponse {
  message: string;
  data: PatientWithFilters[];
  pagination: IPagination;
}

const getSearchString = (searchQuery: string | null | undefined, key = "") => {
  const query = searchQuery ? `${searchQuery}` : "";
  const queryKey = key ? `${key}` : "";
  const formattedQuery = queryKey ? `?${queryKey}=${query}` : `?${query}`;
  return formattedQuery;
};
// { queryString ? `?${queryString}` : "" }
// projectId ? `?projectId=${projectId}` : ""
const getPatientInfo = async (patientId: string, projectId: string | null) => {
  const searchQuery = getSearchString(projectId, "project_id");
  const res = await apiClient.get<IPatient>(
    `profile/patient/${patientId}/detailsv3${searchQuery}`
  );
  return res;
};

const getPatientsList: PatientListService = async () => {
  const res = await apiClient.get(`profile/list`);
  return res;
};

const addPatientsToProject = async (
  clientId: number,
  reqObj: AddPatientsToProjectRequest
) => {
  const res = await apiClient.post<PatientListByProjectIdResponse>(
    `/patient/project/org/${clientId}`,
    reqObj
  );
  return res;
};

const getPatientsListWithFilters = async (queryString?: string) => {
  const searchQuery = getSearchString(queryString);
  const res = await apiClient.get<PatientswithFiltersResponse>(
    `patient${searchQuery}`
  );
  return res;
};

const getPatientsForPagination = async () => {
  const res = await apiClient.get<PatientPaginationMappingResponse>(
    `patient/list`
  );
  return res;
};

const getPatientProjects = async (patientId: string) => {
  const res = await apiClient.get<PatientProjectsResponse>(
    `patient/${patientId}/project/list`
  );
  return res;
};

const getPatientHEDISMeasure = async (subjectId: string) => {
  const res = await apiClient.get<IHEDISMeasureInsightResponse>(
    `/patientHedisInsight/${subjectId}`
  );
  return res;
};

const getHedisCareGaps = async (documentUUID: string) => {
  const res = await apiClient.get(`/hedis-measure?documentUUID=${documentUUID}`);
  return res;
};

const getQuestions = async (uuid: string, measureId: number) => {
  const res = await apiClient.get(
    `hedis-measure-query-answer/${uuid}?measureId=${measureId}`
  );
  // const res = await apiClient.get(`hedis-measure-query/${measureId}`);
  return res;
};

const getSources = async (uuid: string, queryId: string) => {
  const res = await apiClient.get(
    `hedis-measure-query-answer/${uuid}/${queryId}`
  );
  return res;
}

export const PatientService = {
  getPatientInfo,
  getPatientsList,
  addPatientsToProject,
  getPatientsListWithFilters,
  getPatientProjects,
  getPatientsForPagination,
  getPatientHEDISMeasure,
  getHedisCareGaps,
  getQuestions,
  getSources
};
