import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setProjects } from "../../../store";
import { ProjectTile } from "./ProjectTile";
import { projectService } from "../../../api";
import { useAuthorization, useUser } from "../../../hooks";
import { Loading } from "../../../components";
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";

interface Props {
  className: string;
}

export const ProjectListInTiles = ({ className }: Props) => {
  const { projects } = useSelector((state: RootState) => state.data);
  const { clientId } = useUser();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { setIsAuthorized } = useAuthorization();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        if (clientId) {
          const { data } = await projectService.getAll(clientId);
          const { data: projectList } = data;
          dispatch(setProjects(projectList));
        }
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [clientId, dispatch, setIsAuthorized]);

  return (
    <>
      <div className={`grid-View m-t ${className}`}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {projects.list.map((project, index) => (
              <ProjectTile key={`project-tile-${index}`} project={project} />
            ))}
          </>
        )}
      </div>
    </>
  );
};
