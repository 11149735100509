import React from "react";
import { IconsProps } from "./Icons";

export const ChecklistIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 24 16"}
      width={width ? width : "24"}
      height={height ? height : "16"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M13.7143 4.57143H0V6.85714H13.7143V4.57143ZM13.7143 0H0V2.28571H13.7143V0ZM0 11.4286H9.14286V9.14286H0V11.4286ZM22.2857 6.28571L24 8L16.0114 16L10.8571 10.8571L12.5714 9.14286L16.0114 12.5714L22.2857 6.28571Z" fill="currentColor"/>
    </svg>
  );
};
