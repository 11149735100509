import { apiClient } from ".";

export interface KPISettings {
  kpi_project_stats_card: string[];
  kpi_patient_stats_card: string[];
  kpi_time_per_card: string[];
}

export interface DashboardData {
  project: {
    count: number;
    status: {
      pending: number;
      inProgress: number;
    };
  };
  patient: {
    count: number;
    status: {
      toBeReviewed: number;
      reviewed: number;
    };
  };
  kpiSettings: KPISettings;
}

interface DashboardDataResponse {
  data: DashboardData;
  message: string;
}

const getData = async (clientId: number) => {
  const res = await apiClient.get<DashboardDataResponse>(`dashboard/org/${clientId}`);
  return res;
};

export const DashboardService = {
  getData,
};
