import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserList, UserSignUpRequest, usersService } from "../../../api";
import { addNewUser, RootState, updateUser } from "../../../store";
import { openNotificationWithIcon, USER_ROLES_CODE } from "../../../utils";
import { MandatoryFieldsText } from "../../../components";

interface Props {
  title: string;
  buttonTitle: string;
  editObj: UserList;
  role: string;
  clientId: number;
  isEdit?: boolean;
  onClose?: () => void;
}

interface RoleOption {
  value: string;
  id: number;
  roleCode: string;
}

export const AddEditUserForm = (props: Props) => {
  const dispatch = useDispatch();
  const { title, buttonTitle, editObj, isEdit, role, clientId, onClose } =
    props;
  const { roles } = useSelector((state: RootState) => state.user);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const options: RoleOption[] = roles.list.map((item) => ({
    value: item.VisualName,
    id: item.Id,
    roleCode: item.RoleCode,
  }));
  const [roleOption, setRoleOption] = useState({} as RoleOption);

  const handleOptionChange = (value: string, option: RoleOption) => {
    setRoleOption(option);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, email } = editObj;
      form.setFieldsValue({
        name: name,
        email,
        role: isEdit ? "" : "",
      });
    }
  }, [form, editObj, isEdit]);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleEditSubmit = async (e: any) => {
    const nameSplit = e.target[0].value.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.slice(1).join(" ");
    try {
      const { data } = await usersService.editOne(
        { firstName, lastName, roleId: roleOption.id },
        editObj.id
      );
      dispatch(updateUser({ user: data.data }));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleAddSubmit = async (e: any) => {
    const nameSplit = e.target[0].value.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.slice(1).join(" ");
    const email = e.target[1].value;
    const signUpObj: UserSignUpRequest = {
      client: clientId,
      email,
      firstName,
      lastName,
      role:
        role === USER_ROLES_CODE.SUPER_ADMIN
          ? USER_ROLES_CODE.ENTERPRISE_ADMIN
          : roleOption.roleCode,
      roleId: roleOption.id,
    };
    try {
      const { data } = await usersService.addOne(signUpObj);
      const { data: newUser } = data;
      dispatch(addNewUser({ user: newUser }));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    if (isEdit) await handleEditSubmit(e);
    else await handleAddSubmit(e);
    setLoading(false);
    onClose && onClose();
  };

  return (
    <>
      <div className="addClient-title">
        <h1>{title}</h1>
      </div>
      <div className="addClient-content new-role">
        <Form
          form={form}
          onSubmitCapture={handleSubmit}
          onFieldsChange={handleFormChange}
          colon={false}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter valid email" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          {role !== USER_ROLES_CODE.SUPER_ADMIN && (
            <Form.Item
              name="role"
              label="User Role"
              rules={[{ required: true, message: "Please select role" }]}
            >
              <Select
                placeholder="Select Role"
                value={roleOption.value}
                options={options}
                onSelect={handleOptionChange}
                getPopupContainer={(trigger) => trigger.parentNode}
              ></Select>
            </Form.Item>
          )}
          <Form.Item className="mandatory-text">
            <MandatoryFieldsText />
            <Button
              className="outline"
              // type="primary"
              htmlType="submit"
              disabled={disableSave}
              loading={loading}
            >
              {buttonTitle}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
