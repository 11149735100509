import React from "react";
import { IconsProps } from "./Icons";

export const ConfigIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 16 16"}
      width={width ? width : "16"}
      height={height ? height : "16"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
        <path xmlns="http://www.w3.org/2000/svg" d="M13.7317 8.78333C13.765 8.53333 13.7817 8.275 13.7817 8C13.7817 7.73333 13.765 7.46667 13.7233 7.21667L15.415 5.9C15.565 5.78333 15.6067 5.55833 15.515 5.39167L13.915 2.625C13.815 2.44167 13.6067 2.38333 13.4233 2.44167L11.4317 3.24167C11.015 2.925 10.5733 2.65833 10.0817 2.45833L9.78167 0.341667C9.74833 0.141667 9.58167 0 9.38167 0H6.18167C5.98167 0 5.82333 0.141667 5.79 0.341667L5.49 2.45833C4.99833 2.65833 4.54833 2.93333 4.14 3.24167L2.14833 2.44167C1.965 2.375 1.75667 2.44167 1.65667 2.625L0.0650003 5.39167C-0.0349997 5.56667 -0.00166647 5.78333 0.165 5.9L1.85667 7.21667C1.815 7.46667 1.78167 7.74167 1.78167 8C1.78167 8.25833 1.79833 8.53333 1.84 8.78333L0.148334 10.1C-0.00166646 10.2167 -0.043333 10.4417 0.0483336 10.6083L1.64833 13.375C1.74833 13.5583 1.95667 13.6167 2.14 13.5583L4.13167 12.7583C4.54833 13.075 4.99 13.3417 5.48167 13.5417L5.78167 15.6583C5.82333 15.8583 5.98167 16 6.18167 16H9.38167C9.58167 16 9.74833 15.8583 9.77333 15.6583L10.0733 13.5417C10.565 13.3417 11.015 13.075 11.4233 12.7583L13.415 13.5583C13.5983 13.625 13.8067 13.5583 13.9067 13.375L15.5067 10.6083C15.6067 10.425 15.565 10.2167 15.4067 10.1L13.7317 8.78333ZM7.78167 11C6.13167 11 4.78167 9.65 4.78167 8C4.78167 6.35 6.13167 5 7.78167 5C9.43167 5 10.7817 6.35 10.7817 8C10.7817 9.65 9.43167 11 7.78167 11Z" fill="currentColor"/>    </svg>
  );
};
