import React from "react";
import { IconsProps } from "./Icons";

export const FilledBookmarkIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
  onClick,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 10 14"}
      width={width ? width : "10"}
      height={height ? height : "14"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path
        opacity="0.5"
        d="M9.5 0H0.5L0 0.5V13.5L0.872 13.835L5 9.247L9.128 13.835L10 13.5V0.5L9.5 0ZM9 12.2L5.372 8.165H4.628L1 12.2V1H9V12.2Z"
        fill="currentColor"
      />
      <path d="M9 1H1V12.5L4.5 9H5.5L9 12.5V1Z" fill="currentColor" />
    </svg>
  );
};
