import React, { useEffect, useState } from "react";
import { Button, Switch } from "antd";
import { useGlobalConfiguration } from "../../hooks";
import { Loading, NotAccessibleFallback } from "../../components";
import { ConfigurationObj } from "../../api";

const CONFIG = [
  { key: "Project Stats Card", title: "Project stats card" },
  { key: "Patient Stats Card", title: "Patient stats card" },
  // { key: "Project Status", title: "Project status" },
  { key: "Time Per Chart", title: "Time per chart" },
].map((item) => ({
  ...item,
  key: ["GC", "KPI", ...item.key.toUpperCase().split(" ")].join("_"),
}));

export const DashboardConfigurations = () => {
  const {
    fetchConfigurations,
    updateConfigurations,
    configurations,
    loading,
    updateConfigLoading,
    isFetchConfigAuthorized,
  } = useGlobalConfiguration();
  const [localUpdatedConfigurations, setLocalUpdatedConfigurations] = useState(
    [] as ConfigurationObj[]
  );

  const findConfiguration = (keyIndex: number): ConfigurationObj => {
    const key = CONFIG[keyIndex].key;
    const title = CONFIG[keyIndex].title;
    const index = localUpdatedConfigurations.findIndex(
      (item) => item.field_key === key
    );
    if (index !== -1)
      return { ...localUpdatedConfigurations[index], field_name: title };
    return { field_content: [], field_key: key, field_name: title };
  };

  const editConfiguration = (obj: ConfigurationObj) => {
    const key = obj.field_key;
    const filteredList = localUpdatedConfigurations.filter(
      (item) => item.field_key !== key
    );
    setLocalUpdatedConfigurations([...filteredList, obj]);
  };

  const handleSubmit = async () => {
    await updateConfigurations(localUpdatedConfigurations);
  };

  useEffect(() => {
    fetchConfigurations();
  }, [fetchConfigurations]);

  useEffect(() => {
    setLocalUpdatedConfigurations(configurations);
  }, [configurations]);

  return (
    <div>
      <p className="table-heading">Dashboard Configuration</p>
      {loading ? (
        <Loading />
      ) : !isFetchConfigAuthorized ? (
        <NotAccessibleFallback />
      ) : (
        <div className="ant-card equal-height flex flex-column">
          <ul className="flex flex-column gp">
            {CONFIG.map((item, index) => {
              const configObj = findConfiguration(index);
              return (
                <li className="flex gp aic" key={`kpi-config-${index}`}>
                  <p>{configObj.field_name}</p>
                  <Switch
                    size="small"
                    checked={configObj.field_content.length > 0}
                    onChange={(e) =>
                      editConfiguration({
                        ...configObj,
                        field_content: e ? ["true"] : [],
                      })
                    }
                  />
                </li>
              );
            })}
          </ul>
          <div className="tr" style={{ marginTop: "auto" }}>
            <Button
              className="outline"
              loading={updateConfigLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
