import React from "react";
import { Tabs } from "antd";
import { TabPane } from "rc-tabs";
import { USER_ROLES_CODE } from "../../../utils";
import { ProjectDetailsTabsHeadings, PROJECT_DETAIL_TABS } from "./ProjectDetailsTabsHeadings";

interface Props {
  selectedTab: string;
  onTabChange: (key: string) => void;
  roleCode: string;
}

export const ProjectDetailTabs = (props: Props) => {
  const { onTabChange, selectedTab, roleCode } = props;

  return (
    <>
      {/* <div className="project-title">
        <h2 className="project-title-heading">Project</h2>
        {projectName && <h2 className="project-title-name">{projectName}</h2>}
      </div> */}
      <div className="tabs">
        <Tabs onChange={onTabChange} defaultActiveKey={selectedTab}>
          {Object.values(PROJECT_DETAIL_TABS).map((tab) => {
            if (
              (roleCode === USER_ROLES_CODE.CLINICIAN && tab.isAccessibleToClinician) ||
              roleCode !== USER_ROLES_CODE.CLINICIAN
            )
              return (
                <TabPane
                  tab={<ProjectDetailsTabsHeadings name={tab.name} icon={tab.icon} />}
                  key={tab.name}
                />
              );
          })}
        </Tabs>
      </div>
    </>
  );
};
