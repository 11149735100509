import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Button, Form, Input } from "antd";
import { AddEditClientProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { clientService } from "../../../api";
import { MandatoryFieldsText } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";

const AddEditClient = (props: AddEditClientProps) => {
  const { visibility, isEdit, editObj, handleAdd, handleUpdate, handleClose } =
    props;
  const [form] = useForm();
  const [disableSave, setDisableSave] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { name, description } = editObj;
    form.setFieldsValue({
      name,
      description,
    });
  }, [form, editObj]);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  const handleEditSubmit = async (e: any) => {
    const name = e.target[0].value;
    const description = e.target[1].value;
    const { data } = await clientService.editOne(editObj.id, {
      name,
      description,
    });
    handleUpdate({
      description: data.data.Description,
      name: data.data.Name,
      id: data.data.Id,
    });
  };

  const handleAddSubmit = async (e: any) => {
    const name = e.target[0].value;
    const description = e.target[1].value;
    const { data } = await clientService.addOne({ name, description });
    handleAdd({
      description: data.data.Description,
      name: data.data.Name,
      id: data.data.Id,
    });
  };

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);
      if (isEdit) await handleEditSubmit(e);
      else await handleAddSubmit(e);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title="Add Client"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{isEdit ? "Edit Client" : "Add Client"}</h1>
      </div>
      <div className="addClient-content">
        <Form
          form={form}
          onSubmitCapture={handleSubmit}
          colon={false}
          requiredMark={false}
          onFieldsChange={handleFormChange}
        >
          <Form.Item
            label="Client Name:"
            name="name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description:"
            name="description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item label="Logo:" name="logo">
          <Input />
        </Form.Item> */}
          <Form.Item className="tr mandatory-text">
            <MandatoryFieldsText />

            <Button
              className="outline"
              // type="primary"
              htmlType="submit"
              disabled={disableSave}
              loading={loading}
            >
              {isEdit ? "Save" : "Add Client"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditClient;
