import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PAGE_URL } from "../../utils";
import { userService } from "../../api";
import { PasswordSuccessIcon } from "../../assets/icons";

const CreatePasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  const handleFormSubmit = async (e: any) => {
    setLoading(true);
    setError("");
    const token = searchParams.get("token") || "";
    if (!token) {
      setError("Invalid token");
      setLoading(false);
      return;
    }
    const password = e.target[0].value;
    const passwordConfirm = e.target[1].value;
    if (password !== passwordConfirm) {
      setError("Password does not match");
      setLoading(false);
      return;
    }
    try {
      await userService.resetPassword({ password }, token);
      navigate(PAGE_URL.CREATE_PASSWORD_SUCCESS);
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  return (
    <div className="user-form autonomizeModal">
      <Form
        // className="user-form"
        onSubmitCapture={(e) => handleFormSubmit(e)}
        onFieldsChange={handleFormChange}
        form={form}
      >
        <h2>Create Password</h2>
        <Form.Item
          name="password"
          // extra="Must be atleast 8 characters"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password type="password" placeholder="Enter Password" />
        </Form.Item>
        <Form.Item
          name="confirm-password"
          rules={[{ required: true, message: "Please re-enter your password" }]}
        >
          <Input type="password" placeholder="Confirm Password" />
        </Form.Item>
        {error && <h3 className="error">{error}</h3>}
        <Form.Item className="tr">
          <Button
            className="outline"
            // type="primary"
            htmlType="submit"
            disabled={disableSave}
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const CreatePasswordSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="user-form autonomizeModal">
      <Form className="user-form">
        <h2>Create Password</h2>

        <div className="flex gp-40 no-wrap">
          <div>
            <PasswordSuccessIcon />
          </div>
          <div>
            <h4>Password Created Successfully</h4>
            <p>your password has been created successfully please login below</p>
            <Form.Item className="tr">
              <Button className="outline" onClick={() => navigate(PAGE_URL.LOGIN)}>
                Login
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

const CreatePassword = {
  Form: CreatePasswordForm,
  Success: CreatePasswordSuccess,
};

export default CreatePassword;
