import React from "react";
import "../patientprofile.scss";

interface Props {
  children: React.ReactNode;
  title: string;
}

export const PatientCardWrapper = ({ children, title }: Props) => {
  return (
    <div className="patient-card-wrapper">
      <h4 className="insight-card-title" id={title.toLowerCase().split(" ").join("_")}>
        {title.toUpperCase()}
      </h4>
      {children}
    </div>
  );
};
