import { connect } from "react-redux";
import { Dispatch } from "redux";
import { closeModal, RootState } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { AddPatientToCohort } from "./AddPatientToCohort";

type StateProps = {
  visibility: boolean;
  patientName: string;
  patientId: number;
  cohorts: ICohort[];
};
type DispatchProps = {
  handleClose: () => void;
};
export type AddUserToCohortProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.ADD_PATIENT_TO_COHORT],
  patientId: state.patient.info.id,
  patientName: state.patient.info.name,
  cohorts: state.cohort.cohort,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.ADD_PATIENT_TO_COHORT));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddPatientToCohort);
