import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import AddEditClient from "./add-edit-client";
import DeleteConfirmation from "./delete-confirmation";
import AddEditProject from "./add-edit-project";
import AssignProject from "./assign-project";
import AddUserToProject from "./add-user-to-project";
import AddEditUser from "./add-edit-user";
import { EditProfile } from "./edit-profile";
import AddEditUserRole from "./add-edit-user-role";
import AddPatientsToProject from "./add-patients-to-project";
import ProjectDocumentProcessingDetailStatus from "./project-document-processing-status";
import UserMultipleRoles from "./user-multiple-roles";
import AddEditProjectConfiguration from "./add-edit-project-configurations";
import ForgotPassword from "./forgot-password";
import ResetUserPassword from "./reset-user-password";
import AddUserToCohort from "./add-patient-to-cohort";
import EditCohort from "./edit-cohort";
import FeedbackForm from "./feedback";
import ViewQuesAnsContext from "./ques-ans-context";
import LicenseKey from "./license-key";
import AddEditSummarizationTask from "./add-edit-summarization-task";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);

  return (
    <>
      {modal[MODAL_TYPE.ADD_EDIT_CLIENT] && <AddEditClient />}
      {modal[MODAL_TYPE.ADD_EDIT_USER] && <AddEditUser />}
      {modal[MODAL_TYPE.EDIT_PROFILE] && <EditProfile />}
      {modal[MODAL_TYPE.ADD_EDIT_PROJECT] && <AddEditProject />}
      {modal[MODAL_TYPE.ASSIGN_PROJECT] && <AssignProject />}
      {modal[MODAL_TYPE.DELETE_CONFIRMATION] && <DeleteConfirmation />}
      {modal[MODAL_TYPE.ADD_USER_TO_PROJECT] && <AddUserToProject />}
      {modal[MODAL_TYPE.ADD_PATIENT_TO_COHORT] && <AddUserToCohort />}
      {modal[MODAL_TYPE.ADD_EDIT_USER_ROLE] && <AddEditUserRole />}
      {modal[MODAL_TYPE.ADD_PATIENTS_TO_PROJECT] && <AddPatientsToProject />}
      {modal[MODAL_TYPE.USER_MULTIPLE_ROLES] && <UserMultipleRoles />}
      {modal[MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS] && (
        <ProjectDocumentProcessingDetailStatus />
      )}
      {modal[MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION] && (
        <AddEditProjectConfiguration />
      )}
      {modal[MODAL_TYPE.FORGOT_PASSWORD] && <ForgotPassword />}
      {modal[MODAL_TYPE.RESET_USER_PASSWORD] && <ResetUserPassword />}
      {modal[MODAL_TYPE.EDIT_COHORT] && <EditCohort />}
      {modal[MODAL_TYPE.FEEDBACK] && <FeedbackForm />}
      {modal[MODAL_TYPE.VIEW_QUES_ANS_CONTEXT] && <ViewQuesAnsContext />}
      {modal[MODAL_TYPE.LICENSE_KEY] && <LicenseKey />}
      {modal[MODAL_TYPE.ADD_EDIT_SUMMARIZATION_TASK] && <AddEditSummarizationTask />}
    </>
  );
};
