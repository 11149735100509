import React, { Dispatch, SetStateAction } from "react";
import { PatientProjects } from "../../api";
import { PatientDetailCardProps } from ".";
import { IPatient } from "../../interfaces";
import { PatientInsightHeader } from "./patient-insight-aside";

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
  setSelectedProject: (item: PatientProjects) => void;
  reviewStatusMap: PatientDetailCardProps[];
  patientProjectsLoading: boolean;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PatientInsightAside = (props: Props) => {
  const {
    patientInfo,
    patientProjects,
    selectedProject,
    setSelectedProject,
    reviewStatusMap,
    patientProjectsLoading,
    setReviewStatusMap,
    isAuthorized,
    setIsAuthorized,
  } = props;
  return (
    <div>
      <PatientInsightHeader
        {...{
          patientInfo,
          patientProjects,
          selectedProject,
          setSelectedProject,
          reviewStatusMap,
          patientProjectsLoading,
          setReviewStatusMap,
          isAuthorized,
          setIsAuthorized,
        }}
      />
    </div>
  );
};
