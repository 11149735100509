import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { Dispatch, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import {
  MODAL_TYPE,
  PAGE_URL,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import {
  RootState,
  deleteClient,
  openModal,
  setClientEditDelete,
  setClients,
  updateDeleteConfirmation,
} from "../../../store";
import { clientService } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { useAuthorization } from "../../../hooks";
import { Loading } from "../../../components";

interface DataType {
  key: React.Key;
  name: string;
  description: string;
  action: string;
}

const getColumns = (dispatch: Dispatch<AnyAction>): ColumnsType<DataType> => [
  {
    title: "Client Name",
    key: "name",
    dataIndex: "name",
    render: (text, value) => (
      <Link to={`${PAGE_URL.CLIENT_DETAIL}/${value.key}`}>{text}</Link>
    ),
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, value) => (
      <div className="editDelete gp jcc flex">
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            dispatch(
              setClientEditDelete({ id: Number(value.key), isEdit: true })
            );
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_CLIENT));
          }}
        />
        <DeleteIcon
          className="cursor-pointer"
          onClick={() => {
            dispatch(
              setClientEditDelete({ id: Number(value.key), isEdit: false })
            );
            dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
          }}
        />
      </div>
    ),
  },
];

interface Props {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClientsList = ({ loading, setLoading }: Props) => {
  const dispatch = useDispatch();
  const { list, id: clientId } = useSelector(
    (state: RootState) => state.data.clients
  );
  const isDeleteConfirmed = useSelector(
    (state: RootState) => state.ui.isDeleteConfirmed
  );
  const clientsData: DataType[] = list.map((item) => {
    const { description, id, name } = item;
    return { key: id, name, description, action: "" };
  });
  const { setIsAuthorized } = useAuthorization();
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await clientService.getAll();
        const clientList = data.data;
        const res = clientList.map((item) => ({
          id: item.Id,
          name: item.Name,
          description: item.Description,
        }));
        dispatch(setClients(res));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      }
    };
    fetch();
  }, [dispatch, setIsAuthorized]);

  useEffect(() => {
    const deleteClientAPI = async () => {
      try {
        setLoading(true);
        await clientService.deleteOne(clientId);
        dispatch(deleteClient(clientId));
        dispatch(updateDeleteConfirmation(false));
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setLoading(false);
      }
    };
    if (isDeleteConfirmed && clientId !== -1) deleteClientAPI();
  }, [clientId, dispatch, isDeleteConfirmed, setLoading]);

  return (
    <div className="clientList">
      {loading ? (
        <Loading />
      ) : (
        <Table
          className="client-table user-table"
          dataSource={clientsData}
          columns={getColumns(dispatch)}
          loading={loading}
          pagination={false}
        ></Table>
      )}
    </div>
  );
};
