import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EVIDENCE_VIEWER_URL, EVIDENCE_VIEWER_URL_SINGLE_FILE } from ".";

interface Props {
  searchParanms: any;
  belowEightyLength: number;
  threshold: number;
}
export const ViewMoreResults = ({ searchParanms, belowEightyLength, threshold }: Props) => {
  const navigate = useNavigate();
  if (belowEightyLength <= 0) return null;

  const handleClick = () => {
    navigate(
      `${EVIDENCE_VIEWER_URL_SINGLE_FILE}&${Object.keys(searchParanms)
        .map((key) => `${key}=${searchParanms[key]}`)
        .join("&")}`
    );
  };

  return (
    <div className="view-more-result">
      <div className="flex aic gp jcsb no-wrap">
        <p>{`Other entries that don't meet ${threshold}% confidence.`}</p>
        <a onClick={handleClick}>View More</a>
      </div>
    </div>
  );
};
