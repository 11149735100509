import React, { useEffect, useState } from 'react';
import { Button, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { projectService } from '../../../api';
import { EditIcon } from '../../../assets/icons';
import { useAuthorization, useUser } from '../../../hooks';
import { Project } from '../../../interfaces';
import {
  openModal,
  RootState,
  setProjectEditDelete,
  setProjects,
  updateProject,
} from '../../../store';
import {
  CLINICIAN_PERMISSIONS,
  MODAL_TYPE,
  openNotificationWithIcon,
  PAGE_URL,
  STATUS_CODE,
} from '../../../utils';
import { Loading } from '../../../components';
import './project-list.scss';

export const ProjectsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId, roleCode } = useUser();
  const { list: projects } = useSelector(
    (state: RootState) => state.data.projects,
  );
  const [loading, setLoading] = useState(false);
  const { setIsAuthorized } = useAuthorization();

  const navigateToProjectDetail = (id: number) => {
    navigate(`${PAGE_URL.PROJECT_DETAIL}/${id}`);
  };

  const onEnableDisable = async (status: boolean, project: Project) => {
    try {
      const { data } = await projectService.disableEnable(
        project.OrganizationId,
        status,
        project.Id,
      );
      dispatch(updateProject(data.data));
    } catch (err: any) {
      openNotificationWithIcon('', err.response.data.message, 'error');
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await projectService.getAll(clientId);
        const { data: projectList } = data;
        dispatch(setProjects(projectList));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon('', err.response.data.message, 'error');
        }
      } finally {
        setLoading(false);
      }
    };
    if (clientId) fetch();
  }, [clientId, dispatch, setIsAuthorized]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="timeline-group flex flex-column gp-10">
          {projects.map((project) => (
            <div className="timeline" key={`patient-project-${project.Id}`}>
              <Button
                onClick={() => navigateToProjectDetail(project.Id)}
                className="tab-inactive"
              >
                {project.Name}
              </Button>
              {!CLINICIAN_PERMISSIONS.includes(roleCode) && (
                <>
                  <EditIcon
                    className={
                      project.status ? 'cursor-pointer' : 'cursor-disable'
                    }
                    onClick={() => {
                      if (project.status) {
                        dispatch(
                          setProjectEditDelete({
                            id: project.Id,
                            isEdit: true,
                          }),
                        );
                        dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
                      }
                    }}
                  />
                  <Switch
                    size="small"
                    checked={project.status}
                    onChange={(status) => onEnableDisable(status, project)}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
