import { useCallback, useState } from "react";
import { ConfigurationObj, ConfigurationService } from "../api";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { useUser } from "./useUser";

export const useGlobalConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [updateConfigLoading, setUpdateConfigLoading] = useState(false);
  const [configurations, setConfigurations] = useState(
    [] as ConfigurationObj[]
  );
  const { clientId } = useUser();
  const [isFetchConfigAuthorized, setIsFetchConfigAuthorized] = useState(true);

  const fetchConfigurations = useCallback(
    async (updateForm?: (configObj: ConfigurationObj[]) => void) => {
      try {
        if (clientId) {
          setLoading(true);
          const { data } = await ConfigurationService.getConfigurations(
            clientId
          );
          setConfigurations(data.data);
          if (updateForm) updateForm(data.data);
        }
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsFetchConfigAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [clientId]
  );

  const updateConfigurations = useCallback(
    async (reqObj: ConfigurationObj[], errorFunction?: () => void) => {
      setUpdateConfigLoading(true);
      try {
        const { data } = await ConfigurationService.updateConfiguration(
          clientId,
          reqObj
        );
        setConfigurations(data.data);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        if (errorFunction) errorFunction();
      } finally {
        setUpdateConfigLoading(false);
      }
    },
    [clientId]
  );

  return {
    fetchConfigurations,
    loading,
    updateConfigurations,
    configurations,
    updateConfigLoading,
    isFetchConfigAuthorized,
  };
};
