import { apiClient } from ".";
import { IPagination } from "../interfaces";

interface IUpdateCohortRequest {
  name: string;
}

interface ICohortPatientListResponse {
  message: string;
  data: ICohortPatient[];
  pagination: IPagination;
}

interface ICohortListResponse {
  message: string;
  data: ICohort[];
  pagination: IPagination;
}

export interface ISaveCohortResponse {
  message: string;
  response: string;
}

interface IDeleteCohortPatientResponse {
  message: string;
  response: string;
}

interface IUpdateCohortResponse {
  message: string;
  response: string;
}

const getCohortList = async (limit?: number, offset?:number) => {
  if (limit) {
    return await apiClient.get<ICohortListResponse>(
      `/cohort/list?limit=${limit}&offset=${offset}`
    );
  }
  const res = await apiClient.get<ICohortListResponse>(
    `/cohort/list/?limit=1000&offset=0`
  );
  return res;
};
// done

const saveCohort = async (
  cohortName: string,
  patientId: number,
  cohortId?: number
) => {
  let reqObject = {} as ISaveCohort;
  if (cohortId) {
    reqObject = {
      Cohort: { Name: cohortName },
      CohortPatient: { PatientId: patientId, CohortId: cohortId },
    };
  } else {
    reqObject = {
      Cohort: { Name: cohortName },
      CohortPatient: { PatientId: patientId },
    };
  }
  const res = await apiClient.post<ISaveCohortResponse>(
    `/cohort`,
    reqObject
  );
  return res;
};



const getCohortPatientList = async (queryString: string) => {
  const res = await apiClient.get<ICohortPatientListResponse>(
    `/cohort/list/${queryString}`
  );
  return res;
};

const updateCohort = async (cohortId: number, reqObj: IUpdateCohortRequest) => {
  const res = await apiClient.put<IUpdateCohortResponse>(
    `/cohort/${cohortId}`,
    reqObj
  );
  return res;
};

const deleteCohortPatient = async (cohortId: number, patientId: number) => {
  const res = await apiClient.delete<IDeleteCohortPatientResponse>(
    `/cohort/${cohortId}/patient/${patientId}`
  );
  return res;
};

export const cohortService = {
  getCohortList,
  saveCohort,
  getCohortPatientList,
  updateCohort,
  deleteCohortPatient,
};
