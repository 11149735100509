import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersService } from "../api";
import { RootState, setUserList } from "../store";
import { useUser } from "./useUser";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";

export const useFetchUserList = () => {
  const dispatch = useDispatch();
  const { clientId } = useUser();
  const { list } = useSelector((state: RootState) => state.data.users.users);
  const [loading, setLoading] = useState(false);
  const [isFetchUsersAuthorized, setIsFetchUsersAuthorized] = useState(true);

  const fetchUsers = useCallback(
    async (id?: number) => {
      try {
        setLoading(true);
        const finalClientId = id ? id : clientId;
        const { data } = await usersService.getAll(finalClientId);
        const { data: userData } = data;

        dispatch(setUserList(userData));
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsFetchUsersAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [clientId, dispatch]
  );

  return {
    loading,
    fetchUsers,
    usersList: list,
    isFetchUsersAuthorized,
  };
};
