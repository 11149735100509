import React from "react";
import { Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  className?: string;
  onClick?: () => void;
  newTagTitle?: string;
}

export const AddNewTag = ({ className, newTagTitle, onClick }: Props) => {
  return (
    <Tag className={`${className} site-tag-plus`} onClick={onClick}>
      <PlusOutlined /> {newTagTitle ? newTagTitle : "New Tag"}
    </Tag>
  );
};
