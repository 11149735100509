import React from "react";
import { Tabs } from "antd";
import { PATIENTS_LIST_TABS } from "..";
const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const PatientsListTab = ({ onChange, selectedTab }: Props) => {
  return (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(PATIENTS_LIST_TABS).map((key) => (
          <TabPane tab={key} key={key} />
        ))}
      </Tabs>
    </div>
  );
};
