import React from "react";
import { TextEditor } from "../../../components";
import {OverviewIcon} from "../../../assets/icons";

interface Props {
  description: string;
}

export const ProjectDetailOverview = ({ description }: Props) => {
  return (
    <div className="study-desc h-full">
      <p className="project-detail-container-left-title flex aic gp"><span><OverviewIcon className="icon-overview" /></span>Project Description</p>
      <div className="basic-detail-content m-t">
        <TextEditor isDisabled text={description} />
      </div>
    </div>
  );
};
