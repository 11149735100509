import React, { useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Button, Input, Tag } from "antd";
import { AddNewTag } from "./AddNewTag";
import { ProjectMetaFieldContent } from "../../interfaces";
import { projectService } from "../../api";
import { openNotificationWithIcon } from "../../utils";

interface Props {
  tags: ProjectMetaFieldContent[];
  setTags: (tags: ProjectMetaFieldContent[]) => void;
  disableTagAddEdit?: boolean;
  newTagTitle?: string;
  allowAddingNewTag?: boolean;
}

export const AddRemoveTagNew = ({
  setTags,
  tags,
  newTagTitle,
  allowAddingNewTag,
  disableTagAddEdit,
}: Props) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [labelValue, setLabelValue] = useState("");

  const handleClose = (removedTag: ProjectMetaFieldContent) => {
    const newTags = tags.filter((tag) => tag.icdCode !== removedTag.icdCode);
    setTags([...newTags]);
  };

  const showInput = () => {
    if (!disableTagAddEdit) setInputVisible(true);
  };

  const hideInput = () => {
    setInputVisible(false);
    setCodeValue("");
    setLabelValue("");
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelValue(e.target.value);
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    try {
      const res = await projectService.validateICDCode(codeValue);
      openNotificationWithIcon("", res.data.data, "success");
      console.log(res.data);
      if (labelValue && codeValue) {
        setTags([...tags, { label: labelValue, icdCode: codeValue }]);
      }
      setInputVisible(false);
      setLabelValue("");
      setCodeValue("");
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  return (
    <>
      {tags.map((tag, index) => {
        return (
          <Tag
            className="edit-tag"
            key={index}
            closable={!disableTagAddEdit}
            onClose={() => handleClose(tag)}
          >
            {tag.icdCode}/{tag.label}
          </Tag>
        );
      })}
      {allowAddingNewTag ? (
        inputVisible ? (
          <div>
            <Input
              type="text"
              size="small"
              className="tag-input"
              value={codeValue}
              placeholder="Code"
              onChange={handleCodeChange}
              //   onBlur={handleInputConfirm}
              //   onPressEnter={handleInputConfirm}
            />
            <Input
              type="text"
              size="small"
              className="tag-input"
              placeholder="Label"
              value={labelValue}
              onChange={handleLabelChange}
              //   onBlur={handleInputConfirm}
              //   onPressEnter={handleInputConfirm}
            />
            <Button className="outline m-r" onClick={handleInputConfirm}>
              Save
            </Button>
            <Button className="outline" onClick={hideInput}>
              Cancel
            </Button>
          </div>
        ) : (
          <AddNewTag
            className={disableTagAddEdit ? "cursor-disable" : "cursor-pointer"}
            newTagTitle={newTagTitle}
            onClick={showInput}
          />
        )
      ) : null}
    </>
  );
};
