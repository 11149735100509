import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICohortSlice {
  cohort: ICohort[];
  cohortPatientToBeDeleted: ICohortPatient;
  cohortId: string;
  cohortName: string;
  retryGetCohort: boolean;
  retryGetCohortPatient: boolean;
}

export const cohortInitialState: ICohortSlice = {
  cohort: [] as ICohort[],
  cohortPatientToBeDeleted: {} as ICohortPatient,
  cohortId: "",
  cohortName: "",
  retryGetCohort: false,
  retryGetCohortPatient: false,
};

const cohortSlice = createSlice({
  name: "cohort",
  initialState: cohortInitialState,
  reducers: {
    setCohorts: (state: ICohortSlice, { payload }: PayloadAction<ICohort[]>) => {
      state.cohort = [...payload];
    },
    setCohortPatient: (state: ICohortSlice, { payload }: PayloadAction<ICohortPatient>) => {
      state.cohortPatientToBeDeleted = { ...payload };
    },
    setCohortId: (state: ICohortSlice, { payload }: PayloadAction<string>) => {
      state.cohortId = payload;
    },
    setCohortName: (state: ICohortSlice, { payload }: PayloadAction<string>) => {
      state.cohortName = payload;
    },
    setRetryGetCohort: (state: ICohortSlice, { payload }: PayloadAction<boolean>) => {
      state.retryGetCohort = payload;
    },
    setRetryGetCohortPatient: (state: ICohortSlice, { payload }: PayloadAction<boolean>) => {
      state.retryGetCohortPatient = payload;
    },
  },
});

export const {
  setCohorts,
  setCohortPatient,
  setCohortId,
  setCohortName,
  setRetryGetCohort,
  setRetryGetCohortPatient,
} = cohortSlice.actions;

export default cohortSlice.reducer;
