import React from "react";
import { IconsProps } from "./Icons";
const ChartBlueIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      fill="none"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 216 60"}
      width={width ? width : "216"}
      height={height ? height : "60"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_101_7)">
        <path
          d="M30.3303 25.6222C18.3523 42.7845 9.11927 32.7731 6 25.6222V54H210V22.2702C208.752 23.3875 205.508 26.1585 202.514 28.3038C198.771 30.9854 195.028 30.315 191.284 28.9742C187.541 27.6334 183.798 28.3038 180.055 28.9742C174.066 29.5105 170.073 27.8568 168.826 26.963C166.954 21.1528 163.211 9.26431 163.211 8.19167C163.211 6.85086 159.468 4.83965 153.853 6.85086C140.752 12.8845 138.881 22.2702 137.009 28.3038C133.266 32.9966 127.651 27.6334 125.78 23.611C123.908 19.5885 116.422 20.2589 110.807 22.2702C105.193 24.2814 101.45 23.611 99.578 22.2702C97.7064 20.9293 93.9633 19.5885 86.4771 20.2589C78.9908 20.9293 78.9908 21.5998 73.3761 16.2365C67.7615 10.8733 65.8899 13.5549 60.2752 18.2477C54.6605 22.9405 52.789 22.2702 47.1743 18.2477C41.5596 12.8845 39.6881 16.2365 30.3303 25.6222Z"
          fill="url(#paint0_linear_101_7)"
          fillOpacity="0.6"
        />
      </g>
      <path
        d="M6 25.5558C9.11927 32.6826 18.3523 42.6601 30.3303 25.5558C39.6881 16.2019 41.5596 12.8612 47.1743 18.2063C52.789 22.2151 54.6606 22.8833 60.2752 18.2063C65.8899 13.5294 67.7615 10.8568 73.3761 16.2019C78.9908 21.547 78.9908 20.8789 86.4771 20.2107C93.9633 19.5426 97.7064 20.8789 99.578 22.2151C101.45 23.5514 105.193 24.2196 110.807 22.2151C116.422 20.2107 123.908 19.5426 125.78 23.5514C127.651 27.5602 133.266 32.9053 137.009 28.2284C138.881 22.2151 140.752 12.8612 153.853 6.84798C159.468 4.84357 163.211 6.84798 163.211 8.18426C163.211 9.25328 166.954 21.1016 168.826 26.8921C170.073 27.7829 174.066 29.431 180.055 28.8965C183.798 28.2284 187.541 27.5602 191.284 28.8965C195.028 30.2328 198.771 30.9009 202.514 28.2284C205.508 26.0903 208.752 23.3287 210 22.2151"
        stroke="#017BFF"
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_f_101_7"
          x="0"
          y="0"
          width="216"
          height="60"
          filterUnits="userSpaceOnUse"
          // colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_101_7" />
        </filter>
        <linearGradient
          id="paint0_linear_101_7"
          x1="108"
          y1="6"
          x2="108"
          y2="54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#017BFF" />
          <stop offset="0.833333" stopColor="#017BFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChartBlueIcon;
