import React from "react";
import { IconsProps } from "./Icons";

export const ThumbsDown = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 18 18"}
      width={width ? width : "18"}
      height={height ? height : "18"}
      fill={fill ? fill : "currentColor"}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M16.5348 11.2458C16.1407 11.7133 15.5723 11.9792 14.9582 11.9792H11.264V14.5C11.264 15.8933 10.1365 17.0208 8.66984 17.0208C8.02817 17.0208 7.45067 16.6358 7.194 16.04L4.86567 10.6042H3.1515C1.95067 10.6042 0.979004 9.63251 0.979004 8.43168V3.14251C0.979004 1.95084 1.95984 0.979177 3.1515 0.979177H13.7482C14.7473 0.979177 15.5998 1.69418 15.774 2.68418L16.984 9.55918C17.0848 10.1642 16.9198 10.7783 16.5257 11.2458H16.5348ZM3.1515 2.35418C2.7115 2.35418 2.354 2.71168 2.354 3.14251V8.43168C2.354 8.87168 2.7115 9.22918 3.1515 9.22918H4.62734V2.35418H3.1515ZM14.4265 2.92251C14.3715 2.59251 14.0873 2.35418 13.7482 2.35418H6.0115V9.77918L8.459 15.5083C8.49567 15.5908 8.57817 15.655 8.75234 15.655C9.38484 15.655 9.889 15.1417 9.889 14.5092V10.6133H14.9582C15.169 10.6133 15.3523 10.5308 15.4807 10.375C15.609 10.2192 15.664 10.0175 15.6273 9.80668L14.4173 2.93168L14.4265 2.92251Z" fill="#635F6A"/>
    </svg>
  );
};
