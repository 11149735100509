import React from "react";
import { IconsProps } from "./Icons";

export const DocumentsIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 13 16"}
      width={width ? width : "13"}
      height={height ? height : "16"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M8 0H1.6C0.72 0 0.00800018 0.72 0.00800018 1.6L0 14.4C0 15.28 0.712 16 1.592 16H11.2C12.08 16 12.8 15.28 12.8 14.4V4.8L8 0ZM9.6 12.8H3.2V11.2H9.6V12.8ZM9.6 9.6H3.2V8H9.6V9.6ZM7.2 5.6V1.2L11.6 5.6H7.2Z" fill="currentColor"/>
    </svg>
  );
};
