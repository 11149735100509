import { Dispatch } from "redux";
import { connect } from "react-redux";
import { UserRole } from "../../../interfaces";
import { RootState, setUserRoleEdit, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { AddEditUserRole } from "./AddEditUserRole";

type StateProps = {
  visibility: boolean;
  editObj: UserRole;
  isEdit: boolean;
  roles: UserRole[];
};

type DispatchProps = {
  handleClose: () => void;
};
export type AddNewRoleProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const id = state.user.roles.id;
  let editObj = {} as UserRole;
  if (id !== -1) {
    const obj = state.user.roles.list.find((item) => item.Id === id);
    if (obj) editObj = obj;
  }
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_USER_ROLE],
    editObj,
    isEdit: id !== -1,
    roles: state.user.roles.list,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(setUserRoleEdit(-1));
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_USER_ROLE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUserRole);
